import { ComponentProps } from "react";
// eslint-disable-next-line no-restricted-imports
import { IntlProvider } from "react-intl";
// eslint-disable-next-line no-restricted-imports
import { STRINGS } from "_shared/localization/strings";

type Props = ComponentProps<typeof IntlProvider>;

export function LocalizationProvider(props: Props) {
  return <IntlProvider messages={STRINGS} {...props} />;
}
