import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronsRight } from "@fortawesome/sharp-light-svg-icons";
import bullhorn from "_assets/icons/bullhorn.svg";
import bullseyeArrow from "_assets/icons/bullseyeArrow.svg";
import cartDollar from "_assets/icons/cartDollar.svg";
import chartBar from "_assets/icons/chartBar.svg";
import chartPie from "_assets/icons/chartPie.svg";
import clipboardListCheck from "_assets/icons/clipboardListCheck.svg";
import globe from "_assets/icons/globe.svg";
import marketLab from "_assets/icons/marketLab.svg";
import messages from "_assets/icons/messages.svg";
import penNib from "_assets/icons/penNib.svg";
import salesFunnel from "_assets/icons/salesFunnel.svg";
import userPlus from "_assets/icons/userPlus.svg";
import users from "_assets/icons/users.svg";
import { CustomIcon } from "_shared/CustomIcon";
import { useFeatureFlags } from "_shared/FeatureFlagsContext";
import { IgnorePointer } from "_shared/IgnorePointer";
import { Tooltip } from "_shared/Tooltip/Tooltip";
import { useToggleButton } from "_shared/a11y";
import { LocalizedMessage } from "_shared/localization/LocalizedMessage";
import { useLocalize } from "_shared/localization/useLocalize";
import { SmallText } from "_shared/text/SmallText";
import { useMediaQuery } from "_shared/utils";
import classNames from "classnames";
import { ReactNode } from "react";
import { NavLink, useParams } from "react-router-dom";

export function AppSidebar() {
  const { startupId } = useParams();
  const localize = useLocalize();
  const isHoverSupported = useMediaQuery("(hover: hover)");
  const [isOpen, toggleIsOpen, toggleOpenButtonProps] = useToggleButton();
  const {
    isMessagesOn,
    isFounderRewardsOn,
    isPortfolioOn,
    isFounderInsightsOn,
    isCommunitiesOn,
    isJottingOn,
    isBigProblemOn,
    isIndustrySurveyOn,
    isStartupMarketLabOn,
    isStartupFundingOn,
    isStartupSalesFunnelOn,
    isStartupCRMOn,
    isStartupRecruitmentOn,
  } = useFeatureFlags();
  // If user switches from touch to mouse, close sidebar
  if (isHoverSupported && isOpen) toggleIsOpen();
  return (
    <div
      className={classNames(
        "group absolute left-0 top-0 flex h-full w-20 flex-col justify-center gap-10 border-r border-gray-80 bg-gray-86 transition-all dark:border-gray-31 dark:bg-gray-24",
        {
          "delay-200 duration-200 hover:w-56 hover:border-gray-86 hover:bg-white dark:hover:border-gray-42 dark:hover:bg-gray-31":
            isHoverSupported,
          "w-56 border-gray-86 bg-white dark:border-gray-42 dark:bg-gray-31":
            isOpen,
        },
      )}
    >
      {isHoverSupported ? null : (
        <button
          type="button"
          className="hover:bg-shade text-gray-42 dark:text-gray-80"
          aria-label={localize("button_open")}
          {...toggleOpenButtonProps}
        >
          <FontAwesomeIcon
            icon={faChevronsRight}
            className="py-3.5"
            style={isOpen ? { rotate: "y 180deg" } : {}}
          />
        </button>
      )}
      <div className="flex flex-col">
        <Item
          to={`/founder/portfolio`}
          icon={<CustomIcon svg={chartBar} />}
          label={<LocalizedMessage id="app_portfolio" />}
          isHoverSupported={isHoverSupported}
          isOpen={isOpen}
          disabled={!isPortfolioOn}
        />
        <Item
          to={`/founder/messages`}
          icon={<CustomIcon svg={messages} />}
          label={<LocalizedMessage id="app_messages" />}
          isHoverSupported={isHoverSupported}
          isOpen={isOpen}
          disabled={!isMessagesOn}
        />
        <Item
          to={`/founder/rewards`}
          icon={<CustomIcon svg={bullhorn} />}
          label={<LocalizedMessage id="app_founderRewards" />}
          isHoverSupported={isHoverSupported}
          isOpen={isOpen}
          disabled={!isFounderRewardsOn}
        />
        <Item
          to={`/founder/insights`}
          icon={<CustomIcon svg={chartPie} />}
          label={<LocalizedMessage id="app_founderInsights" />}
          isHoverSupported={isHoverSupported}
          isOpen={isOpen}
          disabled={!isFounderInsightsOn}
        />
        <Item
          to={`/founder/communities`}
          icon={<CustomIcon svg={users} />}
          label={<LocalizedMessage id="app_communities" />}
          isHoverSupported={isHoverSupported}
          isOpen={isOpen}
          disabled={!isCommunitiesOn}
        />
        <Item
          to={`/founder/jotting`}
          icon={<CustomIcon svg={penNib} />}
          label={<LocalizedMessage id="app_jotting" />}
          isHoverSupported={isHoverSupported}
          isOpen={isOpen}
          disabled={!isJottingOn}
        />
        <Item
          to={`/founder/big-problem`}
          icon={<CustomIcon svg={globe} />}
          label={<LocalizedMessage id="app_bigProblem" />}
          isHoverSupported={isHoverSupported}
          isOpen={isOpen}
          disabled={!isBigProblemOn}
        />
        <Item
          to={`/founder/industry-survey`}
          icon={<CustomIcon svg={clipboardListCheck} />}
          label={<LocalizedMessage id="app_industrySurvey" />}
          isHoverSupported={isHoverSupported}
          isOpen={isOpen}
          disabled={!isIndustrySurveyOn}
        />
      </div>
      {startupId ? (
        <div className="flex flex-col">
          <Item
            to={`/startups/${startupId}/market-lab`}
            icon={<CustomIcon svg={marketLab} />}
            label={<LocalizedMessage id="app_marketLab" />}
            isHoverSupported={isHoverSupported}
            isOpen={isOpen}
            disabled={!isStartupMarketLabOn}
          />
          <Item
            to={`/startups/${startupId}/funding`}
            icon={<CustomIcon svg={cartDollar} />}
            label={<LocalizedMessage id="app_funding" />}
            isHoverSupported={isHoverSupported}
            isOpen={isOpen}
            disabled={!isStartupFundingOn}
          />
          <Item
            to={`/startups/${startupId}/sales-funnel`}
            icon={<CustomIcon svg={salesFunnel} />}
            label={<LocalizedMessage id="app_salesFunnel" />}
            isHoverSupported={isHoverSupported}
            isOpen={isOpen}
            disabled={!isStartupSalesFunnelOn}
          />
          <Item
            to={`/startups/${startupId}/crm`}
            icon={<CustomIcon svg={bullseyeArrow} />}
            label={<LocalizedMessage id="app_crm" />}
            isHoverSupported={isHoverSupported}
            isOpen={isOpen}
            disabled={!isStartupCRMOn}
          />
          <Item
            to={`/startups/${startupId}/recruitment`}
            icon={<CustomIcon svg={userPlus} />}
            label={<LocalizedMessage id="app_recruitment" />}
            isHoverSupported={isHoverSupported}
            isOpen={isOpen}
            disabled={!isStartupRecruitmentOn}
          />
        </div>
      ) : null}
    </div>
  );
}

interface ItemProps {
  to: string;
  icon: ReactNode;
  label: ReactNode;
  isHoverSupported: boolean;
  isOpen: boolean;
  disabled?: boolean;
}

function Item(props: ItemProps) {
  const { to, icon, label, isHoverSupported, isOpen, disabled } = props;
  return (
    <Tooltip
      skip={!disabled}
      content={
        <SmallText color="light">
          <LocalizedMessage id="feature_coming_soon" />
        </SmallText>
      }
      side="right"
    >
      <IgnorePointer skip={!disabled} className="opacity-60">
        <NavLink
          to={to}
          className="aria-[current=page]:bg-shade hover:bg-shade flex items-center gap-4 border-e border-transparent py-2 ps-7 aria-[current=page]:border-primary aria-[current=page]:text-primary"
        >
          <div className="text-2xl">{icon}</div>
          <span
            className={classNames("whitespace-nowrap transition-opacity", {
              "pointer-events-none opacity-0": isHoverSupported || !isOpen,
              "delay-200 duration-200 group-hover:opacity-100":
                isHoverSupported,
            })}
          >
            <SmallText color="light">{label}</SmallText>
          </span>
        </NavLink>
      </IgnorePointer>
    </Tooltip>
  );
}
