import classNames from "classnames";
import { ReactNode } from "react";

export interface Props {
  checked: boolean;
  children?: ReactNode;
}

export function RadioGroupLabel(props: Props) {
  const { checked, ...otherProps } = props;
  return (
    <label
      className={classNames("flex gap-2.5 p-2.5", {
        "text-primary": checked,
      })}
      {...otherProps}
    />
  );
}
