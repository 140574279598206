import * as Form from "@radix-ui/react-form";
import { LocalizedMessage } from "_shared/localization/LocalizedMessage";
import { SmallText } from "_shared/text/SmallText";

export function InvalidPastDateMessage() {
  return (
    <Form.Message match="rangeOverflow">
      <SmallText color="warning">
        <LocalizedMessage id="input_error_date_before_today" />
      </SmallText>
    </Form.Message>
  );
}
