import * as Form from "@radix-ui/react-form";
import { AriaLabelableProps } from "_shared/a11y";
import { RequiredFormMessage } from "_shared/field/RequiredFormMessage";
import { Checkbox } from "_shared/input/Checkbox";
import { CheckboxGroupLabel } from "_shared/input/CheckboxGroup/CheckboxGroupLabel";
import { toggleInSet } from "_shared/utils";
import { ReactNode } from "react";

type Props<V extends string> = AriaLabelableProps & {
  onChange: (newValue: Set<V>) => void;
  value: Set<V>;
  options: ReadonlyArray<{
    value: V;
    label: ReactNode;
  }>;
  /**
   * If true, the user must tick at least one checkbox
   */
  required?: boolean;
};

export function CheckboxGroup<V extends string>(props: Props<V>) {
  const {
    value: groupValue,
    onChange,
    options,
    required,
    ...ariaProps
  } = props;
  return (
    <div role="group" className="flex flex-col" {...ariaProps}>
      <div className="flex flex-col">
        {options.map(({ value, label }) => {
          const checked = groupValue.has(value);
          return (
            <div key={value} className="hover:bg-shade">
              <CheckboxGroupLabel checked={checked}>
                <Form.Control asChild>
                  <Checkbox
                    value={value}
                    id="" // Stop @radix-ui Form.Control giving every checkbox the same ID
                    checked={checked}
                    onChange={() => onChange(toggleInSet(value, groupValue))}
                    required={required && groupValue.size === 0}
                  />
                </Form.Control>
                {label}
              </CheckboxGroupLabel>
            </div>
          );
        })}
      </div>
      <RequiredFormMessage type="select" />
    </div>
  );
}
