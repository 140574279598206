import { ReactNode } from "react";

interface Props {
  children?: ReactNode;
}

export function H2Text({ children }: Props) {
  return (
    <span className="contents text-sm font-bold uppercase tracking-wide text-gray-31 dark:text-white">
      {children}
    </span>
  );
}
