import { Spinner } from "_shared/Spinner";
import { Status } from "_shared/utils";
import classNames from "classnames";
import { ComponentProps, ReactNode, forwardRef } from "react";

interface Props extends Omit<ComponentProps<"button">, "className"> {
  icon?: ReactNode;
  size?: "small" | "normal";
  status?: Status;
}

export const SecondaryButton = forwardRef<
  HTMLButtonElement,
  Omit<Props, "ref">
>(function SecondaryButton(props, ref) {
  const {
    children,
    icon,
    status,
    disabled,
    type = "button",
    size = "normal",
    ...otherProps
  } = props;
  return (
    <div className="flex bg-white dark:bg-gray-31">
      <button
        ref={ref}
        type={type}
        className={classNames(
          "hover:bg-shade flex items-center justify-center whitespace-nowrap rounded-sm border border-gray-86 leading-none text-primary active:brightness-105 disabled:pointer-events-none disabled:opacity-70 disabled:grayscale dark:border-gray-42",
          {
            "gap-1.5 px-2 py-1 text-sm tracking-wide": size === "small",
            "gap-2 px-4 py-3 text-base": size === "normal",
          },
        )}
        disabled={status === "pending" || disabled}
        {...otherProps}
      >
        {status === "pending" ? (
          <Spinner />
        ) : (
          <>
            {icon}
            {children}
          </>
        )}
      </button>
    </div>
  );
});
