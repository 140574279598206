import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/sharp-light-svg-icons";
import * as Dialog from "@radix-ui/react-dialog";
import * as Form from "@radix-ui/react-form";
import { Scrollable } from "_shared/Scrollable/Scrollable";
import { SideDialog } from "_shared/SideDialog/SideDialog";
import { SideDialogFooter } from "_shared/SideDialog/SideDialogFooter";
import { SideDialogHeader } from "_shared/SideDialog/SideDialogHeader";
import { Tooltip } from "_shared/Tooltip/Tooltip";
import { useAuth } from "_shared/auth/AuthContext";
import { FlatButton } from "_shared/button/FlatButton";
import { PrimaryButton } from "_shared/button/PrimaryButton";
import { SecondaryButton } from "_shared/button/SecondaryButton";
import { Field } from "_shared/field/Field";
import { InvalidEmailMessage } from "_shared/field/InvalidEmailMessage";
import { RequiredFormMessage } from "_shared/field/RequiredFormMessage";
import { Grow } from "_shared/flex/Grow";
import { Select } from "_shared/input/Select/Select";
import { TextArea } from "_shared/input/TextArea";
import { TextInput } from "_shared/input/TextInput";
import { LocalizedMessage } from "_shared/localization/LocalizedMessage";
import { LabelText } from "_shared/text/LabelText";
import { SmallText } from "_shared/text/SmallText";
import { Status } from "_shared/utils";
import { usePortfolioProjectsQuery } from "founder/portfolio/usePortfolioProjectsQuery";
import { ComponentProps, forwardRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Startup } from "startups/startup/useStartupsQuery";
import invariant from "tiny-invariant";

interface StartupData {
  startup_id?: string;
  portfolio_id?: string;
  inviteCode?: string;
  startupAddress?: string;
  startupEmail?: string;
  startupPhNumber?: string;
  startupNotes?: string;
}

interface Props {
  startupsById: Record<string, Startup>;
}

export function AddStartup({ startupsById }: Props) {
  const [status, setStatus] = useState<Status>("idle");
  const [newStartup, setNewStartup] = useState<StartupData>();
  const navigate = useNavigate();
  const { post } = useAuth();
  const query = usePortfolioProjectsQuery();
  const projectsById = query.data ?? {};
  const projectIdsWithStartup = new Set(
    Object.values(startupsById).map((startup) => startup.portfolio_id),
  );

  async function sendInviteCode(portfolio_id?: string) {
    try {
      const { startup_id } = (await post("promote-portfolio", {
        portfolio_id: portfolio_id,
      })) as { startup_id: string };
      invariant(startup_id);
      setNewStartup({
        ...newStartup,
        portfolio_id: portfolio_id,
        startup_id: startup_id,
      });
      setStatus("success");
    } catch (error) {
      if (!(error instanceof Error)) throw error;
      setStatus("error");
    }
  }

  if (query.isLoading) return <AddStartupButton disabled />;

  if (Object.values(projectsById).length === 0)
    return (
      <Tooltip
        content={
          <LocalizedMessage
            id="startups_add_add_portfolio_first"
            values={{
              a: (chunks) => (
                <Link
                  to="/founder/portfolio"
                  className="text-primary hover:underline"
                >
                  {chunks}
                </Link>
              ),
            }}
          />
        }
      >
        <div>
          <AddStartupButton disabled />
        </div>
      </Tooltip>
    );

  return (
    <SideDialog isModal={true} trigger={<AddStartupButton />}>
      <SideDialogHeader>
        <LocalizedMessage id="startups_add" />
      </SideDialogHeader>
      <Form.Root
        className="flex min-h-0 w-96 flex-col"
        onSubmit={async (e) => {
          e.preventDefault();
          try {
            await post("save-startup-details", newStartup);
            setStatus("success");
            navigate("/startups/0");
          } catch (_) {
            setStatus("error");
          }
        }}
      >
        <Grow>
          <Scrollable>
            <div className="flex flex-col items-start gap-6 px-8 py-6">
              <Field name="currentProject">
                <Form.Label>
                  <LabelText>
                    <LocalizedMessage id="startups_add_choose_portfolio_project" />
                  </LabelText>
                </Form.Label>
                <Form.Control asChild>
                  <Select
                    required
                    value={newStartup?.portfolio_id}
                    onChange={async (value) => {
                      if (!value) return;
                      setNewStartup({
                        ...newStartup,
                        portfolio_id: projectsById[value].portfolio_id,
                      });
                      sendInviteCode(projectsById[value].portfolio_id);
                    }}
                    options={Object.values(projectsById)
                      .filter(
                        ({ portfolio_id }) =>
                          !projectIdsWithStartup.has(portfolio_id!),
                      )
                      .map((project) => {
                        return {
                          value: project.portfolio_id!,
                          label: project.projectCompany_name,
                        };
                      })}
                  />
                </Form.Control>
                <RequiredFormMessage />
                {newStartup ? (
                  <SmallText color="light">
                    <LocalizedMessage id="startups_add_label_invite_code_sent" />
                  </SmallText>
                ) : null}
              </Field>
              <Field name="inviteCode">
                <Form.Label>
                  <LabelText>
                    <LocalizedMessage id="startups_add_label_invite_code" />
                  </LabelText>
                </Form.Label>
                <Form.Control asChild>
                  <TextInput
                    value={newStartup?.inviteCode ?? ""}
                    onChange={(value) =>
                      setNewStartup({ ...newStartup, inviteCode: value })
                    }
                    required
                    suffix={
                      <FlatButton
                        size="small"
                        onClick={() => sendInviteCode(newStartup?.portfolio_id)}
                      >
                        <LocalizedMessage id="startups_add_resend_code" />
                      </FlatButton>
                    }
                  />
                </Form.Control>
                <RequiredFormMessage />
              </Field>
              <Field name="startupEmail">
                <Form.Label>
                  <LabelText>
                    <LocalizedMessage id="startups_add_label_email" />
                  </LabelText>
                </Form.Label>
                <Form.Control asChild>
                  <TextInput
                    type="email"
                    value={newStartup?.startupEmail ?? ""}
                    onChange={(value) =>
                      setNewStartup({
                        ...newStartup,
                        startupEmail: value,
                      })
                    }
                    required
                  />
                </Form.Control>
                <RequiredFormMessage />
                <InvalidEmailMessage />
              </Field>
              <Field name="startupPhNumber">
                <LabelText>
                  <LocalizedMessage id="startups_add_label_phone" />
                </LabelText>
                <Form.Control asChild>
                  <TextInput
                    type="tel"
                    value={newStartup?.startupPhNumber ?? ""}
                    onChange={(value) =>
                      setNewStartup({
                        ...newStartup,
                        startupPhNumber: value,
                      })
                    }
                    required
                  />
                </Form.Control>
                <RequiredFormMessage />
              </Field>
              <Field name="startupAddress">
                <Form.Label>
                  <LabelText>
                    <LocalizedMessage id="add_startup_label_address" />
                  </LabelText>
                </Form.Label>
                <Form.Control asChild>
                  <TextArea
                    value={newStartup?.startupAddress ?? ""}
                    onChange={(e) =>
                      setNewStartup({
                        ...newStartup,
                        startupAddress: e.target.value,
                      })
                    }
                    required
                  />
                </Form.Control>
                <RequiredFormMessage />
              </Field>
              <Field name="startupNotes">
                <Form.Label>
                  <LabelText>
                    <LocalizedMessage id="startups_add_label_startup_notes" />
                  </LabelText>
                </Form.Label>
                <Form.Control asChild>
                  <TextArea
                    value={newStartup?.startupNotes ?? ""}
                    onChange={(e) =>
                      setNewStartup({
                        ...newStartup,
                        startupNotes: e.target.value,
                      })
                    }
                    required
                  />
                </Form.Control>
              </Field>
            </div>
          </Scrollable>
        </Grow>
        <SideDialogFooter>
          <div className="flex flex-1 gap-4">
            <Form.Submit asChild>
              <PrimaryButton status={status}>
                <LocalizedMessage id="startups_add" />
              </PrimaryButton>
            </Form.Submit>
            <Dialog.Close asChild>
              <SecondaryButton>
                <LocalizedMessage id="button_cancel" />
              </SecondaryButton>
            </Dialog.Close>
          </div>
        </SideDialogFooter>
      </Form.Root>
    </SideDialog>
  );
}

const AddStartupButton = forwardRef<
  HTMLButtonElement,
  Omit<ComponentProps<"button">, "ref">
>(function AddStartupButton(props, ref) {
  return (
    <FlatButton ref={ref} icon={<FontAwesomeIcon icon={faPlus} />} {...props}>
      <LocalizedMessage id="startups_add" />
    </FlatButton>
  );
});
