import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleHalfStroke,
  faMoon,
  faSunBright,
} from "@fortawesome/sharp-light-svg-icons";
import { Tooltip } from "_shared/Tooltip/Tooltip";
import { ToggleGroup } from "_shared/input/ToggleGroup/ToggleGroup";
import { ToggleGroupItemPadding } from "_shared/input/ToggleGroup/ToggleGroupItemPadding";
import { useLocalize } from "_shared/localization/useLocalize";
import { usePreferredTheme } from "./theme";

export function ThemeSwitcher() {
  const localize = useLocalize();
  const [preferredTheme, setPreferredTheme] = usePreferredTheme();
  return (
    <ToggleGroup
      value={preferredTheme ?? "system"}
      onChange={(newTheme) => {
        if (!newTheme) return;
        setPreferredTheme(newTheme === "system" ? null : newTheme);
      }}
      options={[
        {
          value: "dark",
          label: (
            <Tooltip content={localize("user_theme_dark")}>
              <ToggleGroupItemPadding>
                <FontAwesomeIcon icon={faMoon} fixedWidth />
              </ToggleGroupItemPadding>
            </Tooltip>
          ),
        },
        {
          value: "system",
          label: (
            <Tooltip content={localize("user_theme_system")}>
              <ToggleGroupItemPadding>
                <FontAwesomeIcon icon={faCircleHalfStroke} fixedWidth />
              </ToggleGroupItemPadding>
            </Tooltip>
          ),
        },
        {
          value: "light",
          label: (
            <Tooltip content={localize("user_theme_light")}>
              <ToggleGroupItemPadding>
                <FontAwesomeIcon icon={faSunBright} fixedWidth />
              </ToggleGroupItemPadding>
            </Tooltip>
          ),
        },
      ]}
    />
  );
}
