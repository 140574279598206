import * as Form from "@radix-ui/react-form";
import { PrimaryButton } from "_shared/button/PrimaryButton";
import { LocalizedMessage } from "_shared/localization/LocalizedMessage";

export function SurveyNextButton() {
  return (
    <Form.Submit asChild>
      <PrimaryButton>
        <LocalizedMessage id="button_next" />
      </PrimaryButton>
    </Form.Submit>
  );
}
