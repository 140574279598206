import { PrimaryButton } from "_shared/button/PrimaryButton";
import { LocalizedMessage } from "_shared/localization/LocalizedMessage";
import { H2Text } from "_shared/text/H2Text";
import { H3Text } from "_shared/text/H3Text";

interface Props {
  firstName: string;
  next: () => void;
}

export function Verified({ firstName, next }: Props) {
  return (
    <div className="flex flex-col items-center gap-8 text-center">
      <div>
        <h2>
          <H2Text>
            <LocalizedMessage id="user_success" />
          </H2Text>
        </h2>
      </div>
      <div>
        <h3>
          <H3Text>
            <LocalizedMessage
              id="user_welcome_account_verified"
              values={{ name: firstName }}
            />
          </H3Text>
        </h3>
      </div>
      <PrimaryButton onClick={next}>Continue</PrimaryButton>
    </div>
  );
}
