import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRightFromBracket,
  faFileLines,
  faReceipt,
  faTimes,
  faUser,
} from "@fortawesome/sharp-light-svg-icons";
import * as Dialog from "@radix-ui/react-dialog";
import { useFeatureFlags } from "_shared/FeatureFlagsContext";
import { IgnorePointer } from "_shared/IgnorePointer";
import { Scrollable } from "_shared/Scrollable/Scrollable";
import { Tooltip } from "_shared/Tooltip/Tooltip";
import { useAuth, useUser } from "_shared/auth/AuthContext";
import { IconButton } from "_shared/button/IconButton";
import { Grow } from "_shared/flex/Grow";
import { LocalizedMessage } from "_shared/localization/LocalizedMessage";
import { useLocalize } from "_shared/localization/useLocalize";
import { SmallText } from "_shared/text/SmallText";
import { TruncatedText } from "_shared/text/TruncatedText";
import { ThemeSwitcher } from "_shared/theme/ThemeSwitcher";
import { ReactNode } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { useStartupsQuery } from "startups/startup/useStartupsQuery";

export function UserSidebar() {
  const { signOut } = useAuth();
  const user = useUser();
  const localize = useLocalize();
  const { pathname } = useLocation();
  const { isUserSubscriptionOn, isStartupsOn } = useFeatureFlags();
  const query = useStartupsQuery();
  const startupsById = query.data ?? {};

  return (
    <Dialog.Root modal={false}>
      <Dialog.Trigger asChild>
        <Tooltip
          content={
            <div className="flex flex-col gap-1">
              <div className="text-xs uppercase tracking-wide text-gray-42 dark:text-gray-80">
                <LocalizedMessage id="header_account" />
              </div>
              <span>{user.email}</span>
            </div>
          }
        >
          <button>
            <div className="flex aspect-square w-12 items-center justify-center rounded-full bg-gray-86 tracking-wide hover:outline hover:outline-2 hover:outline-gray-80 dark:bg-gray-48 dark:hover:outline-gray-42">
              {user.name[0]}
            </div>
          </button>
        </Tooltip>
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Content className="fixed right-0 top-0 flex h-full flex-col data-[state*=open]:animate-slide-left data-[state=closed]:animate-slide-right">
          <div className="flex min-h-0 max-w-xs flex-1 flex-col border-l border-gray-86 bg-white dark:border-gray-42 dark:bg-gray-31">
            <div className="flex items-center justify-between gap-12 px-8 py-6">
              <ThemeSwitcher />
              <Dialog.Close asChild>
                <Tooltip content={localize("button_close")}>
                  <IconButton>
                    <FontAwesomeIcon icon={faTimes} fixedWidth />
                  </IconButton>
                </Tooltip>
              </Dialog.Close>
            </div>
            <hr className="mx-8 border-gray-86 dark:border-gray-42" />
            <Grow>
              <Scrollable>
                <nav className="flex flex-col items-stretch py-1">
                  <PersonaLink to="/founder">
                    <div className="flex items-center gap-4 px-6 py-4">
                      <div className="flex aspect-square w-12 items-center justify-center rounded-full bg-gray-86 tracking-wide dark:bg-gray-48">
                        {user.name[0]}
                      </div>
                      <div className="flex min-w-0 flex-1 flex-col">
                        <small className="text-xs uppercase tracking-wide text-gray-42 dark:text-gray-80">
                          Founder
                        </small>
                        <TruncatedText>{user.name}</TruncatedText>
                      </div>
                    </div>
                  </PersonaLink>
                  {isStartupsOn ? (
                    <>
                      <span className="px-8 pb-1.5 pt-4">
                        <SmallText color="light">Startups</SmallText>
                      </span>
                      {Object.entries(startupsById).map(([id, startup]) => (
                        <PersonaLink
                          key={id}
                          to={
                            pathname.includes("/startups/")
                              ? pathname.replace(
                                  /\/startups\/[^/]*/,
                                  `/startups/${id}`,
                                )
                              : `/startups/${id}`
                          }
                        >
                          <div className="flex items-center gap-4 px-6 py-4">
                            <div className="flex aspect-square w-12 items-center justify-center rounded-full bg-gray-86 tracking-wide dark:bg-gray-48">
                              {startup.SUName[0]}
                            </div>
                            <div className="flex min-w-0 flex-1 flex-col">
                              <small className="text-xs uppercase tracking-wide text-gray-42 dark:text-gray-80">
                                Admin
                              </small>
                              <TruncatedText>{startup.SUName}</TruncatedText>
                            </div>
                          </div>
                        </PersonaLink>
                      ))}
                    </>
                  ) : null}
                </nav>
              </Scrollable>
            </Grow>
            <hr className="mx-8 border-gray-86 dark:border-gray-42" />
            <div className="flex flex-col gap-2 py-4">
              <div className="px-8">
                <SmallText color="light">{user.email}</SmallText>
              </div>
              <div className="flex flex-col">
                <Link
                  to="/user/settings"
                  className="hover:bg-shade flex items-center gap-4 px-8 py-3"
                >
                  <FontAwesomeIcon icon={faUser} fixedWidth />
                  <LocalizedMessage id="user_settings_title" />
                </Link>
                <Tooltip
                  skip={isUserSubscriptionOn}
                  content={
                    <SmallText color="light">
                      <LocalizedMessage id="feature_coming_soon" />
                    </SmallText>
                  }
                  side="left"
                >
                  <IgnorePointer
                    skip={isUserSubscriptionOn}
                    className="opacity-60"
                  >
                    <Link
                      to="/user/subscription"
                      className="hover:bg-shade flex items-center gap-4 px-8 py-3"
                    >
                      <FontAwesomeIcon icon={faReceipt} fixedWidth />
                      <LocalizedMessage id="header_subscription" />
                    </Link>
                  </IgnorePointer>
                </Tooltip>
                <Link
                  to="/terms"
                  target="_blank"
                  className="hover:bg-shade flex items-center gap-4 px-8 py-3"
                >
                  <FontAwesomeIcon icon={faFileLines} fixedWidth />
                  <LocalizedMessage id="header_terms" />
                </Link>
                <Link
                  to="/"
                  onClick={signOut}
                  className="hover:bg-shade flex items-center gap-4 px-8 py-3"
                >
                  <FontAwesomeIcon icon={faArrowRightFromBracket} fixedWidth />
                  <LocalizedMessage id="header_sign_out" />
                </Link>
              </div>
            </div>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}

interface PersonaLinkProps {
  to: string;
  children: ReactNode;
}

function PersonaLink(props: PersonaLinkProps) {
  const { to, children } = props;
  return (
    <NavLink
      to={to}
      className="aria-[current=page]:bg-shade hover:bg-shade border-s-2 border-transparent aria-[current=page]:border-primary"
    >
      {children}
    </NavLink>
  );
}
