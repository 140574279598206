import * as Form from "@radix-ui/react-form";
import { genders } from "_options/genders";
import { Field } from "_shared/field/Field";
import { InvalidPastDateMessage } from "_shared/field/InvalidPastDateMessage";
import { RequiredFormMessage } from "_shared/field/RequiredFormMessage";
import { DateInput } from "_shared/input/DateInput";
import { LinkedInInput } from "_shared/input/LinkedInInput";
import { Select } from "_shared/input/Select/Select";
import { TextInput } from "_shared/input/TextInput";
import { LocalizedMessage } from "_shared/localization/LocalizedMessage";
import { LabelText } from "_shared/text/LabelText";
import { SmallText } from "_shared/text/SmallText";
import { formatISO } from "_shared/utils";
import { UserProfile } from "user/User.types";

interface Props {
  userProfile: UserProfile;
  setUserProfile: (value?: UserProfile) => void;
}

export function UserSettingsProfile(props: Props) {
  const { userProfile, setUserProfile } = props;

  function onChange(update?: UserProfile) {
    setUserProfile({ ...userProfile, ...update });
  }

  return (
    <>
      <Field name="firstName">
        <LabelText>
          <LocalizedMessage id="user_settings_first_name" />
        </LabelText>
        <Form.Control asChild>
          <TextInput
            autoComplete="given-name"
            required
            value={userProfile?.FirstName}
            onChange={(value) => {
              onChange({ FirstName: value });
            }}
          />
        </Form.Control>
        <RequiredFormMessage />
      </Field>
      <Field name="lastName">
        <LabelText>
          <LocalizedMessage id="user_settings_last_name" />
        </LabelText>
        <Form.Control asChild>
          <TextInput
            autoComplete="family-name"
            required
            value={userProfile?.LastName}
            onChange={(value) => {
              onChange({ LastName: value });
            }}
          />
        </Form.Control>
        <RequiredFormMessage />
      </Field>
      <Field name="dateOfBirth">
        <LabelText>
          <LocalizedMessage id="user_settings_date_of_birth" />
        </LabelText>
        <Form.Control asChild>
          <DateInput
            required
            max={new Date()}
            value={
              userProfile?.dateOfBirth
                ? new Date(userProfile?.dateOfBirth)
                : undefined
            }
            onChange={(value) => {
              onChange({ dateOfBirth: value ? formatISO(value) : "" });
            }}
          />
        </Form.Control>
        <InvalidPastDateMessage />
        <RequiredFormMessage type="date" />
        <Form.Message match="rangeOverflow">
          <SmallText color="warning">
            <LocalizedMessage id="input_error_date_before_today" />
          </SmallText>
        </Form.Message>
      </Field>
      <Field name="gender">
        <LabelText>
          <LocalizedMessage id="user_settings_gender" />
        </LabelText>
        <Select
          value={userProfile?.Gender}
          onChange={(value) => {
            onChange({ Gender: value });
          }}
          options={genders}
        />
      </Field>
      <Field name="linkedInProfile">
        <LabelText>
          <LocalizedMessage id="user_linkedin_username" />
        </LabelText>
        <Form.Control asChild>
          <LinkedInInput
            value={userProfile?.linkedInHandle}
            onChange={(value) => {
              onChange({ linkedInHandle: value });
            }}
            required
          />
        </Form.Control>
        <RequiredFormMessage />
      </Field>
    </>
  );
}
