import { ComponentPropsWithoutRef, forwardRef } from "react";

type Props = ComponentPropsWithoutRef<"input">;

export const Radio = forwardRef<HTMLInputElement, Props>(
  function Checkbox(props, ref) {
    return (
      <input
        ref={ref}
        type="radio"
        className="w-3.5 accent-primary"
        {...props}
      />
    );
  },
);
