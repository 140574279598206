import { SmallText } from "_shared/text/SmallText";
import { ReactNode } from "react";

interface Props {
  children?: ReactNode;
}

export function Breadcrumbs({ children }: Props) {
  return (
    <SmallText color="light">
      <div className="flex gap-1">{children}</div>
    </SmallText>
  );
}
