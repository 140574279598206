import * as Form from "@radix-ui/react-form";
import { Field } from "_shared/field/Field";
import { InvalidIntMessage } from "_shared/field/InvalidIntMessage";
import { RequiredFormMessage } from "_shared/field/RequiredFormMessage";
import { IntInput } from "_shared/input/IntInput";
import { TextInput } from "_shared/input/TextInput";
import { ToggleGroup } from "_shared/input/ToggleGroup/ToggleGroup";
import { LocalizedMessage } from "_shared/localization/LocalizedMessage";
import { useLocalize } from "_shared/localization/useLocalize";
import { LabelText } from "_shared/text/LabelText";
import { useId, useState } from "react";
import { RELATIONSHIPS, Relationship } from "user/User.types";

export function UserSettingsOther() {
  const id = useId();
  const localize = useLocalize();

  const [relationshipStatus, setRelationshipStatus] = useState<Relationship>();
  const [financialStatus, setFinancialStatus] = useState<number | undefined>(3);
  const [emotionalStatus, setEmotionalStatus] = useState(
    "Test Emotional Status",
  );

  return (
    <>
      <Field name="relationshipStatus">
        <LabelText>
          <LocalizedMessage id="user_settings_relationship_status" />
        </LabelText>
        <ToggleGroup
          aria-labelledby={id}
          value={relationshipStatus}
          onChange={setRelationshipStatus}
          options={RELATIONSHIPS.map((value) => ({
            value,
            label: localize(`user_settings_relationship_${value}`),
          }))}
        />
      </Field>
      <Field name="financialStatus">
        <LabelText>
          <LocalizedMessage id="user_settings_financial_status" />
        </LabelText>
        <Form.Control asChild>
          <IntInput
            required
            value={financialStatus}
            onChange={setFinancialStatus}
            suffix="months"
          />
        </Form.Control>
        <InvalidIntMessage />
        <RequiredFormMessage />
      </Field>
      <Field name="emotionalStatus">
        <LabelText>
          <LocalizedMessage id="user_settings_emotional_status" />
        </LabelText>
        <Form.Control asChild>
          <TextInput
            type="text"
            value={emotionalStatus}
            onChange={setEmotionalStatus}
          />
        </Form.Control>
      </Field>
    </>
  );
}
