import { Item, Root } from "@radix-ui/react-toggle-group";
import { ToggleGroupItemPadding } from "_shared/input/ToggleGroup/ToggleGroupItemPadding";
import classNames from "classnames";
import { ReactNode, Ref, forwardRef } from "react";

interface Props<V extends string> {
  options: ReadonlyArray<{ value: V; label: ReactNode }>;
  value?: V;
  onChange: (newValue: V) => void;
}

export const ToggleGroup = forwardRef(function ToggleGroup<V extends string>(
  props: Props<V>,
  ref: Ref<HTMLInputElement>,
) {
  const { options, value, onChange, ...inputProps } = props;
  return (
    <Root
      type="single"
      onValueChange={(newValue) => {
        /**
         * We cannot support deselecting until @radiux-ui/primitive `composeEventHandlers` fully supports custom `onChange` events (which don't use `Event` objects).
         * Currently, @radix-ui checks `event.defaultPrevented` which, though dodgy, works for strings but throws if we do `onChange(undefined)`.
         * See https://github.com/radix-ui/primitives/issues/2464
         */
        if (newValue === "") return;
        onChange(newValue as V);
      }}
      value={value}
    >
      {/* Render hidden input for compatibility with @radix-ui form validation */}
      <input
        ref={ref}
        type="text"
        value={value ?? ""}
        onChange={() => {}} // Suppress uncontrolled input warning
        {...inputProps}
        className="peer hidden"
      />
      <div className="flex rounded-sm bg-white outline-1 -outline-offset-1 outline-red-50 peer-data-[invalid]:outline dark:bg-gray-24 dark:outline-yellow-57">
        {options.map((option) => {
          return (
            <Item
              key={option.value}
              className={classNames(
                "hover:bg-shade flex grow items-center justify-center border border-gray-80 bg-primary bg-opacity-0 dark:border-gray-42",
                "first:rounded-s-sm last:rounded-e-sm [&:not(:first-child)]:-ml-[1px]",
                "aria-checked:border-b-primary aria-checked:bg-opacity-5 aria-checked:text-primary dark:aria-checked:border-b-primary",
                "focus-visible:outline focus-visible:outline-2 focus-visible:outline-blue-62",
              )}
              value={option.value}
            >
              {typeof option.label === "string" ? (
                <ToggleGroupItemPadding>{option.label}</ToggleGroupItemPadding>
              ) : (
                option.label
              )}
            </Item>
          );
        })}
      </div>
    </Root>
  );
});
