import * as Form from "@radix-ui/react-form";
import { ReactNode, useId } from "react";

interface Props {
  name?: string;
  serverInvalid?: boolean;
  children?: ReactNode;
}

export function Field(props: Props) {
  const { name, children, serverInvalid } = props;
  const id = useId();
  return (
    <Form.Field
      name={name ?? id}
      serverInvalid={serverInvalid}
      className="flex flex-col gap-1 self-stretch"
    >
      {children}
    </Form.Field>
  );
}
