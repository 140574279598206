import { ReactNode } from "react";

interface Props {
  children?: ReactNode;
}

export function H1Text({ children }: Props) {
  return (
    <span className="contents text-4xl font-thin text-gray-27 dark:text-white">
      {children}
    </span>
  );
}
