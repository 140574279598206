import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/sharp-light-svg-icons";
import { ChangeEvent, ComponentProps, ReactNode, Ref, forwardRef } from "react";

interface Props<V extends string>
  extends Omit<ComponentProps<"select">, "ref" | "value" | "onChange"> {
  options: ReadonlyArray<{ value: V; label: ReactNode }>;
  value: V | undefined;
  onChange: (newValue: V | undefined) => void;
}

export const Select = forwardRef(function Select<V extends string>(
  props: Props<V>,
  ref: Ref<HTMLSelectElement>,
) {
  const { value, options, onChange, ...otherProps } = props;
  return (
    <span className="relative flex items-center">
      <select
        ref={ref}
        className="min-w-0 grow cursor-pointer appearance-none rounded-sm border border-gray-80 px-3 py-2 pr-9 focus-visible:outline focus-visible:outline-2 focus-visible:-outline-offset-1 focus-visible:outline-blue-62 data-[invalid]:border-red-50 dark:border-gray-42 dark:bg-gray-24 dark:data-[invalid]:border-yellow-57"
        value={value ?? ""}
        {...otherProps}
        //Using onInput rather than onChange to work around @radix-ui Form.Control bug which didn't recognise the Select value change
        onInput={(e: ChangeEvent<HTMLSelectElement>) => {
          /**
           * We cannot support deselecting until @radiux-ui/primitive `composeEventHandlers` fully supports custom `onChange` events (which don't use `Event` objects).
           * Currently, @radix-ui checks `event.defaultPrevented` which, though dodgy, works for strings but throws if we do `onChange(undefined)`.
           * See https://github.com/radix-ui/primitives/issues/2464
           */
          if (e.target.value === "") return;
          onChange(e.target.value as V);
        }}
      >
        {value === undefined ? <option value="">-</option> : null}
        {options.map((option) => {
          return (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          );
        })}
      </select>
      <FontAwesomeIcon
        icon={faChevronDown}
        className="pointer-events-none absolute right-3"
      />
    </span>
  );
});
