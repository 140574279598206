import * as RadioGroup from "@radix-ui/react-radio-group";
import { AriaLabelableProps } from "_shared/a11y";
import { SmallText } from "_shared/text/SmallText";
import classNames from "classnames";
import { ComponentPropsWithoutRef, ReactNode, forwardRef, useId } from "react";

type Props = Omit<ComponentPropsWithoutRef<"input">, "value" | "onChange"> &
  AriaLabelableProps & {
    start: ReactNode;
    end: ReactNode;
    /**
     * Zero-based index of selected value
     */
    value?: number;
    defaultValue?: number;
    onChange?: (newValue: number) => void;
    /**
     * Number of options
     */
    length?: number;
  };

export const RatingInput = forwardRef<HTMLInputElement, Props>(
  function RatingInput(props, ref) {
    const {
      start,
      end,
      length = 5,
      value,
      defaultValue,
      onChange,
      "aria-label": ariaLabel,
      "aria-labelledby": ariaLabelledby,
      ...inputProps
    } = props;
    const idsByIndex = {
      0: useId(),
      [length - 1]: useId(),
    };
    return (
      <RadioGroup.Root
        className="flex items-center gap-4"
        value={`${value}`}
        defaultValue={`${defaultValue}`}
        onValueChange={(v) => onChange?.(parseInt(v, 10))}
        aria-label={ariaLabel}
        aria-labelledby={ariaLabelledby}
      >
        {/* Render hidden input for compatibility with @radix-ui form validation */}
        <input
          ref={ref}
          type="number"
          value={value ?? ""}
          onChange={() => {}} // Suppress uncontrolled input warning
          {...inputProps}
          className="hidden"
        />
        <span id={idsByIndex[0]} className="text-center">
          <SmallText color="light">{start}</SmallText>
        </span>
        <div className="flex items-center">
          {Array.from({ length }).map((_, index) => {
            return (
              <RadioGroup.Item
                key={index}
                value={`${index}`}
                className="group/radio flex h-12 w-12 items-center justify-center hover:brightness-95 focus-visible:outline-none active:brightness-105"
                aria-label={`${index + 1}`}
                aria-describedby={idsByIndex[index]}
              >
                <div
                  className={classNames(
                    "flex aspect-square w-6 items-center justify-center rounded-full border border-gray-80 bg-white",
                    "dark:border-gray-42 dark:bg-gray-24",
                    "group-focus-visible/radio:outline group-focus-visible/radio:outline-2 group-focus-visible/radio:-outline-offset-1 group-focus-visible/radio:outline-blue-62",
                    "group-data-[state=checked]/radio:border-primary",
                  )}
                >
                  <RadioGroup.Indicator className="aspect-square w-3.5 rounded-full bg-primary" />
                </div>
              </RadioGroup.Item>
            );
          })}
        </div>
        <span id={idsByIndex[length - 1]} className="text-center">
          <SmallText color="light">{end}</SmallText>
        </span>
      </RadioGroup.Root>
    );
  },
);
