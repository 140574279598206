import { createRoot } from "react-dom/client";
import invariant from "tiny-invariant";
import { LocalizationProvider } from "_shared/localization/LocalizationProvider";
import {
  createBrowserRouter,
  Navigate,
  Outlet,
  redirect,
  RouterProvider,
  useLocation,
} from "react-router-dom";
import { GlobalHeader } from "./GlobalHeader";
import { SignIn } from "user/SignIn";
import "@fontsource/roboto/100.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/400-italic.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "@fontsource/roboto/900.css";
import "@fontsource/roboto-mono";
import "@fontsource/montserrat/700.css";
import { Register } from "user/register/Register";
import { useTheme } from "_shared/theme/theme";
import * as Tooltip from "@radix-ui/react-tooltip";
import { ReactNode, useEffect } from "react";
import { AuthProvider } from "_shared/auth/AuthContext";
import { ResetPassword } from "user/ResetPassword";
import { StaticSurvey } from "user/static-survey/StaticSurvey";
import { Authenticated } from "_shared/auth/Authenticated";
import {
  FeatureFlagsProvider,
  useFeatureFlags,
} from "_shared/FeatureFlagsContext";
import { MarketLab } from "startups/startup/market-lab/MarketLab";
import { Startup } from "startups/startup/Startup";
import { UserSettings } from "user/UserSettings";
import { AppSidebar } from "./AppSidebar";
import { NotFound } from "./NotFound";
import { Portfolio } from "founder/portfolio/Portfolio";
import { Founder } from "founder/Founder";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import "./fontawesome";
import { ToastProvider } from "_shared/Toast/ToastContext";
import { Survey } from "user/survey/Survey";
import { Terms } from "user/Terms";
import { MarketLabProducts } from "startups/startup/market-lab/products/MarketLabProducts";
import { MarketLabProduct } from "startups/startup/market-lab/products/MarketLabProduct";
import { MarketLabChallenges } from "startups/startup/market-lab/products/challenges/MarketLabChallenges";

interface Props {
  children?: ReactNode;
}

function Root(props: Props) {
  const { children } = props;
  const theme = useTheme();
  const location = useLocation();

  // Set <meta name="theme-color" />
  useEffect(() => {
    const { backgroundColor } = getComputedStyle(document.body);
    if (!backgroundColor) return;
    const metaTag = document.createElement("meta");
    metaTag.setAttribute("name", "theme-color");
    metaTag.setAttribute("content", backgroundColor);
    document.head.appendChild(metaTag);
    return () => metaTag.remove();
  }, [theme]);

  // Purposefully outside useEffect so that the very first render gets the correct theme
  if (theme === "dark") {
    document.documentElement.classList.add("dark");
  } else {
    document.documentElement.classList.remove("dark");
  }

  if (location.pathname === "/") return <Navigate to="/founder" replace />;

  return (
    <div className="flex h-screen flex-col overflow-clip">
      {children ?? <Outlet />}
    </div>
  );
}

function Sidebars() {
  return (
    <Authenticated>
      <div className="relative flex grow overflow-clip ps-20">
        <div className="flex grow flex-col overflow-clip">
          <GlobalHeader />
          <Outlet />
        </div>
        <AppSidebar />
      </div>
    </Authenticated>
  );
}

function Startups() {
  const { isStartupsOn } = useFeatureFlags();
  if (!isStartupsOn) throw new Error("404");
  return <Sidebars />;
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: (
      <Root>
        <NotFound />
      </Root>
    ),
    children: [
      { path: "user/register", element: <Register /> },
      { path: "user/sign-in", element: <SignIn /> },
      { path: "user/reset-password", element: <ResetPassword /> },
      { path: "terms", element: <Terms /> },

      {
        path: "user",
        element: <Sidebars />,
        children: [
          { path: "settings", element: <UserSettings /> },
          { path: "survey", loader: () => redirect("/founder/survey") },
        ],
      },
      {
        path: "founder",
        element: <Sidebars />,
        children: [
          {
            path: "",
            element: <Founder />,
          },
          { path: "survey", element: <Survey /> },
          { path: "static-survey", element: <StaticSurvey /> },
          {
            path: "portfolio/:projectId?",
            element: <Portfolio />,
          },
        ],
      },
      {
        path: "startups",
        element: <Startups />,
        children: [
          {
            path: ":startupId",
            element: <Startup />,
          },
          {
            path: ":startupId/market-lab",
            element: <MarketLab />,
            children: [
              {
                path: "",
                loader: () => redirect("products"),
              },
              {
                path: "products",
                element: <MarketLabProducts />,
              },
              {
                path: "products/:productId",
                element: <MarketLabProduct />,
              },
              {
                path: "products/:productId/challenges",
                element: <MarketLabChallenges />,
              },
            ],
          },
        ],
      },
    ],
  },
]);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const container = document.getElementById("app");
invariant(container, "#app HTML Element not found");
createRoot(container).render(
  <FeatureFlagsProvider>
    <AuthProvider>
      <QueryClientProvider client={queryClient}>
        <LocalizationProvider locale="en-GB">
          <Tooltip.Provider delayDuration={0}>
            <ToastProvider>
              <RouterProvider router={router} />
            </ToastProvider>
          </Tooltip.Provider>
        </LocalizationProvider>
        <ReactQueryDevtools />
      </QueryClientProvider>
    </AuthProvider>
  </FeatureFlagsProvider>,
);
