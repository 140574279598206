import { forwardRef, ReactNode, Ref } from "react";

interface Props {
  onClick?: () => void;
  children: ReactNode;
}

export const KebabMenuItem = forwardRef(function KebabMenuItem(
  props: Props,
  ref: Ref<HTMLButtonElement>,
) {
  const { onClick, children } = props;
  return (
    <button
      className="hover:bg-shade flex items-center gap-3 px-4 py-2"
      onClick={onClick}
      ref={ref}
    >
      {children}
    </button>
  );
});
