import { Breadcrumb } from "_shared/Breadcrumbs/Breadcrumb";
import { Breadcrumbs } from "_shared/Breadcrumbs/Breadcrumbs";
import { LocalizedMessage } from "_shared/localization/LocalizedMessage";
import { H1Text } from "_shared/text/H1Text";

export function MarketLabChallenges() {
  return (
    <div className="flex flex-col gap-4">
      <Breadcrumbs>
        <Breadcrumb to="/">
          <LocalizedMessage id="startups_title" />
        </Breadcrumb>
        <Breadcrumb to={`/startups/0`}>Kavedon Kapital</Breadcrumb>
        <Breadcrumb to="/startups/0/market-lab">
          <LocalizedMessage id="app_marketLab" />
        </Breadcrumb>
        <Breadcrumb to="/startups/0/market-lab/products/0">
          Fraud-o-matic
        </Breadcrumb>
        <Breadcrumb>Challenges</Breadcrumb>
      </Breadcrumbs>
      <h1>
        <H1Text>Challenges</H1Text>
      </h1>
    </div>
  );
}
