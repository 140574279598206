import { Input } from "_shared/input/Input";
import { InputProps } from "_shared/input/Input.types";
import { InputAffix } from "_shared/input/InputAffix";
import { InputWrapper } from "_shared/input/InputWrapper";
import { formatISO, mergeRefs } from "_shared/utils";
import { forwardRef, useEffect, useRef, useState } from "react";

interface Props extends Omit<InputProps, "min" | "max"> {
  onChange?: (newValue: Date | undefined) => void;
  value?: Date;
  max?: Date;
  min?: Date;
}

/**
 * A controlled input which manages its own value, and only calls `onChange` when its value is a date.
 */
export const DateInput = forwardRef<HTMLInputElement, Props>(
  function DateInput(props, ref) {
    const { onChange, prefix, suffix, value, max, min, ...inputProps } = props;
    const [localValue, setLocalValue] = useState<string>(
      value ? formatISO(value) : "",
    );
    const localRef = useRef<HTMLInputElement>();

    useEffect(() => {
      if (localRef.current === document.activeElement) {
        return;
      }
      setLocalValue(value ? formatISO(value) : "");
    }, [value]);

    return (
      <InputWrapper data-invalid={inputProps["data-invalid"]}>
        <InputAffix>{prefix}</InputAffix>
        <Input
          ref={mergeRefs(localRef, ref)}
          type="date"
          max={max ? formatISO(max) : undefined}
          min={min ? formatISO(min) : undefined}
          value={localValue}
          onChange={(e) => {
            setLocalValue(e.target.value);
            if (e.target.value === "") {
              try {
                onChange?.(undefined);
              } catch (err) {
                /**
                 * Radix will not support deselecting until @radiux-ui/primitive `composeEventHandlers` fully supports custom `onChange` events (which don't use `Event` objects).
                 * Currently, @radix-ui checks `event.defaultPrevented` which, though dodgy, works for strings but throws if we do `onChange(undefined)`.
                 * See https://github.com/radix-ui/primitives/issues/2464
                 * We therefore need to catch this error, which does not stop the `onChange(undefined)`.
                 */
              }
            } else {
              const date = e.target.valueAsDate;
              if (date) onChange?.(date);
            }
          }}
          {...inputProps}
        />
        <InputAffix>{suffix}</InputAffix>
      </InputWrapper>
    );
  },
);
