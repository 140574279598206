import { Provider, Viewport } from "@radix-ui/react-toast";
import { Toast } from "_shared/Toast/Toast";
import { nanoid } from "nanoid";
import {
  ComponentProps,
  FC,
  ReactNode,
  createContext,
  useContext,
  useState,
} from "react";
import invariant from "tiny-invariant";

type ToastComponent = FC<Pick<ComponentProps<typeof Toast>, "onClose">>;

interface ToastContextValue {
  showToast: (DynamicToast: ToastComponent) => void;
}

const ToastContext = createContext<ToastContextValue | undefined>(undefined);

interface ToastProviderProps {
  children?: ReactNode;
}

export function ToastProvider(props: ToastProviderProps) {
  const [toastsById, setToastsById] = useState<Record<string, ToastComponent>>(
    {},
  );
  function showToast(DynamicToast: ToastComponent) {
    const tempObject = { ...toastsById };
    tempObject[nanoid()] = DynamicToast;
    setToastsById(tempObject);
  }
  return (
    <ToastContext.Provider
      value={{
        showToast,
      }}
    >
      {props.children}
      <Provider swipeDirection="right">
        {Object.entries(toastsById).map(([id, DynamicToast]) => {
          return (
            <DynamicToast
              key={id}
              onClose={() => {
                const tempObject = { ...toastsById };
                delete tempObject[id];
                setToastsById(tempObject);
              }}
            />
          );
        })}
        <Viewport className="pointer-events-none fixed bottom-6 right-8  flex flex-col items-end gap-6 [&>*]:pointer-events-auto" />
      </Provider>
    </ToastContext.Provider>
  );
}

export function useToast(): ToastContextValue {
  const context = useContext(ToastContext);
  invariant(
    context !== undefined,
    "useToast() must be used within a ToastContext.Provider.",
  );
  return context;
}
