import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPartyHorn } from "@fortawesome/sharp-light-svg-icons";
import { PrimaryButton } from "_shared/button/PrimaryButton";
import { LocalizedMessage } from "_shared/localization/LocalizedMessage";
import { H1Text } from "_shared/text/H1Text";
import { SmallText } from "_shared/text/SmallText";
import { useNavigate } from "react-router-dom";

export function End() {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col items-center gap-12">
      <FontAwesomeIcon
        icon={faPartyHorn}
        className="text-9xl text-gray-80 dark:text-gray-42"
      />
      <div className="flex flex-col items-center gap-8">
        <h1>
          <H1Text>
            <LocalizedMessage id="survey_end_title" />
          </H1Text>
        </h1>
        <div>
          <SmallText color="light">
            <LocalizedMessage id="survey_end_description" />
          </SmallText>
        </div>
        <PrimaryButton onClick={() => navigate("/")}>
          <LocalizedMessage id="survey_end_continue" />
        </PrimaryButton>
      </div>
    </div>
  );
}
