import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Form from "@radix-ui/react-form";
import { Toast } from "_shared/Toast/Toast";
import { useToast } from "_shared/Toast/ToastContext";
import { useAuth } from "_shared/auth/AuthContext";
import { PrimaryButton } from "_shared/button/PrimaryButton";
import { Field } from "_shared/field/Field";
import { RequiredFormMessage } from "_shared/field/RequiredFormMessage";
import { LinkedInInput } from "_shared/input/LinkedInInput";
import { LocalizedMessage } from "_shared/localization/LocalizedMessage";
import { H2Text } from "_shared/text/H2Text";
import { LabelText } from "_shared/text/LabelText";
import { SmallText } from "_shared/text/SmallText";
import { Status } from "_shared/utils";
import { useState } from "react";

interface Props {
  next: () => void;
}

export function SetLinkedInHandle({ next }: Props) {
  const [value, setValue] = useState("");
  const [status, setStatus] = useState<Status>("idle");
  const { post } = useAuth();
  const { showToast } = useToast();

  async function fetchProfile() {
    setStatus("pending");
    try {
      await post("linkdinfetch", {
        linkedInProf: value,
      });
      setStatus("success");
      next();
    } catch (error) {
      showToast((props) => (
        <Toast {...props}>
          <LocalizedMessage id="error" />
        </Toast>
      ));
      setStatus("idle");
    }
  }

  return (
    <Form.Root
      className="flex flex-col gap-6"
      onSubmit={(e) => {
        e.preventDefault();
        fetchProfile();
      }}
    >
      <h2>
        <H2Text>
          <LocalizedMessage id="user_your_profile" />
        </H2Text>
      </h2>
      <Field name="linkedInHandle">
        <LabelText>
          <Form.Label className="flex items-center gap-1">
            <FontAwesomeIcon icon={faLinkedin} />
            <LocalizedMessage id="user_linkedin_username" />
          </Form.Label>
        </LabelText>
        <Form.Control asChild>
          <LinkedInInput value={value} onChange={setValue} required />
        </Form.Control>
        <RequiredFormMessage />
        <Form.Message match="patternMismatch">
          <SmallText color="warning">
            <LocalizedMessage id="user_set_linkedin_invalid" />
          </SmallText>
        </Form.Message>
        <SmallText color="light">
          <LocalizedMessage id="user_set_linkedin_description" />
        </SmallText>
      </Field>
      <div className="flex flex-col gap-3">
        <Form.Submit asChild>
          <PrimaryButton status={status}>
            <LocalizedMessage id="user_set_linkedin_profile" />
          </PrimaryButton>
        </Form.Submit>
      </div>
    </Form.Root>
  );
}
