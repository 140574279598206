import arrowsRotateUser from "_assets/icons/arrowsRotateUser.svg";
import { CustomIcon } from "_shared/CustomIcon";
import { PrimaryButton } from "_shared/button/PrimaryButton";
import { LocalizedMessage } from "_shared/localization/LocalizedMessage";
import { H2Text } from "_shared/text/H2Text";
import { useNavigate } from "react-router-dom";

export function SurveyPrompt() {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col gap-8">
      <h2>
        <H2Text>
          <LocalizedMessage id="user_contribution_time" />
        </H2Text>
      </h2>
      <div className="text-center text-8xl">
        <CustomIcon stroke="gradient" svg={arrowsRotateUser} />
      </div>
      <p>
        <LocalizedMessage id="user_survey_description_0" />
      </p>
      <p>
        <LocalizedMessage id="user_survey_description_1" />
      </p>
      <PrimaryButton onClick={() => navigate("/founder/survey")}>
        <LocalizedMessage id="user_take_founder_survey" />
      </PrimaryButton>
    </div>
  );
}
