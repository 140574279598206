import { useFeatureFlags } from "_shared/FeatureFlagsContext";
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useRef,
  useState,
} from "react";
import invariant from "tiny-invariant";

export interface User {
  uid: string;
  name: string;
  email: string;
  mobilePhone: string;
  dateSurveyCompleted?: Date;
}

interface NewUser {
  firstName: string;
  lastName: string;
  email: string;
  phoneCountryCode: string;
  phoneWithoutCountryCode: string;
}

interface Credentials {
  email: string;
  password: string;
}

interface Auth {
  /**
   * Prefer {@link useUser}
   */
  user?: User;
  register: (newUser: NewUser) => Promise<void>;
  signIn: (credentials: Credentials) => Promise<void>;
  signOut: () => void;
  verifyEmailOneTimeCode: (oneTimeCode: string) => Promise<void>;
  verifyPhoneOneTimeCode: (oneTimeCode: string) => Promise<void>;
  sendEmailOneTimeCode: () => Promise<void>;
  sendPhoneOneTimeCode: () => Promise<void>;
  setPassword: (password: string) => Promise<void>;
  sendForgotPasswordCode: (email: string) => Promise<void>;
  verifyForgotPasswordCode: (
    email: string,
    oneTimeCode: string,
  ) => Promise<void>;
  post: (endpoint: string, body?: object) => Promise<object>;
}

const AuthContext = createContext<Auth | undefined>(undefined);

interface Props {
  children?: ReactNode;
}

export function AuthProvider(props: Props) {
  const { isAuthOn, isDevApiServerOn } = useFeatureFlags();
  /**
   * Ridiculously, the back-end necessitates a semi-signed-in state: where we have an access token, but not user details.
   * For example, during the forgot password flow.
   * This must be a ref to avoid waiting for React state to update between API calls.
   */
  const accessToken = useRef<string | undefined>(isAuthOn ? undefined : "");
  const [user, setUser] = useState<User | undefined>(
    isAuthOn
      ? undefined
      : {
          uid: "0",
          name: "Louis Localhost",
          email: "test@localhost",
          mobilePhone: "07000000000",
        },
  );

  const post = useCallback(async (endpoint: string, body?: object) => {
    const url = new URL(
      endpoint.startsWith("/") ? endpoint.slice(1) : endpoint,
      isDevApiServerOn
        ? "https://apirevveon.com/api/"
        : "https://liveapirevveon.com/api/",
    );
    const headers: Record<string, string> = {
      "Content-Type": "application/json",
    };
    if (accessToken.current)
      headers["Authorization"] = `Bearer ${accessToken.current}`;
    const response = await fetch(url.toString(), {
      method: "POST",
      body: JSON.stringify(body),
      headers,
    });
    const json = await response.json();
    if (!response.ok || json.status === "error") throw new Error(json.message);
    return json;
  }, []);

  async function signIn(credentials: Credentials) {
    const { access_token, user_info } = await post("login", {
      loginRadio: "Email",
      ...credentials,
    });
    invariant(access_token && user_info);
    const {
      uid,
      name,
      email,
      country_code,
      contact_no,
      date_survey_completed,
    } = user_info;
    accessToken.current = access_token;
    setUser({
      uid,
      name,
      email,
      mobilePhone: `+${country_code}${contact_no}`,
      dateSurveyCompleted: date_survey_completed
        ? new Date(date_survey_completed)
        : undefined,
    });
  }

  function signOut() {
    accessToken.current = undefined;
    setUser(undefined);
  }

  async function register(newUser: NewUser) {
    const {
      firstName,
      lastName,
      email,
      phoneCountryCode,
      phoneWithoutCountryCode,
    } = newUser;
    const { access_token, regID } = await post("founder-register", {
      first_name: firstName,
      last_name: lastName,
      email,
      country_code: phoneCountryCode,
      mobile_no: phoneWithoutCountryCode,
    });
    invariant(access_token && regID);
    accessToken.current = access_token;
    setUser({
      uid: regID,
      name: `${firstName} ${lastName}`,
      email,
      mobilePhone: `+${phoneCountryCode}${phoneWithoutCountryCode}`,
    });
    /**
     * Endue sends email OTPs but not phone OTPs automatically.
     * Pass `email` as `user` state will not have updated.
     */
    sendPhoneOneTimeCode(email);
  }

  async function verifyEmailOneTimeCode(oneTimeCode: string) {
    invariant(user);
    await post("verify-register-emailotp", {
      Email: user.email,
      Email_OTP: oneTimeCode,
    });
  }

  async function verifyPhoneOneTimeCode(oneTimeCode: string) {
    await post("verify-foundermobile-otp", {
      OtpVal: oneTimeCode,
    });
  }

  async function sendEmailOneTimeCode() {
    invariant(user);
    await post("resend-register-emailotp", {
      regID: user.uid,
    });
  }

  async function sendPhoneOneTimeCode(email = user?.email) {
    invariant(email);
    await post("send-foundermobile-otp", {
      emailID: email,
    });
  }

  async function setPassword(password: string) {
    await post("changeMyPassword", { password });
  }

  async function sendForgotPasswordCode(email: string) {
    await post("forgetpassotp", {
      emailMob: email,
    });
  }

  async function verifyForgotPasswordCode(email: string, oneTimeCode: string) {
    const { token } = await post("verify-forgetpassotp", {
      Email: email,
      otpSent: oneTimeCode,
    });
    invariant(token);
    accessToken.current = token;
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        signIn,
        signOut,
        register,
        verifyEmailOneTimeCode,
        verifyPhoneOneTimeCode,
        sendEmailOneTimeCode,
        sendPhoneOneTimeCode,
        setPassword,
        sendForgotPasswordCode,
        verifyForgotPasswordCode,
        post,
      }}
      {...props}
    />
  );
}

export function useAuth(): Auth {
  const auth = useContext(AuthContext);
  invariant(
    auth !== undefined,
    "useAuth() must be used within an AuthProvider.",
  );
  return auth;
}

/**
 * Get the current user. Throw if not signed in. This is the hook to use inside `<Authenticated>`.
 */
export function useUser(): User {
  const { user } = useAuth();
  invariant(
    user !== undefined,
    "useUser() was called before sign in. Did you wrap your route with <Authenticated>?",
  );
  return user;
}
