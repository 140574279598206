import { useQuery } from "@tanstack/react-query";
import { useAuth, useUser } from "_shared/auth/AuthContext";
import { Project } from "founder/portfolio/Portfolio.types";

export function usePortfolioProjectsQuery() {
  const user = useUser();
  const { post } = useAuth();

  return useQuery({
    queryKey: ["/get-portfolio-list", user.email],
    queryFn: async () => {
      const fetchedProjects = (await post("get-portfolio-list", {
        email: user.email,
      })) as {
        0: [Project];
      };
      const projectsById: Record<string, Project> = Object.fromEntries(
        fetchedProjects[0].map((project) => [project.portfolio_id, project]),
      );
      return projectsById;
    },
  });
}
