import { ComponentPropsWithoutRef, forwardRef } from "react";

type Props = ComponentPropsWithoutRef<"input">;

export const Checkbox = forwardRef<HTMLInputElement, Props>(
  function Checkbox(props, ref) {
    return (
      <input
        ref={ref}
        type="checkbox"
        className="w-3.5 accent-primary"
        {...props}
      />
    );
  },
);
