import { ComponentProps } from "react";
// eslint-disable-next-line no-restricted-imports
import { FormattedMessage } from "react-intl";
// eslint-disable-next-line no-restricted-imports
import { STRINGS } from "_shared/localization/strings";

export type LocalizationId = keyof typeof STRINGS;

interface Props extends ComponentProps<typeof FormattedMessage> {
  id: LocalizationId;
}

export function LocalizedMessage(props: Props) {
  return <FormattedMessage {...props} />;
}
