import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faEnvelope } from "@fortawesome/sharp-light-svg-icons";
import * as Form from "@radix-ui/react-form";
import { Scrollable } from "_shared/Scrollable/Scrollable";
import { Toast } from "_shared/Toast/Toast";
import { useToast } from "_shared/Toast/ToastContext";
import { Wrapper } from "_shared/Wrapper";
import { useAuth } from "_shared/auth/AuthContext";
import { BackButton } from "_shared/button/BackButton";
import { PrimaryButton } from "_shared/button/PrimaryButton";
import { Field } from "_shared/field/Field";
import { InvalidEmailMessage } from "_shared/field/InvalidEmailMessage";
import { NewPasswordField } from "_shared/field/NewPasswordField/NewPasswordField";
import { RequiredFormMessage } from "_shared/field/RequiredFormMessage";
import { Grow } from "_shared/flex/Grow";
import { TextInput } from "_shared/input/TextInput";
import { LocalizedMessage } from "_shared/localization/LocalizedMessage";
import { PageView, usePageView } from "_shared/pagination/PageView";
import { H1Text } from "_shared/text/H1Text";
import { H3Text } from "_shared/text/H3Text";
import { LabelText } from "_shared/text/LabelText";
import { SmallText } from "_shared/text/SmallText";
import { TruncatedText } from "_shared/text/TruncatedText";
import { Status } from "_shared/utils";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";

export function ResetPassword() {
  const { state = {} } = useLocation();
  const auth = useAuth();
  const { showToast } = useToast();
  const [email, setEmail] = useState(state?.email ?? "");
  const [password, setPassword] = useState("");
  const [oneTimeCode, setOneTimeCode] = useState("");
  const { back, next, ...pageViewProps } = usePageView();
  const [status, setStatus] = useState<Status>("idle");

  return (
    <Grow>
      <Scrollable>
        <Wrapper>
          <div className="flex grow flex-col items-center gap-16">
            <h1>
              <H1Text>
                <LocalizedMessage id="user_reset_password" />
              </H1Text>
            </h1>
            <hr className="w-24 border-gray-42" />
            <PageView
              {...pageViewProps}
              back={back}
              next={next}
              width="20rem"
              pages={[
                <Form.Root
                  key="emailPassword"
                  className="flex flex-col gap-6"
                  onSubmit={async (event) => {
                    event.preventDefault();
                    setStatus("pending");
                    try {
                      await auth.sendForgotPasswordCode(email);
                      next();
                    } catch (e) {
                      showToast((props) => (
                        <Toast {...props} duration={Infinity}>
                          <LocalizedMessage id="error" />
                        </Toast>
                      ));
                    }
                    setStatus("idle");
                  }}
                >
                  <Field name="email">
                    <Form.Label>
                      <LabelText>
                        <LocalizedMessage id="user_email" />
                      </LabelText>
                    </Form.Label>
                    <Form.Control asChild>
                      <TextInput
                        type="email"
                        autoComplete="username"
                        required
                        onChange={setEmail}
                        value={email}
                      />
                    </Form.Control>
                    <RequiredFormMessage />
                    <InvalidEmailMessage />
                  </Field>
                  <Field name="password">
                    <NewPasswordField
                      label={<LocalizedMessage id="user_new_password" />}
                      onChange={setPassword}
                      value={password}
                    />
                    <RequiredFormMessage />
                  </Field>
                  <LocalizedMessage id="user_reset_password_description" />
                  <Form.Submit asChild>
                    <PrimaryButton status={status}>
                      <LocalizedMessage id="user_send_email" />
                    </PrimaryButton>
                  </Form.Submit>
                </Form.Root>,
                <Form.Root
                  key="verification"
                  className="flex flex-col gap-6"
                  onSubmit={async (event) => {
                    event.preventDefault();
                    setStatus("pending");
                    try {
                      await auth.verifyForgotPasswordCode(email, oneTimeCode);
                      await auth.setPassword(password);
                      await auth.signIn({ email, password });
                      next();
                    } catch (e) {
                      showToast((props) => (
                        <Toast {...props} duration={Infinity}>
                          <LocalizedMessage id="error" />
                        </Toast>
                      ));
                    }
                  }}
                >
                  <Field name="emailCode">
                    <LabelText>
                      <Form.Label className="flex items-center gap-1">
                        <FontAwesomeIcon fixedWidth icon={faEnvelope} />
                        <LocalizedMessage id="user_email_verification_code" />
                      </Form.Label>
                    </LabelText>
                    <Form.Control asChild>
                      <TextInput
                        required
                        onChange={setOneTimeCode}
                        value={oneTimeCode}
                      />
                    </Form.Control>
                    <RequiredFormMessage />
                    <SmallText color="light">
                      <TruncatedText>{email}</TruncatedText>
                    </SmallText>
                  </Field>
                  <LocalizedMessage id="user_check_your_email" />
                  <Form.Submit asChild>
                    <PrimaryButton status={status}>
                      <LocalizedMessage id="user_verify" />
                    </PrimaryButton>
                  </Form.Submit>
                  <BackButton onClick={back} />
                </Form.Root>,
                <div
                  key="success"
                  className="flex flex-col items-center gap-8 text-center"
                >
                  <div className="text-6xl">
                    <FontAwesomeIcon icon={faCheck} />
                  </div>
                  <h3>
                    <H3Text>
                      <LocalizedMessage id="user_password_reset" />
                    </H3Text>
                  </h3>
                  <Link to="/">
                    <PrimaryButton>
                      <LocalizedMessage id="user_sign_in_action" />
                    </PrimaryButton>
                  </Link>
                </div>,
              ]}
            />
          </div>
        </Wrapper>
      </Scrollable>
    </Grow>
  );
}
