import { ComponentPropsWithoutRef, forwardRef } from "react";

interface Props extends Omit<ComponentPropsWithoutRef<"input">, "className"> {}

export const Input = forwardRef<HTMLInputElement, Props>(
  function Input(props, ref) {
    return (
      <input
        ref={ref}
        className="min-w-0 grow bg-transparent p-2 focus:outline-none"
        {...props}
      />
    );
  },
);
