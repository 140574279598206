import { CustomIconDefaults } from "_shared/CustomIcon";
import classNames from "classnames";
import { ReactNode } from "react";
import { Link } from "react-router-dom";

interface Props {
  status?: "disabled" | "comingSoon";
  icon?: ReactNode;
  title?: ReactNode;
  divider?: ReactNode;
  footer?: ReactNode;
  to: string;
}

export function FeatureCard(props: Props) {
  const {
    status,
    icon,
    title,
    divider = <hr className="border-gray-86 dark:border-gray-42" />,
    footer,
    to,
  } = props;
  return (
    <Link
      to={to}
      target={to.startsWith("http") ? "_blank" : undefined}
      className={classNames(
        "flex grow flex-col rounded-sm py-4 pb-5 transition-all",
        {
          "bg-white shadow-md hover:scale-[101%] hover:shadow-lg active:shadow-none dark:bg-gray-31":
            status === undefined,
          "pointer-events-none border border-gray-86 opacity-75 dark:border-gray-42":
            status === "comingSoon",
          "pointer-events-none bg-white opacity-75 dark:bg-gray-31":
            status === "disabled",
        },
      )}
    >
      <div className="flex grow flex-col gap-2">
        <div className="self-end pr-4 text-3xl">
          <CustomIconDefaults stroke={status ? "solid" : "gradient"}>
            {icon}
          </CustomIconDefaults>
        </div>
        <div className="grow px-6 font-montserrat text-lg font-bold uppercase text-gray-31 dark:text-white">
          {title}
        </div>
      </div>
      {divider ? <div className="px-6 pb-3 pt-2">{divider}</div> : null}
      <div className="flex gap-3 px-6">{footer}</div>
    </Link>
  );
}
