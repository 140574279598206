import { SmallText } from "_shared/text/SmallText";
import { ReactNode } from "react";

interface Props {
  children?: ReactNode;
}

export function InputAffix(props: Props) {
  const { children } = props;
  if (!children) return null;
  return (
    <div className="flex items-center bg-gray-94 p-2 dark:bg-gray-31">
      <SmallText color="inherit">{children}</SmallText>
    </div>
  );
}
