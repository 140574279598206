import * as ScrollArea from "@radix-ui/react-scroll-area";
import classNames from "classnames";
import { ReactNode, useState } from "react";
import { Scrollbar } from "./Scrollbar";

interface Props {
  axis?: "x" | "y" | "both";
  children?: ReactNode;
}

export function Scrollable(props: Props) {
  const { axis = "y", children } = props;
  const [isScrollbarInUse, setIsScrollbarInUse] = useState(false);
  return (
    <ScrollArea.Root
      type="hover"
      scrollHideDelay={400}
      className="flex min-h-0 flex-col"
    >
      <ScrollArea.Viewport
        className={classNames("flex flex-col [&>div]:!contents", {
          "scroll-smooth": !isScrollbarInUse,
          "!overflow-x-clip": axis === "y",
          "!overflow-y-clip": axis === "x",
        })}
      >
        {children}
      </ScrollArea.Viewport>
      <Scrollbar
        orientation="horizontal"
        onMouseEnter={() => setIsScrollbarInUse(true)}
        onMouseLeave={() => setIsScrollbarInUse(false)}
      />
      <Scrollbar
        orientation="vertical"
        onMouseEnter={() => setIsScrollbarInUse(true)}
        onMouseLeave={() => setIsScrollbarInUse(false)}
      />
      <ScrollArea.Corner />
    </ScrollArea.Root>
  );
}
