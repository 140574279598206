export const industries = [
  { value: "1", label: "Business, consultancy or management" },
  { value: "2", label: "Accountancy, banking or finance" },
  { value: "3", label: "Charity and voluntary work" },
  { value: "4", label: "Creative arts or design" },
  { value: "5", label: "Energy and utilities" },
  { value: "6", label: "Engineering or manufacturing" },
  { value: "7", label: "Environment or agriculture" },
  { value: "8", label: "Healthcare" },
  { value: "9", label: "Hospitality or events" },
  { value: "10", label: "Computing or IT" },
  { value: "11", label: "Law" },
  { value: "12", label: "Law enforcement and security" },
  { value: "13", label: "Leisure, sport or tourism" },
  { value: "14", label: "Marketing, advertising or PR" },
  { value: "15", label: "Media or digital" },
  { value: "16", label: "Property or construction" },
  { value: "17", label: "Public services or administration" },
  { value: "18", label: "Recruitment or HR" },
  { value: "19", label: "Retail" },
  { value: "20", label: "Sales" },
  { value: "21", label: "Science or pharmaceuticals" },
  { value: "22", label: "Social care" },
  { value: "23", label: "Teacher training or education" },
  { value: "24", label: "Transport or logistics" },
  { value: "25", label: "Student" },
  { value: "26", label: "Unemployed" },
] as const;

export type Industry = (typeof industries)[number]["value"];
