import { Input } from "_shared/input/Input";
import { InputProps } from "_shared/input/Input.types";
import { InputAffix } from "_shared/input/InputAffix";
import { InputWrapper } from "_shared/input/InputWrapper";
import { forwardRef } from "react";

interface Props extends InputProps {
  onChange?: (newValue: string) => void;
  type?: "text" | "email" | "tel" | "password" | "number" | "url";
  defaultValue?: string;
  value?: string;
}

export const TextInput = forwardRef<HTMLInputElement, Props>(
  function TextInput(props, ref) {
    const {
      prefix,
      suffix,
      type = "text",
      onChange,
      readOnly,
      ...inputProps
    } = props;
    return (
      <InputWrapper
        data-invalid={inputProps["data-invalid"]}
        readOnly={readOnly} // Don't pass on to input as it disables @radix-ui validation
      >
        <InputAffix>{prefix}</InputAffix>
        <Input
          ref={ref}
          type={type}
          onChange={(e) => onChange?.(e.target.value)}
          {...inputProps}
        />
        <InputAffix>{suffix}</InputAffix>
      </InputWrapper>
    );
  },
);
