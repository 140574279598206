import classNames from "classnames";
import { ReactNode } from "react";

interface Props {
  children?: ReactNode;
  title?: ReactNode;
  action?: ReactNode;
  isActive?: boolean;
}

export function TimelineEvent(props: Props) {
  const { children, title, action, isActive } = props;
  return (
    <div className="ms-4 border-s-2 border-s-primary">
      <div
        className={classNames(
          "-ms-4 border-e-2 pb-6 pe-3 ps-[0.4375rem] pt-3",
          isActive ? "bg-shade border-e-primary" : "border-e-transparent",
        )}
      >
        <div className="flex items-start gap-3">
          <div className="flex min-w-0 flex-1 flex-col">
            <div className="flex items-center gap-2">
              <div className="aspect-square w-4 rounded-full border-2 border-primary bg-gray-94 dark:bg-gray-27" />
              {title}
            </div>
            {children}
          </div>
          {action}
        </div>
      </div>
    </div>
  );
}
