import * as Form from "@radix-ui/react-form";
import { SecondaryButton } from "_shared/button/SecondaryButton";
import { Field } from "_shared/field/Field";
import { useTelInput } from "_shared/input/TelInput/useTelInput";
import { TextInput } from "_shared/input/TextInput";
import { LocalizedMessage } from "_shared/localization/LocalizedMessage";
import { LabelText } from "_shared/text/LabelText";
import { SmallText } from "_shared/text/SmallText";
import { Link } from "react-router-dom";
import { UserProfile } from "user/User.types";

export function UserSettingsAccount(props: {
  userProfile: UserProfile | undefined;
}) {
  const { email, phoneCountryCode, MobileNo } = props.userProfile ?? {};
  const [telInputProps] = useTelInput();
  const phone =
    phoneCountryCode && MobileNo ? `+${phoneCountryCode}${MobileNo}` : "";

  return (
    <>
      <Field name="email">
        <LabelText>
          <LocalizedMessage id="user_settings_email" />
        </LabelText>
        <Form.Control asChild>
          <TextInput type="email" value={email} readOnly />
        </Form.Control>
        <SmallText color="light">
          <LocalizedMessage id="user_settings_cannot_change_email" />
        </SmallText>
      </Field>
      <Field name="mobilePhone">
        <LabelText>
          <LocalizedMessage id="user_mobile_number" />
        </LabelText>
        <Form.Control asChild>
          <TextInput value={phone} {...telInputProps} readOnly />
        </Form.Control>
        <SmallText color="light">
          <LocalizedMessage id="user_settings_cannot_change_phone" />
        </SmallText>
      </Field>
      <Field name="password">
        <LabelText>
          <LocalizedMessage id="user_settings_password" />
        </LabelText>
        <Link
          to="/user/reset-password"
          state={{ email }}
          className="self-start"
          target="_blank"
        >
          <SecondaryButton>
            <LocalizedMessage id="user_settings_password_reset" />
          </SecondaryButton>
        </Link>
      </Field>
    </>
  );
}
