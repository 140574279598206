import { ReactNode, createContext, useContext } from "react";
import invariant from "tiny-invariant";

interface FeatureFlagsContextValue {
  isAuthOn: boolean;
  isMessagesOn: boolean;
  isFounderRewardsOn: boolean;
  isPortfolioOn: boolean;
  isFounderInsightsOn: boolean;
  isCommunitiesOn: boolean;
  isJottingOn: boolean;
  isBigProblemOn: boolean;
  isIndustrySurveyOn: boolean;
  isStartupsOn: boolean;
  isStartupMarketLabOn: boolean;
  isStartupFundingOn: boolean;
  isStartupSalesFunnelOn: boolean;
  isStartupCRMOn: boolean;
  isStartupRecruitmentOn: boolean;
  isUserSubscriptionOn: boolean;
  isStaticSurveyOn: boolean;
  isExtendedUserSettingsOn: boolean;
  isDevApiServerOn: boolean;
  isSubmitDeckOn: boolean;
}

const FeatureFlagsContext = createContext<FeatureFlagsContextValue | undefined>(
  undefined,
);

export function useFeatureFlags() {
  const context = useContext(FeatureFlagsContext);
  invariant(
    context !== undefined,
    "useFeatureFlags() must be used within a FeatureFlagsProvider.",
  );
  return context;
}

export function useFeatureFlagsOrUndefined() {
  return useContext(FeatureFlagsContext);
}

interface Props {
  children?: ReactNode;
}

export function FeatureFlagsProvider(props: Props) {
  return (
    <FeatureFlagsContext.Provider
      value={{
        isAuthOn: true,
        isMessagesOn: false,
        isFounderRewardsOn: false,
        isPortfolioOn: true,
        isFounderInsightsOn: false,
        isCommunitiesOn: false,
        isJottingOn: false,
        isBigProblemOn: false,
        isIndustrySurveyOn: false,
        isStartupsOn:
          process.env.NODE_ENV === "development" ||
          process.env.DEPLOYMENT_ENV === "preview",
        isStartupMarketLabOn: true,
        isStartupFundingOn: false,
        isStartupSalesFunnelOn: false,
        isStartupCRMOn: false,
        isStartupRecruitmentOn: false,
        isUserSubscriptionOn: false,
        isStaticSurveyOn:
          process.env.NODE_ENV === "development" ||
          process.env.DEPLOYMENT_ENV === "preview",
        isExtendedUserSettingsOn: false,
        isDevApiServerOn:
          process.env.NODE_ENV === "development" ||
          process.env.DEPLOYMENT_ENV === "preview",
        isSubmitDeckOn: true,
      }}
      {...props}
    />
  );
}
