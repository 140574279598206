import * as Form from "@radix-ui/react-form";
import { Field } from "_shared/field/Field";
import { Select } from "_shared/input/Select/Select";
import { TextInput } from "_shared/input/TextInput";
import { LocalizedMessage } from "_shared/localization/LocalizedMessage";
import { LabelText } from "_shared/text/LabelText";
import { useState } from "react";
import {
  ETHNICITIES,
  Ethnicity,
  PARENTAL_EDUCATION_MILESTONES,
  ParentalEducationMilestone,
} from "user/User.types";

export function UserSettingsBackground() {
  const [ethnicity, setEthnicity] = useState<Ethnicity>();
  const [motherEducation, setMotherEducation] =
    useState<ParentalEducationMilestone>();
  const [fatherEducation, setFatherEducation] =
    useState<ParentalEducationMilestone>();
  const [languagesSpoken, setLanguagesSpoken] = useState("Morroccan");

  return (
    <>
      <Field name="ethnicity">
        <LabelText>
          <LocalizedMessage id="user_settings_ethnicity" />
        </LabelText>
        <Form.Control asChild>
          <Select
            value={ethnicity}
            onChange={setEthnicity}
            options={ETHNICITIES.map((value) => ({
              value,
              label: <LocalizedMessage id={`user_ethnicity_option_${value}`} />,
            }))}
          />
        </Form.Control>
      </Field>
      <Field name="motherEducation">
        <LabelText>
          <LocalizedMessage id="user_settings_education_mother" />
        </LabelText>
        <Form.Control asChild>
          <Select
            value={motherEducation}
            onChange={setMotherEducation}
            options={PARENTAL_EDUCATION_MILESTONES.map((value) => ({
              value,
              label: (
                <LocalizedMessage
                  id={`user_settings_education_level_option_${value}`}
                />
              ),
            }))}
          />
        </Form.Control>
      </Field>
      <Field name="fatherEducation">
        <LabelText>
          <LocalizedMessage id="user_settings_education_father" />
        </LabelText>
        <Form.Control asChild>
          <Select
            value={fatherEducation}
            onChange={setFatherEducation}
            options={PARENTAL_EDUCATION_MILESTONES.map((value) => ({
              value,
              label: (
                <LocalizedMessage
                  id={`user_settings_education_level_option_${value}`}
                />
              ),
            }))}
          />
        </Form.Control>
      </Field>
      <Field name="languagesSpoken">
        <LabelText>
          <LocalizedMessage id="user_settings_languages_spoken" />
        </LabelText>
        <Form.Control asChild>
          <TextInput
            type="text"
            value={languagesSpoken}
            onChange={setLanguagesSpoken}
          />
        </Form.Control>
      </Field>
    </>
  );
}
