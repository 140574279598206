import { useQuery } from "@tanstack/react-query";
import { useAuth, useUser } from "_shared/auth/AuthContext";

export interface Startup {
  portfolio_id: string;
  SUTitle: string;
  SUName: string;
  SUAddress: string;
  SUPhoneCode: string;
  SUPhone: string;
  SUEmail: string;
  SUEmailForCor: string;
  SUWorkPhoneCode: string;
  SUWorkPhone: string;
  SUNotes: string;
  startup_id: string;
}

export function useStartupsQuery() {
  const { post } = useAuth();
  const user = useUser();

  return useQuery({
    queryKey: ["/startup-listing", user.email],
    queryFn: async () => {
      const fetchedStartupList = (await post("startup-listing")) as {
        0: Startup[];
      };
      const startupsById: Record<string, Startup> = Object.fromEntries(
        fetchedStartupList[0].map((startup) => [startup.startup_id, startup]),
      );
      return startupsById;
    },
    refetchOnMount: false,
  });
}
