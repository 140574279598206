import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCogs,
  faPlus,
  faSearch,
  faTrash,
} from "@fortawesome/sharp-light-svg-icons";
import { createColumnHelper } from "@tanstack/react-table";
import { Breadcrumb } from "_shared/Breadcrumbs/Breadcrumb";
import { Breadcrumbs } from "_shared/Breadcrumbs/Breadcrumbs";
import { KebabMenu } from "_shared/Popover/KebabMenu/KebabMenu";
import { KebabMenuItem } from "_shared/Popover/KebabMenu/KebabMenuItem";
import { Table } from "_shared/Table/Table";
import { PrimaryButton } from "_shared/button/PrimaryButton";
import { SecondaryButton } from "_shared/button/SecondaryButton";
import { LocalizedMessage } from "_shared/localization/LocalizedMessage";
import { H1Text } from "_shared/text/H1Text";
import { SmallText } from "_shared/text/SmallText";
import { Link, useNavigate } from "react-router-dom";

interface Product {
  name: string;
}

const columnHelper = createColumnHelper<Product>();

export const mockProducts: Array<Product> = [
  {
    name: "Fraud-o-matic",
  },
  {
    name: "Fraud Buster 2000",
  },
];

const columns = [
  columnHelper.accessor("name", {
    header: "Name",
    cell: ({ getValue }) => (
      <Link to="0" className="text-primary underline-offset-2 hover:underline">
        {getValue()}
      </Link>
    ),
  }),
  columnHelper.display({
    id: "actions",
    meta: {
      size: "min-content",
    },
    cell: ({ row }) => {
      const navigate = useNavigate();
      return (
        <div className="flex grow justify-end gap-2">
          <SecondaryButton
            onClick={() => navigate(`${row.id}/challenges`)}
            icon={<FontAwesomeIcon icon={faSearch} />}
          >
            Find challenges
          </SecondaryButton>
          <KebabMenu>
            <KebabMenuItem onClick={() => navigate(row.id)}>
              <FontAwesomeIcon fixedWidth icon={faCogs} /> Configure
            </KebabMenuItem>
            <KebabMenuItem>
              <FontAwesomeIcon fixedWidth icon={faTrash} /> Delete
            </KebabMenuItem>
          </KebabMenu>
        </div>
      );
    },
  }),
];

export function MarketLabProducts() {
  return (
    <div className="flex flex-col gap-4">
      <Breadcrumbs>
        <Breadcrumb to="/">
          <LocalizedMessage id="startups_title" />
        </Breadcrumb>
        <Breadcrumb to={`/startups/0`}>Kavedon Kapital</Breadcrumb>
        <Breadcrumb>
          <LocalizedMessage id="app_marketLab" />
        </Breadcrumb>
      </Breadcrumbs>
      <div className="flex items-center">
        <div className="flex-1">
          <h1>
            <H1Text>
              <LocalizedMessage id="market_lab_products" />
            </H1Text>
          </h1>
          <p>
            <SmallText color="light">
              Validate your products against enterprise challenges
            </SmallText>
          </p>
        </div>
        <PrimaryButton icon={<FontAwesomeIcon icon={faPlus} />}>
          Add product
        </PrimaryButton>
      </div>
      <Table columns={columns} data={mockProducts} />
    </div>
  );
}
