import * as Form from "@radix-ui/react-form";
import { useFeatureFlags } from "_shared/FeatureFlagsContext";
import { Scrollable } from "_shared/Scrollable/Scrollable";
import { Wrapper } from "_shared/Wrapper";
import { useAuth } from "_shared/auth/AuthContext";
import { InlineButton } from "_shared/button/InlineButton";
import { PrimaryButton } from "_shared/button/PrimaryButton";
import { Field } from "_shared/field/Field";
import { InvalidEmailMessage } from "_shared/field/InvalidEmailMessage";
import { NewPasswordField } from "_shared/field/NewPasswordField/NewPasswordField";
import { RequiredFormMessage } from "_shared/field/RequiredFormMessage";
import { Grow } from "_shared/flex/Grow";
import { useTelInput } from "_shared/input/TelInput/useTelInput";
import { TextInput } from "_shared/input/TextInput";
import {
  LocalizationId,
  LocalizedMessage,
} from "_shared/localization/LocalizedMessage";
import { PageView, usePageView } from "_shared/pagination/PageView";
import { Stepper } from "_shared/pagination/Stepper";
import { H1Text } from "_shared/text/H1Text";
import { H2Text } from "_shared/text/H2Text";
import { LabelText } from "_shared/text/LabelText";
import { SmallText } from "_shared/text/SmallText";
import { Status } from "_shared/utils";
import { useState } from "react";
import { Link } from "react-router-dom";
import { SetLinkedInHandle } from "./SetLinkedInHandle";
import { SurveyPrompt } from "./SurveyPrompt";
import { Verified } from "./Verified";
import { Verify } from "./Verify";

export function Register() {
  const { isAuthOn } = useFeatureFlags();
  const auth = useAuth();
  const { back, next, ...pageViewProps } = usePageView();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [telInputProps, tel] = useTelInput();
  const [status, setStatus] = useState<Status>("idle");
  const [errorMessageId, setErrorMessageId] = useState<LocalizationId>();

  const pages = [
    <Form.Root
      key="register"
      className="flex flex-col gap-6"
      onSubmit={async (event) => {
        event.preventDefault();
        if (!isAuthOn) {
          next();
          return;
        }
        setStatus("pending");
        setErrorMessageId(undefined);
        try {
          await auth.register({
            firstName,
            lastName,
            email,
            phoneCountryCode: tel.countryCode,
            phoneWithoutCountryCode: tel.withoutCountryCode,
          });
          setStatus("success");
          next();
        } catch (error) {
          if (!(error instanceof Error)) throw error;
          switch (error.message) {
            case "mobile_Already_Registered":
              setErrorMessageId("user_error_phone_taken");
              break;
            case "email_Already_Registered":
              setErrorMessageId("user_error_email_taken");
              break;
          }
          setStatus("error");
        }
      }}
    >
      <h2>
        <H2Text>
          <LocalizedMessage id="user_your_details" />
        </H2Text>
      </h2>
      <Field name="firstName">
        <Form.Label>
          <LabelText>
            <LocalizedMessage id="user_first_name" />
          </LabelText>
        </Form.Label>
        <Form.Control asChild>
          <TextInput
            autoComplete="given-name"
            required
            onChange={setFirstName}
            value={firstName}
          />
        </Form.Control>
        <RequiredFormMessage />
      </Field>
      <Field name="lastName">
        <Form.Label>
          <LabelText>
            <LocalizedMessage id="user_last_name" />
          </LabelText>
        </Form.Label>
        <Form.Control asChild>
          <TextInput
            autoComplete="family-name"
            required
            onChange={setLastName}
            value={lastName}
          />
        </Form.Control>
        <RequiredFormMessage />
      </Field>
      <Field name="email">
        <Form.Label>
          <LabelText>
            <LocalizedMessage id="user_personal_email" />
          </LabelText>
        </Form.Label>
        <Form.Control asChild>
          <TextInput
            type="email"
            autoComplete="home email"
            required
            onChange={setEmail}
            value={email}
          />
        </Form.Control>
        <InvalidEmailMessage />
        <RequiredFormMessage />
        <SmallText color="light">
          <LocalizedMessage id="user_personal_email_description" />
        </SmallText>
      </Field>
      <Field name="password">
        <NewPasswordField
          label={<LocalizedMessage id="user_password" />}
          value={password}
          onChange={setPassword}
        />
        <RequiredFormMessage />
      </Field>
      <Field name="mobilePhone">
        <Form.Label>
          <LabelText>
            <LocalizedMessage id="user_mobile_number" />
          </LabelText>
        </Form.Label>
        <Form.Control asChild>
          <TextInput {...telInputProps} autoComplete="home tel" required />
        </Form.Control>
        <Form.Message match="patternMismatch">
          <SmallText color="warning">
            <LocalizedMessage id="user_phone_invalid" />
          </SmallText>
        </Form.Message>
        <RequiredFormMessage />
        <SmallText color="light">
          <LocalizedMessage id="user_we_take_security_seriously" />
        </SmallText>
      </Field>
      <Field name="terms">
        <div className="flex items-center gap-2">
          <Form.Control asChild>
            <input
              type="checkbox"
              className="aspect-square h-6 accent-primary"
              required
            />
          </Form.Control>
          <Form.Label>
            <SmallText color="inherit">
              <LocalizedMessage
                id="user_i_agree_to_terms"
                values={{
                  a: (chunks) => (
                    <Link to="/terms" target="_blank">
                      <InlineButton>{chunks}</InlineButton>
                    </Link>
                  ),
                }}
              />
            </SmallText>
          </Form.Label>
        </div>
        <RequiredFormMessage />
      </Field>
      {status === "error" ? (
        <div>
          <SmallText color="warning">
            <LocalizedMessage
              id={errorMessageId ?? "error"}
              values={{
                a: (chunks) => (
                  <Link to="/user/sign-in">
                    <InlineButton>{chunks}</InlineButton>
                  </Link>
                ),
              }}
            />
          </SmallText>
        </div>
      ) : null}
      <Form.Submit asChild>
        <PrimaryButton status={status}>
          <LocalizedMessage id="user_create_account" />
        </PrimaryButton>
      </Form.Submit>
    </Form.Root>,
    <Verify
      key="verify"
      email={email}
      mobilePhone={tel.e164}
      password={password}
      onSuccess={next}
    />,
    <Verified key="verified" firstName={firstName} next={next} />,
    <SetLinkedInHandle key="setLinkedIn" next={next} />,
    <SurveyPrompt key="survey" />,
  ];

  return (
    <Grow>
      <Scrollable>
        <Wrapper>
          <div className="flex flex-col gap-16">
            <div className="flex flex-col items-center gap-10">
              <h1>
                <H1Text>
                  <LocalizedMessage id="user_create_revveon_account" />
                </H1Text>
              </h1>
              <Stepper
                currentIndex={pageViewProps.pageIndex}
                length={pages.length}
              />
            </div>
            <PageView
              {...pageViewProps}
              back={back}
              next={next}
              width="20rem"
              pages={pages}
            />
          </div>
        </Wrapper>
      </Scrollable>
    </Grow>
  );
}
