import classNames from "classnames";
import { ReactNode } from "react";

interface Props {
  children?: ReactNode;
  color: "inherit" | "light" | "warning";
}

export function SmallText({ children, color }: Props) {
  return (
    <small
      className={classNames(
        "contents text-sm font-normal leading-snug tracking-wide",
        {
          "text-gray-42 dark:text-gray-80": color === "light",
          "text-red-50 dark:text-yellow-57": color === "warning",
        },
      )}
    >
      {children}
    </small>
  );
}
