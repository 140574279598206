import { Tooltip } from "_shared/Tooltip/Tooltip";
import { useSize } from "_shared/utils";
import { ReactNode, useRef } from "react";

interface Props {
  children?: ReactNode;
}

export function TruncatedText(props: Props) {
  const { children } = props;
  const constrainedRef = useRef<HTMLDivElement>(null);
  const [constrainedWidth] = useSize(constrainedRef);
  const unconstrainedRef = useRef<HTMLDivElement>(null);
  const [unconstrainedWidth] = useSize(unconstrainedRef);
  const isOverflowing = unconstrainedWidth > constrainedWidth;

  const elements = (
    <div
      ref={constrainedRef}
      className="relative min-w-0 overflow-clip overflow-ellipsis whitespace-nowrap"
    >
      {children}
      <span ref={unconstrainedRef} className="invisible absolute">
        {children}
      </span>
    </div>
  );

  if (isOverflowing) {
    return <Tooltip content={children}>{elements}</Tooltip>;
  }
  return elements;
}
