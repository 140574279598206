import * as Form from "@radix-ui/react-form";
import { AriaLabelableProps } from "_shared/a11y";
import { RequiredFormMessage } from "_shared/field/RequiredFormMessage";
import { Grow } from "_shared/flex/Grow";
import { Checkbox } from "_shared/input/Checkbox";
import { CheckboxGroupLabel } from "_shared/input/CheckboxGroup/CheckboxGroupLabel";
import { TextInput } from "_shared/input/TextInput";
import { LocalizedMessage } from "_shared/localization/LocalizedMessage";
import { SmallText } from "_shared/text/SmallText";
import { toggleInSet } from "_shared/utils";
import { ReactNode, useId, useRef } from "react";

type Props = AriaLabelableProps & {
  onChange: (newValue: Set<string>) => void;
  value: Set<string>;
  options: ReadonlyArray<{
    value: string;
    label: ReactNode;
  }>;
  /**
   * If true, the user must tick at least one checkbox
   */
  required?: boolean;
};

export function CheckboxGroupWithOther(props: Props) {
  const {
    value: groupValue,
    onChange,
    options,
    required,
    ...ariaProps
  } = props;
  const values = new Set(options.map((option) => option.value));
  const otherValue = [...groupValue].find((v) => !values.has(v));
  const isOtherSelected = otherValue !== undefined;
  const otherFieldName = useId();
  const otherTextRef = useRef<HTMLInputElement>(null);
  return (
    <div role="group" className="flex flex-col" {...ariaProps}>
      <div className="flex flex-col">
        {props.options.map(({ value, label }) => {
          const checked = groupValue.has(value);
          return (
            <div key={value} className="hover:bg-shade">
              <CheckboxGroupLabel checked={checked}>
                <Form.Control asChild>
                  <Checkbox
                    value={value}
                    id="" // Stop @radix-ui Form.Control giving every checkbox the same ID
                    checked={checked}
                    onChange={() => onChange(toggleInSet(value, groupValue))}
                    required={required && groupValue.size === 0}
                  />
                </Form.Control>
                {label}
              </CheckboxGroupLabel>
            </div>
          );
        })}
      </div>
      <div className="hover:bg-shade flex items-center">
        <CheckboxGroupLabel checked={isOtherSelected}>
          <Form.Control asChild>
            <Checkbox
              id="" // Stop @radix-ui Form.Control giving every radio the same ID
              checked={isOtherSelected}
              onChange={(e) => {
                const { checked } = e.target;
                const newValue = new Set(groupValue);
                if (otherValue !== undefined) newValue.delete(otherValue);
                if (checked) newValue.add("");
                onChange(newValue);
                otherTextRef.current?.focus();
              }}
              required={required && groupValue.size === 0}
            />
          </Form.Control>
          <LocalizedMessage id="input_other" />
        </CheckboxGroupLabel>
        <Form.Field name={otherFieldName} asChild>
          <Grow>
            <Form.Control asChild>
              <TextInput
                ref={otherTextRef}
                aria-label="Other response"
                required={isOtherSelected}
                value={otherValue ?? ""}
                onChange={(newOtherValue) => {
                  const newGroupValue = new Set(groupValue);
                  if (otherValue !== undefined) {
                    newGroupValue.delete(otherValue);
                  }
                  newGroupValue.add(newOtherValue);
                  onChange(newGroupValue);
                }}
              />
            </Form.Control>
          </Grow>
        </Form.Field>
      </div>
      <RequiredFormMessage type="select" />
      <Form.Message name={otherFieldName} match="valueMissing">
        <SmallText color="warning">
          <LocalizedMessage id="input_error_required_other" />
        </SmallText>
      </Form.Message>
    </div>
  );
}
