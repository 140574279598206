import { Wrapper } from "_shared/Wrapper";
import { Outlet } from "react-router-dom";

export function MarketLab() {
  return (
    <Wrapper>
      <Outlet />
    </Wrapper>
  );
}
