import { ReactNode } from "react";

interface SideNavLinkProps {
  href?: string;
  children?: ReactNode;
}

export function SideNavLink(props: SideNavLinkProps) {
  const { href, children } = props;
  return (
    <a href={href} className="text-primary underline-offset-4 hover:underline">
      {children}
    </a>
  );
}
