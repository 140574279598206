import { FC, PropsWithChildren, createContext, useContext } from "react";

type PropsWithoutChildren<TProps> = Omit<PropsWithChildren<TProps>, "children">;

/**
 * Set default props via context.
 *
 * Passed props will always take precedence over defaults.
 *
 * @example
 * const [ButtonDefaults, useButtonDefaults] = createDefaults<IButtonProps>({
 *   size: "medium",
 * });
 *
 * const Button = (props) => {
 *   const {size} = useButtonDefaults(props);
 *   //...
 * };
 *
 * const App = () => {
 *   return (
 *     <ButtonDefaults size="small">
 *       <Button>Click me</Button>
 *     </ButtonDefaults>
 *   );
 * };
 */
export function createDefaults<TProps extends object>(): [
  DefaultsProvider: FC<Partial<PropsWithChildren<TProps>>>,
  useDefaults: (props: TProps) => TProps,
] {
  const context = createContext<
    Partial<PropsWithoutChildren<TProps>> | undefined
  >(undefined);
  function Provider({
    children,
    ...defaults
  }: Partial<PropsWithChildren<TProps>>) {
    return <context.Provider value={defaults}>{children}</context.Provider>;
  }
  function useDefaults(props: TProps): TProps {
    const defaultProps = useContext(context);
    return { ...defaultProps, ...props };
  }
  return [Provider, useDefaults];
}
