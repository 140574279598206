import * as Form from "@radix-ui/react-form";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useFeatureFlags } from "_shared/FeatureFlagsContext";
import { Scrollable } from "_shared/Scrollable/Scrollable";
import { SideNav } from "_shared/SideNav/SideNav";
import { SideNavLink } from "_shared/SideNav/SideNavLink";
import { Toast } from "_shared/Toast/Toast";
import { useToast } from "_shared/Toast/ToastContext";
import { Wrapper } from "_shared/Wrapper";
import { useAuth, useUser } from "_shared/auth/AuthContext";
import { PrimaryButton } from "_shared/button/PrimaryButton";
import { Grow } from "_shared/flex/Grow";
import { LocalizedMessage } from "_shared/localization/LocalizedMessage";
import { H1Text } from "_shared/text/H1Text";
import { pickBy } from "lodash";
import { ReactNode, useEffect, useState } from "react";
import { UserProfile } from "user/User.types";
import { UserSettingsAccount } from "user/UserSettingsAccount";
import { UserSettingsBackground } from "user/UserSettingsBackground";
import { UserSettingsEducation } from "user/UserSettingsEducation";
import { UserSettingsOther } from "user/UserSettingsOther";
import { UserSettingsProfile } from "user/UserSettingsProfile";

export function UserSettings() {
  const { isExtendedUserSettingsOn } = useFeatureFlags();
  const { post } = useAuth();
  const user = useUser();
  const { showToast } = useToast();
  const query = useQuery({
    queryKey: ["/fetch-founder-profile", user.email],
    queryFn: async () => {
      const fetchedUserProfile = (await post("fetch-founder-profile", {
        email: user.email,
      })) as {
        0: {
          personal: [UserProfile];
          socialfetch: [UserProfile];
          selectedLangArray: string[];
        };
      };
      return {
        // Endpoint returns null values which need to be filtered out for our form fields using pickBy()
        ...pickBy(fetchedUserProfile[0].personal[0]),
        ...pickBy(fetchedUserProfile[0].socialfetch[0]),
        languagesSpoken: fetchedUserProfile[0].selectedLangArray,
      };
    },
  });
  const mutation = useMutation({
    mutationFn: async () => {
      return await post("update-founder-profile", userProfile);
    },
    onSuccess: async () => {
      await query.refetch();
      setPendingUserProfile(undefined);
    },
    onError: () => {
      showToast((props) => (
        <Toast {...props} duration={Infinity}>
          <LocalizedMessage id="error" />
        </Toast>
      ));
    },
  });
  const [pendingUserProfile, setPendingUserProfile] = useState<UserProfile>();
  const userProfile = { ...query.data, ...pendingUserProfile };

  useEffect(() => {
    if (query.status === "error") {
      showToast((props) => (
        <Toast {...props} duration={Infinity}>
          <LocalizedMessage id="error" />
        </Toast>
      ));
    }
  }, [query.isError]);

  return (
    <Grow>
      <Scrollable>
        <Wrapper>
          <div className="flex items-start gap-16">
            <div className="flex max-w-sm grow flex-col gap-10">
              <h1>
                <H1Text>
                  <LocalizedMessage id="user_settings_title" />
                </H1Text>
              </h1>
              {query.status !== "success" ? null : (
                <Form.Root
                  className="flex flex-col items-stretch gap-14"
                  onSubmit={(e) => {
                    e.preventDefault();
                    mutation.mutate();
                  }}
                >
                  <div id="account" className=" flex flex-col gap-6">
                    <Subtitle>
                      <LocalizedMessage id="user_settings_account" />
                    </Subtitle>
                    <UserSettingsAccount userProfile={userProfile} />
                  </div>
                  <div id="profile" className=" flex flex-col gap-6">
                    <Subtitle>
                      <LocalizedMessage id="user_settings_profile" />
                    </Subtitle>
                    <UserSettingsProfile
                      userProfile={userProfile}
                      setUserProfile={setPendingUserProfile}
                    />
                  </div>
                  {isExtendedUserSettingsOn ? (
                    <>
                      <div id="background" className=" flex flex-col gap-6">
                        <Subtitle>
                          <LocalizedMessage id="user_settings_background" />
                        </Subtitle>
                        <UserSettingsBackground />
                      </div>
                      <div id="education" className=" flex flex-col gap-6">
                        <Subtitle>
                          <LocalizedMessage id="user_settings_education" />
                        </Subtitle>
                        <UserSettingsEducation />
                      </div>
                      <div id="other" className=" flex flex-col gap-6">
                        <Subtitle>
                          <LocalizedMessage id="user_settings_other" />
                        </Subtitle>
                        <UserSettingsOther />
                      </div>
                    </>
                  ) : null}
                  <div className="sticky bottom-0 self-stretch border-t border-gray-86 bg-gray-94 py-4 pb-8 pt-4 dark:border-gray-42 dark:bg-gray-27">
                    <Form.Submit asChild>
                      <PrimaryButton
                        status={mutation.status}
                        disabled={pendingUserProfile === undefined}
                      >
                        <LocalizedMessage id="button_save" />
                      </PrimaryButton>
                    </Form.Submit>
                  </div>
                </Form.Root>
              )}
            </div>
            <SideNav>
              <SideNavLink href="#account">
                <LocalizedMessage id="user_settings_account" />
              </SideNavLink>
              <SideNavLink href="#profile">
                <LocalizedMessage id="user_settings_profile" />
              </SideNavLink>
              {isExtendedUserSettingsOn ? (
                <>
                  <SideNavLink href="#background">
                    <LocalizedMessage id="user_settings_background" />
                  </SideNavLink>
                  <SideNavLink href="#education">
                    <LocalizedMessage id="user_settings_education" />
                  </SideNavLink>
                  <SideNavLink href="#other">
                    <LocalizedMessage id="user_settings_other" />
                  </SideNavLink>
                </>
              ) : null}
            </SideNav>
          </div>
        </Wrapper>
      </Scrollable>
    </Grow>
  );
}

interface SubtitleProps {
  children: ReactNode;
}

function Subtitle({ children }: SubtitleProps) {
  return (
    <h2 className="text-3xl font-thin text-gray-27 dark:text-white">
      {children}
    </h2>
  );
}
