import { Arrow, Content, Portal, Root, Trigger } from "@radix-ui/react-tooltip";
import { ComponentProps, ReactNode, forwardRef } from "react";

interface Props extends Omit<ComponentProps<"button">, "content" | "ref"> {
  skip?: boolean;
  content?: ReactNode;
  side?: ComponentProps<typeof Content>["side"];
}

export const Tooltip = forwardRef<HTMLButtonElement, Props>(function Tooltip(
  { skip, content, side, children, ...otherProps },
  ref,
) {
  if (skip) return children;
  return (
    <Root>
      <Trigger ref={ref} asChild {...otherProps}>
        {children}
      </Trigger>
      <Portal>
        <Content
          className="max-w-[50vw] data-[state*=open]:animate-pop-in data-[state=closed]:animate-pop-out"
          sideOffset={5}
          side={side}
        >
          <div className="break-words rounded-sm bg-white px-4 py-2 text-sm text-gray-42 shadow-lg dark:bg-gray-42 dark:text-gray-94">
            {content}
          </div>
          <Arrow className="fill-white dark:fill-gray-42" />
        </Content>
      </Portal>
    </Root>
  );
});
