import { Title } from "@radix-ui/react-dialog";
import { SideDialogCloseButton } from "_shared/SideDialog/SideDialogCloseButton";
import { H2Text } from "_shared/text/H2Text";
import { ReactNode } from "react";

interface Props {
  children?: ReactNode;
}

export function SideDialogHeader(props: Props) {
  const { children } = props;
  return (
    <div className="mx-8 flex items-center gap-8 border-b border-gray-86 bg-white pb-5 pt-6 dark:border-gray-42 dark:bg-gray-31">
      <Title className="flex-1">
        <H2Text>{children}</H2Text>
      </Title>
      <SideDialogCloseButton />
    </div>
  );
}
