import * as Form from "@radix-ui/react-form";
import { LocalizedMessage } from "_shared/localization/LocalizedMessage";
import { SmallText } from "_shared/text/SmallText";

/**
 * Use within {@link Form.Field} to show an error message when a url input is invalid.
 */
export function InvalidUrlMessage() {
  return (
    <Form.ValidityState>
      {(validity) =>
        validity?.patternMismatch || validity?.typeMismatch ? (
          <SmallText color="warning">
            <LocalizedMessage id="input_error_url" />
          </SmallText>
        ) : null
      }
    </Form.ValidityState>
  );
}
