import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faGripDotsVertical,
} from "@fortawesome/sharp-light-svg-icons";
import { AnimatedMove } from "_shared/animation/AnimatedMove";
import { Grow } from "_shared/flex/Grow";
import { LocalizedMessage } from "_shared/localization/LocalizedMessage";
import { useLocalize } from "_shared/localization/useLocalize";
import { SmallText } from "_shared/text/SmallText";
import { swap } from "_shared/utils";
import classNames from "classnames";
import {
  ComponentProps,
  ReactNode,
  useCallback,
  useEffect,
  useId,
  useState,
} from "react";
import invariant from "tiny-invariant";

interface Props<V extends string | number> {
  options: Record<V, ReactNode>;
  values: ReadonlyArray<V>;
  onChange: (newValue: Array<V>) => void;
}

export function ListBuilder<V extends string | number>(props: Props<V>) {
  const { options, values, onChange } = props;
  const valuesSet = new Set(props.values);
  const localize = useLocalize();
  const [dragValue, setDragValue] = useState<V>();
  let dragFromIndex = values.findIndex((v) => v === dragValue);
  if (dragFromIndex === -1) dragFromIndex = Infinity;
  const [dropToIndex, setDropToIndex] = useState<number>();

  const reset = useCallback(() => {
    setDragValue(undefined);
    setDropToIndex(undefined);
  }, []);

  useEffect(() => {
    document.addEventListener("dragend", reset);
    return () => document.removeEventListener("dragend", reset);
  }, [reset]);

  return (
    <div className="grid grid-cols-2 gap-4" onDrop={reset}>
      <List
        label={<LocalizedMessage id="input_list_builder_available" />}
        hasBorder={dragValue !== undefined && valuesSet.has(dragValue)}
        isDropTarget={dragValue !== undefined}
        onDrop={() => {
          invariant(dragValue !== undefined);
          onChange(values.filter((v) => v !== dragValue));
        }}
      >
        {Object.entries(options)
          .filter(([value]) => !valuesSet.has(value))
          .map(([value, label]) => (
            <AnimatedMove key={value} duration={135}>
              <div
                role="listitem"
                className={classNames(
                  "flex cursor-move items-center rounded-sm border border-gray-80 bg-white px-1.5 transition-transform dark:border-gray-42 dark:bg-gray-24",
                  { "opacity-50": value === dragValue },
                )}
                draggable
                onDragStart={(e) => {
                  e.dataTransfer.effectAllowed = "move";
                  setDragValue(value);
                }}
              >
                <div className="flex grow items-center gap-3 self-stretch ps-3">
                  <GrabHandle />
                  <Grow>
                    <span className="py-2.5 leading-snug">{label}</span>
                  </Grow>
                  <SelectButton
                    aria-label={localize("button_select")}
                    onClick={() => onChange([...values, value])}
                  >
                    <FontAwesomeIcon icon={faChevronRight} />
                  </SelectButton>
                </div>
              </div>
            </AnimatedMove>
          ))}
      </List>
      <List
        label={<LocalizedMessage id="input_list_builder_selected" />}
        hasBorder={dragValue !== undefined || values.length === 0}
        isDropTarget={dragValue !== undefined}
        onDrop={() => {
          invariant(dragValue !== undefined);
          const valuesWithoutDragValue = values.filter((v) => v !== dragValue);
          const insertAt = dropToIndex ?? values.length;
          onChange([
            ...valuesWithoutDragValue.slice(0, insertAt),
            dragValue,
            ...valuesWithoutDragValue.slice(insertAt),
          ]);
        }}
      >
        {values.map((value, index) => {
          const label = options[value];
          return (
            <AnimatedMove key={value} duration={135}>
              <div
                role="listitem"
                className={classNames(
                  "flex cursor-move items-center gap-1 rounded-sm border border-gray-80 bg-white px-1.5 transition-transform dark:border-gray-42 dark:bg-gray-24",
                  dropToIndex === undefined ||
                    dragFromIndex === dropToIndex ||
                    dropToIndex >= values.length
                    ? {}
                    : {
                        "translate-y-1/3":
                          dragFromIndex > dropToIndex && index >= dropToIndex,
                        "-translate-y-1/3":
                          dragFromIndex < dropToIndex && index <= dropToIndex,
                      },
                  { "opacity-50": value === dragValue },
                )}
                draggable
                onDragStart={(e) => {
                  e.dataTransfer.effectAllowed = "move";
                  setDragValue(value);
                }}
                onDragOver={(e) => {
                  const rect = e.currentTarget.getBoundingClientRect();
                  const { top, height } = rect;
                  let continuous = index + (e.pageY - top) / height;
                  if (
                    continuous > dragFromIndex - 0.5 &&
                    continuous < dragFromIndex + 1.5
                  ) {
                    continuous = dragFromIndex;
                  } else if (continuous > dragFromIndex) {
                    continuous--;
                  }
                  setDropToIndex(Math.max(0, Math.round(continuous)));
                }}
              >
                <SelectButton
                  aria-label={localize("button_deselect")}
                  onClick={() => onChange(values.filter((v) => v !== value))}
                >
                  <FontAwesomeIcon icon={faChevronLeft} />
                </SelectButton>
                <Grow>
                  <span className="py-2.5 leading-snug">{label}</span>
                </Grow>
                <div className="flex flex-col items-center gap-px">
                  <button
                    type="button"
                    className="flex aspect-square w-6 origin-bottom items-end justify-center text-sm text-gray-42 hover:text-primary disabled:pointer-events-none disabled:opacity-60 dark:text-gray-80 dark:hover:text-primary"
                    aria-label={localize("input_list_builder_button_up")}
                    onClick={() => onChange(swap(values, index, index - 1))}
                    disabled={index === 0}
                  >
                    <FontAwesomeIcon icon={faChevronUp} />
                  </button>
                  <GrabHandle />
                  <button
                    type="button"
                    className="flex aspect-square w-6 origin-top items-start justify-center text-sm text-gray-42 hover:text-primary disabled:pointer-events-none disabled:opacity-60 dark:text-gray-80 dark:hover:text-primary"
                    aria-label={localize("input_list_builder_button_down")}
                    onClick={() => onChange(swap(values, index, index + 1))}
                    disabled={index === values.length - 1}
                  >
                    <FontAwesomeIcon icon={faChevronDown} />
                  </button>
                </div>
              </div>
            </AnimatedMove>
          );
        })}
      </List>
    </div>
  );
}

interface ListProps {
  hasBorder: boolean;
  isDropTarget: boolean;
  onDrop: () => void;
  label: ReactNode;
  children?: ReactNode;
}

function List(props: ListProps) {
  const { hasBorder, isDropTarget, label, ...otherProps } = props;
  const id = useId();
  return (
    <div className="flex flex-col gap-3">
      <span id={id}>
        <SmallText color="light">{label}</SmallText>
      </span>
      <div
        role="list"
        aria-labelledby={id}
        className={classNames(
          "flex grow flex-col gap-2 border border-dashed pb-6",
          hasBorder
            ? "border-gray-80 dark:border-gray-42"
            : "border-transparent",
        )}
        // Drop targets are enabled by cancelling `dragenter` and `dragover` events. See https://developer.mozilla.org/en-US/docs/Web/API/HTMLElement/drop_event
        onDragEnter={(e) => {
          if (isDropTarget) e.preventDefault();
        }}
        onDragOver={(e) => {
          if (isDropTarget) e.preventDefault();
        }}
        {...otherProps}
      />
    </div>
  );
}

function GrabHandle() {
  return (
    <span className="text-sm text-gray-42 dark:text-gray-80">
      <FontAwesomeIcon icon={faGripDotsVertical} />
    </span>
  );
}

function SelectButton(
  props: Omit<ComponentProps<"button">, "className" | "type">,
) {
  return (
    <button
      type="button"
      className="flex w-6 items-center justify-center self-stretch text-sm text-gray-42 hover:text-primary dark:text-gray-80 dark:hover:text-primary"
      {...props}
    />
  );
}
