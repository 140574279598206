import * as Form from "@radix-ui/react-form";
import { Field } from "_shared/field/Field";
import { Select } from "_shared/input/Select/Select";
import { TextInput } from "_shared/input/TextInput";
import { LocalizedMessage } from "_shared/localization/LocalizedMessage";
import { LabelText } from "_shared/text/LabelText";
import { useState } from "react";
import { EDUCATION_MILESTONES, EducationMilestone } from "user/User.types";

export function UserSettingsEducation() {
  const [highestEducationAchieved, setHighestEducationAchieved] =
    useState<EducationMilestone>();
  const [activitiesAndSocieties, setActivitiesAndSocieties] = useState("");

  return (
    <>
      <Field name="highestEducationAchieved">
        <LabelText>
          <LocalizedMessage id="user_settings_education_level" />
        </LabelText>
        <Form.Control asChild>
          <Select
            value={highestEducationAchieved}
            onChange={setHighestEducationAchieved}
            options={EDUCATION_MILESTONES.map((value) => ({
              value,
              label: <LocalizedMessage id={`user_education_option_${value}`} />,
            }))}
          />
        </Form.Control>
      </Field>
      <Field name="activitiesAndSocieties">
        <LabelText>
          <LocalizedMessage id="user_settings_activities_societies" />
        </LabelText>
        <Form.Control asChild>
          <TextInput
            type="text"
            value={activitiesAndSocieties}
            onChange={setActivitiesAndSocieties}
          />
        </Form.Control>
      </Field>
    </>
  );
}
