import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUp } from "@fortawesome/sharp-light-svg-icons";
import * as Dialog from "@radix-ui/react-dialog";
import * as Form from "@radix-ui/react-form";
import { Scrollable } from "_shared/Scrollable/Scrollable";
import { SideDialog } from "_shared/SideDialog/SideDialog";
import { SideDialogFooter } from "_shared/SideDialog/SideDialogFooter";
import { SideDialogHeader } from "_shared/SideDialog/SideDialogHeader";
import { useUser } from "_shared/auth/AuthContext";
import { PrimaryButton } from "_shared/button/PrimaryButton";
import { SecondaryButton } from "_shared/button/SecondaryButton";
import { Field } from "_shared/field/Field";
import { InvalidEmailMessage } from "_shared/field/InvalidEmailMessage";
import { RequiredFormMessage } from "_shared/field/RequiredFormMessage";
import { Grow } from "_shared/flex/Grow";
import { FileInput } from "_shared/input/FileInput";
import { Select } from "_shared/input/Select/Select";
import { TextArea } from "_shared/input/TextArea";
import { TextInput } from "_shared/input/TextInput";
import { LocalizedMessage } from "_shared/localization/LocalizedMessage";
import { LabelText } from "_shared/text/LabelText";
import { SmallText } from "_shared/text/SmallText";
import { Status } from "_shared/utils";
import { Project } from "founder/portfolio/Portfolio.types";
import { useState } from "react";

interface HubSpotFormData {
  firstname: string;
  lastname: string;
  email: string;
  phone: string;
  linkedin_profile?: string;
  "0-2/startup_name"?: string;
  "0-2/headquaters"?: string;
  "0-2/domain"?: string;
  investment_series?: string;
  pitch_deck____any_other_bits_?: FileList;
  "TICKET.subject"?: string;
  "TICKET.content"?: string;
}

export function DeckSubmission(props: { project: Project | undefined }) {
  const user = useUser();
  const [status, setStatus] = useState<Status>("idle");
  const [form, setForm] = useState<HubSpotFormData>({
    firstname: user.name.split(" ")[0],
    lastname: user.name.split(" ").slice(1).join(" "),
    email: user.email,
    phone: user.mobilePhone,
    "0-2/startup_name": props.project?.projectCompany_name,
    "0-2/domain": props.project?.Website,
    "TICKET.subject": props.project?.projectCompany_name,
    "TICKET.content": props.project?.Elavator,
  });

  return (
    <SideDialog
      isModal={true}
      trigger={
        <SecondaryButton>
          <FontAwesomeIcon icon={faUp} />
          <LocalizedMessage id="deck_submit" />
        </SecondaryButton>
      }
    >
      <Form.Root
        className="flex min-h-0 w-96 flex-col"
        onSubmit={async (e) => {
          e.preventDefault();
          const formData = new FormData(e.currentTarget);
          formData.set(
            "hs_context",
            JSON.stringify({
              source: "forms-embed-1.4595",
            }),
          );
          formData.set("investor_founder", "Founder");
          try {
            const response = await fetch(
              "https://forms.hsforms.com/submissions/v3/public/submit/formsnext/multipart/19491082/7275ac51-22bc-4d9e-b0fb-85c008331d0f",
              {
                method: "POST",
                body: formData,
              },
            );
            if (response.status !== 200) throw new Error();
            setStatus("success");
          } catch (_) {
            setStatus("error");
          }
        }}
      >
        <SideDialogHeader>
          <LocalizedMessage id="deck_submit" />
        </SideDialogHeader>
        <Grow>
          <Scrollable>
            <div className="flex flex-col items-start gap-6 px-8 py-6">
              <Field name="firstname">
                <Form.Label>
                  <LabelText>
                    <LocalizedMessage id="deck_label_firstname" />
                  </LabelText>
                </Form.Label>
                <Form.Control asChild>
                  <TextInput
                    value={form?.firstname ?? ""}
                    onChange={(value) => setForm({ ...form, firstname: value })}
                    required
                  />
                </Form.Control>
                <RequiredFormMessage />
              </Field>
              <Field name="lastname">
                <Form.Label>
                  <LabelText>
                    <LocalizedMessage id="deck_label_lastname" />
                  </LabelText>
                </Form.Label>
                <Form.Control asChild>
                  <TextInput
                    value={form?.lastname ?? ""}
                    onChange={(value) => setForm({ ...form, lastname: value })}
                    required
                  />
                </Form.Control>
                <RequiredFormMessage />
              </Field>
              <Field name="email">
                <Form.Label>
                  <LabelText>
                    <LocalizedMessage id="deck_label_email" />
                  </LabelText>
                </Form.Label>
                <Form.Control asChild>
                  <TextInput
                    type="email"
                    value={form?.email ?? ""}
                    onChange={(value) => setForm({ ...form, email: value })}
                    required
                  />
                </Form.Control>
                <RequiredFormMessage />
                <InvalidEmailMessage />
              </Field>
              <Field name="phone">
                <LabelText>
                  <LocalizedMessage id="deck_label_phone" />
                </LabelText>
                <Form.Control asChild>
                  <TextInput
                    type="tel"
                    value={form?.phone ?? ""}
                    onChange={(value) => setForm({ ...form, phone: value })}
                    required
                  />
                </Form.Control>
              </Field>
              <Field name="linkedin_profile">
                <Form.Label>
                  <LabelText>
                    <LocalizedMessage id="deck_label_linkedin_profile" />
                  </LabelText>
                </Form.Label>
                <Form.Control asChild>
                  <TextInput
                    value={form?.linkedin_profile ?? ""}
                    onChange={(value) =>
                      setForm({ ...form, linkedin_profile: value })
                    }
                  />
                </Form.Control>
              </Field>
              <Field name="0-2/startup_name">
                <Form.Label>
                  <LabelText>
                    <LocalizedMessage id="deck_label_startup_name" />
                  </LabelText>
                </Form.Label>
                <Form.Control asChild>
                  <TextInput
                    value={form?.["0-2/startup_name"] ?? ""}
                    onChange={(value) =>
                      setForm({
                        ...form,
                        "0-2/startup_name": value,
                      })
                    }
                  />
                </Form.Control>
              </Field>
              <Field name="0-2/headquaters">
                <Form.Label>
                  <LabelText>
                    <LocalizedMessage id="deck_label_headquarters" />
                  </LabelText>
                </Form.Label>
                <Form.Control asChild>
                  <TextInput
                    value={form?.["0-2/headquaters"] ?? ""}
                    onChange={(value) =>
                      setForm({ ...form, "0-2/headquaters": value })
                    }
                    required
                  />
                </Form.Control>
                <RequiredFormMessage />
              </Field>
              <Field name="0-2/domain">
                <Form.Label>
                  <LabelText>
                    <LocalizedMessage id="deck_label_domain" />
                  </LabelText>
                </Form.Label>
                <Form.Control asChild>
                  <TextInput
                    value={form?.["0-2/domain"] ?? ""}
                    onChange={(value) =>
                      setForm({ ...form, "0-2/domain": value })
                    }
                  />
                </Form.Control>
              </Field>
              <Field name="investment_series">
                <Form.Label>
                  <LabelText>
                    <LocalizedMessage id="deck_label_investment_series" />
                  </LabelText>
                </Form.Label>
                <Form.Control asChild>
                  <Select
                    required
                    value={form.investment_series}
                    onChange={(value) =>
                      setForm({ ...form, investment_series: value })
                    }
                    options={[
                      { value: "Pre Seed", label: "Pre-Seed" },
                      { value: "Seed", label: "Seed" },
                      { value: "Angel", label: "Angel" },
                      { value: "Serie A", label: "Series A" },
                      { value: "Serie B", label: "Series B" },
                      { value: "Serie C", label: "Series C" },
                      { value: "Series D", label: "Series D" },
                      { value: "Series E", label: "Series E" },
                      { value: "Series F", label: "Series F" },
                      { value: "Series G", label: "Series G" },
                      { value: "Later", label: "Later Round" },
                    ]}
                  />
                </Form.Control>
                <RequiredFormMessage />
              </Field>
              <Field name="pitch_deck____any_other_bits_">
                <Form.Label>
                  <LabelText>
                    <LocalizedMessage id="deck_label_pitch_deck____any_other_bits_" />
                  </LabelText>
                </Form.Label>
                <Form.Control asChild>
                  <FileInput
                    accept=".pdf, .ppt, .pptx"
                    onChange={(e) => {
                      setForm({
                        ...form,
                        pitch_deck____any_other_bits_:
                          e.target.files !== null ? e.target.files : undefined,
                      });
                    }}
                    required
                  />
                </Form.Control>
                <RequiredFormMessage />
              </Field>
              <Field name="TICKET.subject">
                <Form.Label>
                  <LabelText>
                    <LocalizedMessage id="deck_label_subject" />
                  </LabelText>
                </Form.Label>
                <Form.Control asChild>
                  <TextInput
                    value={form?.["TICKET.subject"] ?? ""}
                    onChange={(value) =>
                      setForm({ ...form, "TICKET.subject": value })
                    }
                    required
                  />
                </Form.Control>
                <RequiredFormMessage />
              </Field>
              <Field name="TICKET.content">
                <Form.Label>
                  <LabelText>
                    <LocalizedMessage id="deck_label_content" />
                  </LabelText>
                </Form.Label>
                <Form.Control asChild>
                  <TextArea
                    value={form?.["TICKET.content"] ?? ""}
                    onChange={(e) =>
                      setForm({
                        ...form,
                        "TICKET.content": e.target.value,
                      })
                    }
                    required
                  />
                </Form.Control>
                <RequiredFormMessage />
              </Field>
            </div>
          </Scrollable>
        </Grow>
        <SideDialogFooter>
          <div className="flex flex-1 gap-4">
            <Form.Submit asChild>
              <PrimaryButton status={status}>
                <LocalizedMessage id="button_submit" />
              </PrimaryButton>
            </Form.Submit>
            <Dialog.Close asChild>
              <SecondaryButton>
                <LocalizedMessage id="button_cancel" />
              </SecondaryButton>
            </Dialog.Close>
          </div>
          {status === "error" ? (
            <SmallText color="warning">
              <LocalizedMessage id="error" />
            </SmallText>
          ) : null}
        </SideDialogFooter>
      </Form.Root>
    </SideDialog>
  );
}
