import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/sharp-light-svg-icons";
import { Close } from "@radix-ui/react-dialog";
import { Tooltip } from "_shared/Tooltip/Tooltip";
import { IconButton } from "_shared/button/IconButton";
import { LocalizedMessage } from "_shared/localization/LocalizedMessage";
import { ReactNode } from "react";

interface Props {
  children?: ReactNode;
}

export function SideDialogCloseButton(props: Props) {
  const { children } = props;
  return (
    <Close asChild>
      {children ?? (
        <Tooltip content={<LocalizedMessage id="button_close" />}>
          <IconButton>
            <FontAwesomeIcon icon={faTimes} fixedWidth />
          </IconButton>
        </Tooltip>
      )}
    </Close>
  );
}
