import darkLogo from "_assets/images/revveon-logo-dark.svg";
import lightLogo from "_assets/images/revveon-logo-light.svg";
import { Scrollable } from "_shared/Scrollable/Scrollable";
import { Wrapper } from "_shared/Wrapper";
import { Grow } from "_shared/flex/Grow";
import { useLocalize } from "_shared/localization/useLocalize";
import { useTheme } from "_shared/theme/theme";
import { Link } from "react-router-dom";

export function Terms() {
  const localize = useLocalize();
  const theme = useTheme();
  return (
    <Grow>
      <Scrollable>
        <Wrapper>
          <div className="flex flex-col gap-12">
            <Link to="/" className="self-start">
              <img
                src={theme === "light" ? lightLogo : darkLogo}
                alt={localize("revveon")}
              />
            </Link>
            <article className="prose self-center dark:prose-invert prose-h1:text-4xl prose-h1:font-thin prose-h2:text-sm prose-h2:font-bold prose-h2:uppercase prose-h2:tracking-wide prose-h3:text-base prose-h3:font-normal prose-h3:underline prose-a:font-normal">
              <h1>Revveon Terms & Conditions of Use (January 2024)</h1>
              <p>
                <br />
                The Revveon Website Terms of Use (&ldquo;Terms of Use&rdquo;)
                are defining the conditions under which you may access and use
                the Revveon Website and all the functionalities provided
                (hereinafter designated together the
                &ldquo;Service&rdquo;).&nbsp;
                <br />
                <br />
                By accessing or using the Service or by logging into with your
                allocated User ID, you agree to be bound by these Terms of Use.
                The User undertakes to have legal ability and means required to
                access and use the Service and acknowledges to have read,
                understood, and accepted without limitation or reservation the
                Terms of Use, which apply in addition to the Privacy Policy.
                <br />
                <br />
                These Terms of Service are permanently available at:&nbsp;
                <a href="/terms" className="underline">
                  app.revveon.com/terms
                </a>
                .
                <br />
                <br />
                Please review these Terms of Service carefully prior to using
                the Service. If you do not agree with these Terms of Use, please
                do not use the Service.&nbsp;
              </p>

              <h2>Legal notice</h2>
              <p>
                The Revveon Platform Service is provided by Kavedon Kapital
                Limited, &ldquo;Kavedon&rdquo; &ndash; a Guernsey incorporated
                company.
                <br />
                <br />
                Registered office: PO Box 650, 1<sup>st</sup>
                Floor Royal Chambers, St Julians Avenue, St Peter Port,
                Guernsey, GY1 3JX
                <br />
                <br />
                Company Number: 70752
                <br />
                <br />
                E-mail: info@revveon.com
              </p>
              <h2>Definitions</h2>
              <p>
                Revveon Website: the online platform is named
                &ldquo;Revveon&rdquo; and is designed and built and provided by
                &ldquo;Kavedon&rdquo; allowing User to benefit from the Services
                of the Platform.&nbsp;
                <br />
                <br />
                Data Protection Law: means, the Regulation (EU) 2016/679 of the
                European Parliament and of the Council of 27 April 2016 on the
                protection of natural persons with regard to the processing of
                personal data and on the free movement of such data (General
                Data Protection Regulation) with all its later amendments and
                changes.&nbsp; UK GDPR (General Data Protection Regulation)
                means the EU GDPR as transposed into UK law (including by the
                Data Protection Act 2018 and the Data Protection, Privacy and
                Electronic Communications (Amendments etc) (EU Exit) Regulations
                2019) and all other UK laws regulating the processing of
                Personal Data, as such laws may be updated, amended and
                superseded from time to time
                <br />
                <br />
                User Account: means a dedicated online account opened by any
                Founder, Start-up, Entity or Individual and providing an access
                to the Service.
                <br />
                <br />
                Identifiers: means login and password the User used to create
                the User Account.
                <br />
                <br />
                Party or Parties: means the User and Revveon designated either
                individually or collectively.
                <br />
                <br />
                Service: means the Revveon Website and all the functionalities
                provided thereto and described in the Terms and Conditions.
                <br />
                <br />
                Service Content: any data, text, files, information, usernames,
                images, graphics, photos, profiles, audio, and video clips,
                sounds, works of authorship, applications, links, and other
                content or materials made available during the Service.
                <br />
                <br />
                User or Users:&nbsp;means any Founder, Entrepreneur, Start-up,
                Entity or Individual authorized by Revveon to access and use of
                the Service after creation of a User Account.
                <br />
                <br />
                User Content: any content created, posted, or shared by the
                User, on or through the Service including any information, data,
                photos, and other content relating to the User.
              </p>
              <h2>Term</h2>
              <p>
                The Terms of Service will come into effect on first access to
                the Service for the entire duration of use.
              </p>
              <h2>Description of the Service</h2>
              <p>
                Registering and completing the Founder survey when joining up on
                Revveon enables Users to access the Services supplied to connect
                with other parties within the venture asset class, Founders,
                Start-ups, Enterprises, and Investors and have access to
                supportive applications and build communities.
                <br />
                <br />
                The Service comprises an on-growing suite of application
                services provided by Revveon, to enable the User to gain
                valuable support in growing its ideas, products, and company
                whilst at the same time providing opportunities to access other
                parties such as Corporate Enterprises for such matters as
                product validation and product
              </p>
              <h2>Access to the Service</h2>
              <p>
                Access to the Service shall be subject to the creation of a User
                Account.
                <br />
                <br />
                The Service is available on the website revveon.com and is
                available in mobile form.
                <br />
                <h3>Requirements for the access to the Service</h3>
                The User must ensure to have (the &ldquo;User&rsquo;s
                System&rdquo;):
                <br />
                <br />
                <ul className="list-disc">
                  <li>
                    The ability and means required to access the Services and to
                    use it;
                  </li>
                  <li>
                    The appropriate hardware (computer, smartphone...) and
                    operating software and shall carry out the responsibility of
                    the maintenance and the security;&nbsp;
                  </li>
                  <li>
                    An Internet access from any access provider of the
                    User&rsquo;s choice;
                  </li>
                  <li>An adapted configuration of the browser.</li>
                </ul>
                <br />
                Users shall bear all the costs for accessing the Service.
                <br />
                <h3>Creation of the User Account</h3>
                This shall require the entering of personal identification data
                through the online registration form.
                <br />
                <br />
                The User shall enter user identification data such as name,
                surname, allocated user ID and email and is invited to choose
                its Identifiers.
                <br />
                <br />
                The User agrees to provide only accurate and complete
                information when creating or updating his/her account
                information.
                <br />
                <h3>Access modalities</h3>
                In order to benefit from the Services, the User must log-in and
                insert the Identifiers with the SSO account details the User
                used to create the User Account. Identifiers are personal and
                confidential. Users are responsible for keeping it secret and
                not disclose it to any third party in any form and for any
                reason whatsoever.
                <br />
                <br />
                The User&rsquo;s login can only be changed at the request of the
                User or at the initiative of Revveon. The User&rsquo;s password
                is modifiable by the User through the User&rsquo;s Account. The
                User is solely and entirely responsible for the use of the
                User&rsquo;s Identifiers and undertakes to do everything
                possible to keep it secret and not to disclose them to anyone,
                in any form whatsoever and for any reason whatsoever. If the
                User forgets her/his password, the User can generate a new one
                by clicking on the link&nbsp;
                <a href="/user/reset-password" className="underline">
                  &ldquo;Reset my password&rdquo;
                </a>
                .
                <br />
                <br />
                The User is solely responsible for the activity that occurs on
                its User Account and will be responsible to keep its Identifiers
                secure and for the use of his Identifiers by third-parties or
                for any actions or declarations made through his User Account,
                whether fraudulent or not, and guarantees Revveon against any
                request in this respect.&nbsp;
                <br />
                <br />
                If the User has reason to believe that a person is fraudulently
                using identification elements or his User Account, they must
                immediately inform Revveon by e-mail at the following
                address,&nbsp;
                <a href="mailto: info@revveon.com" className="underline">
                  info@revveon.com
                </a>
                .
                <br />
                <br />
                The User is solely responsible for the improper access, damage,
                disruption, loss or theft and adverse consequences caused by its
                breach of the confidentiality of the Identifiers. In case of
                loss, misappropriation or unauthorized use of its password, the
                User shall immediately notify Revveon.
                <br />
                <br />
                Users may not use another&rsquo;s account without permission. A
                User Account may not be shared, transferred, or sold to other
                parties, nor are they to be used by or made available to any
                person or entity except the registered user.
                <br />
                <h3>Founder Survey as part of the registration process</h3>
                As part of the registration process, User must complete a
                Founder Survey. Revveon requests this for access in order to
                build an understanding of the Founder and Start-up community, so
                that it can better serve its community through the services it
                provides. In order to enhance the services, Revveon will
                request, from time to time, updates to its Founder Surveys.
              </p>
              <h2>Cost of the Service</h2>
              <p>
                The Service is generally available for free of charge for the
                User, with the exception of paid members when access is required
                for Users numbering more than 25 Users within one company entity
                where a minimal cost will be charged, and notification will be
                provided. All costs relating to any connection costs, whose
                price depends on your electronic communication service provider
                are at the expense of the User.
              </p>
              <h2>Intellectual property</h2>
              <p>
                <h3>Company&rsquo;s ownership&nbsp;</h3>
                The Service and the Service Content belong and shall remain the
                property of Kavedon, and is protected by copyright, patent when
                applicable, trade secret and other intellectual property laws.
                <br />
                <br />
                The User shall not acquire any of such rights title and interest
                (including, without limitation, all intellectual property rights
                such as copies, modifications, extensions, and derivative works
                thereof) save as otherwise expressly set out and the User shall
                take all necessary measures to this effect. All rights not
                expressly granted to Users are reserved and retained.
                <br />
                <br />
                Any reproduction, representation, distribution, modification,
                adaptation, or translation of these various Service Content, in
                whole or in part, in any form and current or future medium is
                strictly prohibited, except within the limit of the Terms of
                Service or with prior express authorization of Revveon. Any use
                of these Service Content not expressly authorized by Revveon
                shall constitute a violation of the Terms of Use and a violation
                of intellectual property rights.
                <br />
                <br />
                Except as specifically permitted by these Terms of Use, User may
                not use or register any name, logo, brand, or insignia of
                Revveon or Kavedon or subdivisions for any purpose except with
                Revveons&rsquo; or Kavedons&rsquo; prior written approval and in
                accordance with any restrictions required by Revveon. These are
                the property of Revveon or are subject to rights to use,
                duplicate or represent/communicate to the public that have been
                granted to such.
                <br />
                <br />
                Revveon is the producer of databases on the Website and Revveon
                is the owner of any databases made available. The User shall not
                extract or reuse a substantial qualitative or quantitative
                portion of the databases, including for private purpose.
                <br />
                <h3>User&rsquo;s right of use</h3>
                Revveon grants the User a limited, non-exclusive,
                non-transferable, and revocable license to use the Service for
                User&rsquo;s personal, non-commercial purposes. The User may
                only use the Website on devices that the User owns or controls
                and as permitted. User may use the Service and the Service
                Content only in the manner and for the purposes specified in
                these Terms of Use. Except as specifically permitted by these
                Terms of Use, User may not modify, transmit, produce,
                distribute, display, sell, license, broadcast, or otherwise make
                available in any form or by any means any part of the Service or
                the Service Content without the express written permission of
                Revveon.
                <br />
                <br />
                In connection with the use of the Services and Service Content
                or User&rsquo;s Content, the User is not authorized to, or
                cannot allow a third party to:
                <br />
                <br />
                <ul className="list-disc">
                  <li>
                    violate any applicable laws, rules, or regulations, notably
                    cause damage or injury to any person or property, infringes
                    any rights of third parties including but not limited to any
                    intellectual property rights and privacy rights or perform
                    any other illegal, harmful, or fraudulent activities;
                  </li>
                  <li>
                    probe, scan, or test the vulnerability of any system or
                    network;
                  </li>
                  <li>
                    remove, circumvent, disable, damage, or otherwise interfere
                    with any security measures of any network, computer or
                    communications system, software application, or network or
                    computing device;
                  </li>
                  <li>
                    attempts to connect without permission and/or damage, in any
                    way whatsoever, any users, hosts or networks;
                  </li>
                  <li>
                    disturb, hamper, interfere or interrupt the access to or
                    operation of the Service and Service Content including by
                    transmitting any worms, viruses, spyware, malware, or any
                    other code of a destructive or disruptive nature.
                  </li>
                </ul>
              </p>
              <h2>User&rsquo;s undertakings</h2>
              <p>
                The User represents and warrants that they will retain ownership
                of all of their intellectual property rights in the User Content
                or that this User Content is subject to a license granted to the
                User by a third party holding these rights, under conditions
                allowing the User to freely use and/or transfer these elements
                for the purpose of using the Service, under the conditions
                described in the Terms and Conditions. Revveon and Kavedon does
                not claim ownership of the User Content.
                <br />
                <br />
                The User holds Revveon and Kavedon harmless from all claims or
                actions brought by third parties on the grounds of an
                infringement of their rights, including, without limitation,
                publicity rights, copyrights, trademark and/or other
                intellectual property rights or privacy rights in the User
                Content.
                <br />
                <br />
                By using the Service, the User represents and warrants that she
                or he has the legal right and capacity to enter into these Terms
                of Use in the User&rsquo;s jurisdiction.
                <br />
                <br />
                User warrants to be at least 18 years of age or older or an
                emancipated minor, or possess legal parental or guardian
                consent, and is fully competent, to use the Service and to enter
                into and comply with these Terms of Use.&nbsp;If the User is
                under the age of 18 years, the User must obtain verifiable
                consent from a parent or legal guardian. The parent or legal
                guardian shall be able to provide consent to her or his
                child&rsquo;s use of the Service and agree to be bound by these
                Terms of Use in respect to her or his child&rsquo;s use of the
                Service.
                <br />
                <br />
                Persons aged 13 years old or less are not authorized to access
                the Service.
              </p>
              <h2>User&rsquo;s rules of conduct</h2>
              <p>
                Generally, the User is committed to use the Services in
                accordance with Community Standards made available to the User
                in the Policy section of the website.
                <br />
                <br />
                User may not post content which includes, but is not limited to:
                (i) sexually explicit materials; (ii) obscene, misleading,
                libelous, slanderous, violent, hateful and/or unlawful content
                or profanity; (iii) content that infringes upon the rights of
                any third party, including copyright, trademark, privacy,
                publicity or other personal or proprietary right, or that is
                deceptive or fraudulent; (iv) content that promotes the use or
                sale of illegal or regulated substances, tobacco products,
                ammunition and/or firearms; and (v) gambling, including without
                limitation, any online casino, sports books, bingo or poker
                (&ldquo;Objectionable Content&rdquo;) via the Service.
                <br />
                <br />
                User may not defame, stalk, bully, abuse, harass, threaten,
                impersonate, or intimidate people or entities and must not post
                private or confidential information via the Service, including,
                without limitation, the User or any other person&apos;s credit
                card information, social security or alternate national identity
                numbers, non-public phone numbers or non-public email addresses.
                <br />
                <br />
                User must not create accounts with the Service through
                unauthorized means, including but not limited to, by using an
                automated device, script, bot, spider, crawler, or scraper.
                <br />
                <br />
                User must not attempt to restrict another User from using or
                enjoying the Service and must not encourage or facilitate
                violations of these Terms of Service.
                <br />
                <br />
                User may only make non-commercial uses of the Service and
                Service Content. Use of Service or the Service Content for any
                commercial, public, or political purpose is strictly
                prohibited.&nbsp;Prohibited activities include, but are not
                limited to: (1) activities that are illegal or fraudulent; (2)
                use that inaccurately implies endorsement, approval, or
                sponsorship by Revveon or Kavedon (or any individual officer or
                employee of Revveon or Kavedon); (3) use that can be confused
                with official communications of Revveon or Kavedon or its
                officers or employees; and (4) print or electronic mass mailings
                (sometimes known as &quot;spamming&quot;), solicitations for
                commercial services.
              </p>
              <h2>Reporting and moderation</h2>
              <p>
                In order to ensure the Service provides the best experience
                possible for everyone, if a User sees any inappropriate content,
                she or he must report this at&nbsp;
                <a href="mailto: info@revveon.com" className="underline">
                  info@revveon.com
                </a>
                <br />
                <br />
                Revveon has no general obligation to monitor User Content nor to
                investigate whether facts or circumstances are revealing illicit
                activities. Revveon has set up a reporting tool allowing any
                User to notify illegal, abusive, and harmful content.
                <br />
                <br />
                The knowledge of such content is deemed effective when the
                hosting provider is notified of following:
                <br />
                <br />
                <ul className="list-disc">
                  <li>The notification date;</li>
                  <li>The notifying User identity;</li>
                  <li>
                    The description of the facts in contention (possibly with
                    screen shots of the content);
                  </li>
                  <li>
                    As well as the reasons for which the content must be
                    removed.
                  </li>
                </ul>
                <br />
                If a User Content is reported, Revveon reserves the right to
                withdraw the reported content and/or suspend or terminate the
                User Account of the User having originally published the
                reported content.&nbsp;
              </p>
              <h2>Termination, suspension, or revocation of access</h2>
              <p>
                <h3>Deletion of the User Account by the User</h3>
                At any time, the User may delete its User Account by contacting
                Revveon at info@revveon.com.
                <br />
                <br />
                Deletion is effective immediately and all the data collected
                upon the subscription as well as the content published by the
                User on the Website is deleted after a period of 14 days.
                <br />
                <h3>
                  Suspension or termination of the User Account by Revveon in
                  case of breach of the Terms of Use
                </h3>
                Revveon retains the right to suspend User&rsquo;s access to the
                Service to make all investigations necessary following a report
                made or in case of presumed breach of Terms of Use which may be
                remediated. The User whose User Account is suspended will be
                informed by Revveon of such a decision by email. The User has
                the possibility send an email to Revveon to obtain additional
                information on the reasons for the suspension as well as to
                provide explanations and/or any element in order to demonstrate
                that the breach has been remediated. If the violation of Terms
                of Use is not remedied within one (1) month from suspension
                notification, Revveon reserves the right to terminate the
                applicable User&rsquo;s Account and right to use the Service, in
                addition to any other rights or remedies available.&nbsp;
                <br />
                <h3>Deletion of inactive User Account</h3>
                Revveon will delete inactive accounts after a continuous period
                of three (3) years the User has not used the Service. The User
                will be informed by email of the deletion of his or her account
                subject to prior notice of two (2) weeks allowing the User to
                object to such deletion.
              </p>
              <h2>Privacy; Confidentiality</h2>
              <p>
                By using the Service, the User&rsquo;s personal data, in the
                meaning of Data Protection Law, may be collected and processed
                by Revveon, in compliance within the Revveon Privacy Policy and
                the Cookie Policy, (collectively, &ldquo;Privacy
                Policies&rdquo;).
                <br />
                <br />
                Revveon will treat User Content as confidential information in
                with these Terms of Use and the Privacy Policies. &nbsp;
              </p>
              <h2>Limitation of Warranty and Liability; Indemnification</h2>
              <p>
                Revveon is not responsible for the User&rsquo;s device
                compatibility nor if the User is unable to connect due to any
                technical problem related to the Internet network or a failure
                in the User&apos;s computer facilities and equipment.
                <br />
                <br />
                Revveon shall not be responsible for User&rsquo;s system, nor
                any problems arising from or related to User&rsquo;s System or
                caused by the Internet. User is responsible for the security of
                the User System and the security of its access to and connection
                with the Services.
                <br />
                <br />
                Insofar as it has not been reported, any document, information,
                data, or elements available via the Service, including User
                Content (&ldquo;Information&rdquo;) is provided without any
                guarantees, notably that the Information is comprehensive or
                accurate.
                <br />
                <br />
                The User is solely responsible for the use of the Information
                and bears all the risks arising from your reliance on this
                Information. The Information is provided on the condition that
                the User can determine its interest for a specific purpose
                before use. Under no circumstances does Revveon accepts
                liability for injury arising from reliance on the said
                Information, its use or use of a product to which it refers.
                <br />
                <br />
                The User is solely responsible for all User Content posted and
                assumes all risks relating to its use by others and
                others&rsquo; reliance on its accuracy. The User acknowledges
                that, following the publication of the any content, its
                information will become accessible to all the Users on the
                Service and that as such, same information will be published,
                modified, translated, reproduced in any form and accessible,
                saved and reproduced by other Users and Revveon. Revveon will
                have no responsibility or liability in connection with the User
                Content.
                <br />
                <br />
                Revveon expressly excludes any liability for events of any kind
                that may occur during any meeting organized between Users within
                the use of the Services.
              </p>
              <h2>Hypertext links</h2>
              <p>
                The Service may provide links to websites operated by third
                parties or other internet sources/networks, as well as
                data/information coming from third-party servers. Revveon does
                not endorse or accept responsibility for the content or the use
                of third-party websites and cannot be held liable for any damage
                following or in relation with use or the fact of having trusted
                the content/data/results available on such third-party servers,
                external sources, or internet sites.&nbsp;
                <br />
                <br />
                If a User uses third-party sites or content from third-party
                servers, User does so solely at User&rsquo;s own risk. Links do
                not imply that the Company is affiliated or associated with any
                linked site.&nbsp;
              </p>
              <h2>Contact</h2>
              <p>
                For any questions regarding the Terms of Use, especially use of
                the Service or any claim, please email us at&nbsp;
                <a href="mailto: info@revveon.com" className="underline">
                  info@revveon.com
                </a>
                <br />
                <br />
                The User can use these contact details for any technical support
                request.
              </p>
              <h2>Miscellaneous</h2>
              <p>
                <h3>Governing Law and jurisdiction.</h3>
                These Terms of Use will be governed by the laws in England and
                Wales without regard to its conflicts of law principles.
                <br />
                <br />
                The User is informed, as consumer, of the possibility to use a
                conventional mediation procedure or any other alternative
                dispute resolution method. In particular, the User may refer the
                matter to the Consumer Ombudsman to be directed to with an
                inquiry via&nbsp;
                <a href="mailto: info@revveon.com" className="underline">
                  info@revveon.com
                </a>
                .
                <br />
                <br />
                The User can submit a dispute to the Online Dispute Resolution
                platform proposed by the European Commission, which may be
                accessed at the following address:&nbsp;
                <a
                  href="http://ec.europa.eu/consumers/odr/"
                  className="underline"
                >
                  http://ec.europa.eu/consumers/odr/
                </a>
                .
                <br />
                <h3>Change of Terms of Use.&nbsp;</h3>
                These Terms of Use are subject to change from time to time. Any
                changes will be posted to this page. Use of this Service or any
                Service Content after entering into force the new version of the
                Terms of Use will constitute the agreement to the modified Terms
                of Use. If the User disagrees with such modification of the
                Terms of Use, the User may terminate the Service and delete its
                User Account.
                <br />
                <h3>Change of the Service.</h3>
                Revveon may further develop or modify the Service or the Service
                Content only to improve its quality and add additional
                applications and services. The User will be informed of such
                development/modification as soon as it is implemented.
                <br />
                <h3>Severability; Entire Agreement.</h3>
                Except in the case of invalidity of an essential clause, if any
                provision of these Terms of Use is held to be invalid or
                unenforceable, that provision, to the extent unenforceable,
                shall be struck, and shall not affect the validity or
                enforceability of the remaining provisions. These Terms of Use,
                including the Privacy Policies, set forth the entire
                understanding and agreement between the User and Kavedon with
                respect to the subject matter hereof.
                <br />
                <h3>Convention of proof.</h3>
                Electronic documents exchanged by electronic means between the
                User and Revveon shall be regarded as writings having the same
                probative force as writings on paper in the meaning of
                applicable law. They may constitute faithful and durable copies
                in accordance with applicable law, so that the User is invited
                to keep them.
                <br />
                <h3>Force majeure.</h3>
                In no case Revveon may be held liable for any breach of its
                contractual obligations resulting from a force majeure event as
                defined in applicable law and related jurisprudence.
              </p>
              <br />
              <br />
              <p>Version Dated: &lt;15/01/24&gt;</p>
            </article>
          </div>
        </Wrapper>
      </Scrollable>
    </Grow>
  );
}
