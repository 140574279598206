import { Country } from "_options/countries";
import { Gender } from "_options/genders";

export const RELATIONSHIPS = ["single", "married", "other"] as const;
export type Relationship = (typeof RELATIONSHIPS)[number];

export const EDUCATION_MILESTONES = [
  "none",
  "schoolDropout",
  "school",
  "vocationalTraining",
  "sixthForm",
  "bachelors",
  "postgraduate",
] as const;
export type EducationMilestone = (typeof EDUCATION_MILESTONES)[number];

export const PARENTAL_EDUCATION_MILESTONES = [
  "primary",
  "secondary",
  "higher",
  "other",
] as const;
export type ParentalEducationMilestone =
  (typeof PARENTAL_EDUCATION_MILESTONES)[number];

export const ETHNICITIES = [
  "asian",
  "black",
  "latino",
  "middleEastern",
  "mixed",
  "white",
  "other",
] as const;
export type Ethnicity = (typeof ETHNICITIES)[number];

export type UserProfile = {
  email?: string;
  MobileNo?: string;
  phoneCountryCode?: string;
  title?: string;
  FirstName?: string;
  SecondName?: string;
  LastName?: string;
  dateOfBirth?: string;
  Gender?: Gender;
  CountryOfBirth?: Country;
  CountryOfLiving?: Country;
  linkedInHandle?: string;
  Ethnicity?: string;
  EducationLevelMother?: string;
  EducationLevelFather?: string;
  languagesSpoken?: string[];
  optionsfeel?: string;
  RelationshipStat?: string;
  financialSurvive?: string;
};
