import * as Form from "@radix-ui/react-form";
import { AriaLabelableProps } from "_shared/a11y";
import { RequiredFormMessage } from "_shared/field/RequiredFormMessage";
import { Grow } from "_shared/flex/Grow";
import { Radio } from "_shared/input/Radio";
import { RadioGroupLabel } from "_shared/input/RadioGroup/RadioGroupLabel";
import { TextInput } from "_shared/input/TextInput";
import { LocalizedMessage } from "_shared/localization/LocalizedMessage";
import { SmallText } from "_shared/text/SmallText";
import { ReactNode, useId, useRef } from "react";

export type Props = AriaLabelableProps & {
  onChange: (newValue: string) => void;
  value?: string;
  options: ReadonlyArray<{ value: string; label: ReactNode }>;
};

export function RadioGroupWithOther(props: Props) {
  const { value: groupValue, onChange, options, ...ariaProps } = props;
  const values = new Set(options.map((option) => option.value));
  const isOtherSelected = groupValue !== undefined && !values.has(groupValue);
  const otherFieldName = useId();
  const otherTextRef = useRef<HTMLInputElement>(null);
  return (
    <div role="radiogroup" className="flex flex-col gap-2" {...ariaProps}>
      <div className="flex flex-col">
        {options.map(({ value, label }) => {
          const checked = value === groupValue;
          return (
            <div key={value} className="hover:bg-shade">
              <RadioGroupLabel checked={checked}>
                <Form.Control asChild>
                  <Radio
                    checked={checked}
                    id="" // Stop @radix-ui Form.Control giving every radio the same ID
                    onChange={() => onChange(value)}
                    required
                  />
                </Form.Control>
                {label}
              </RadioGroupLabel>
            </div>
          );
        })}
        <div className="hover:bg-shade flex items-center">
          <RadioGroupLabel checked={isOtherSelected}>
            <Form.Control asChild>
              <Radio
                id="" // Stop @radix-ui Form.Control giving every radio the same ID
                checked={isOtherSelected}
                onChange={() => {
                  onChange("");
                  otherTextRef.current?.focus();
                }}
                required
              />
            </Form.Control>
            <LocalizedMessage id="input_other" />
          </RadioGroupLabel>
          <Form.Field name={otherFieldName} asChild>
            <Grow>
              <Form.Control asChild>
                <TextInput
                  ref={otherTextRef}
                  aria-label="Other response"
                  required={isOtherSelected}
                  value={isOtherSelected ? groupValue : ""}
                  onChange={onChange}
                />
              </Form.Control>
            </Grow>
          </Form.Field>
        </div>
      </div>
      <RequiredFormMessage type="select" />
      <Form.Message name={otherFieldName} match="valueMissing">
        <SmallText color="warning">
          <LocalizedMessage id="input_error_required_other" />
        </SmallText>
      </Form.Message>
    </div>
  );
}
