import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMessagesQuestion } from "@fortawesome/sharp-light-svg-icons";
import bullhorn from "_assets/icons/bullhorn.svg";
import bullseyeArrow from "_assets/icons/bullseyeArrow.svg";
import cartDollar from "_assets/icons/cartDollar.svg";
import chartBar from "_assets/icons/chartBar.svg";
import chartPie from "_assets/icons/chartPie.svg";
import clipboardListCheck from "_assets/icons/clipboardListCheck.svg";
import globe from "_assets/icons/globe.svg";
import marketLab from "_assets/icons/marketLab.svg";
import messages from "_assets/icons/messages.svg";
import penNib from "_assets/icons/penNib.svg";
import rocketLaunch from "_assets/icons/rocketLaunch.svg";
import salesFunnel from "_assets/icons/salesFunnel.svg";
import userPlus from "_assets/icons/userPlus.svg";
import users from "_assets/icons/users.svg";
import { Alert } from "_shared/Alert";
import { CustomIcon } from "_shared/CustomIcon";
import { ComingSoonBadge } from "_shared/FeatureCard/ComingSoonBadge";
import { FeatureCard } from "_shared/FeatureCard/FeatureCard";
import { InfoBadge } from "_shared/FeatureCard/InfoBadge";
import { NotificationBadge } from "_shared/FeatureCard/NotificationBadge";
import { useFeatureFlags } from "_shared/FeatureFlagsContext";
import { Scrollable } from "_shared/Scrollable/Scrollable";
import { Tooltip } from "_shared/Tooltip/Tooltip";
import { Wrapper } from "_shared/Wrapper";
import { useUser } from "_shared/auth/AuthContext";
import { FlatButton } from "_shared/button/FlatButton";
import { Grow } from "_shared/flex/Grow";
import { LocalizedMessage } from "_shared/localization/LocalizedMessage";
import { H1Text } from "_shared/text/H1Text";
import { useNavigate } from "react-router-dom";
import { AddStartup } from "startups/startup/AddStartup";
import { useStartupsQuery } from "startups/startup/useStartupsQuery";

export function Founder() {
  const {
    isMessagesOn,
    isFounderRewardsOn,
    isPortfolioOn,
    isFounderInsightsOn,
    isCommunitiesOn,
    isJottingOn,
    isBigProblemOn,
    isIndustrySurveyOn,
    isStartupsOn,
  } = useFeatureFlags();
  const query = useStartupsQuery();
  const startupsById = query.data ?? {};
  const user = useUser();
  const navigate = useNavigate();
  const oneYearAgo = new Date();
  oneYearAgo.setFullYear(new Date().getFullYear() - 1);
  return (
    <Grow>
      <Scrollable>
        <Wrapper>
          <div className="flex flex-col gap-24 self-stretch pt-8">
            <div className="flex flex-col gap-12">
              {user.dateSurveyCompleted === undefined ||
              user.dateSurveyCompleted < oneYearAgo ? (
                <Alert
                  icon={<FontAwesomeIcon icon={faMessagesQuestion} />}
                  title={
                    <LocalizedMessage
                      id="startups_survey_prompt_title"
                      values={{ now: new Date() }}
                    />
                  }
                  subtitle={
                    <LocalizedMessage id="startups_survey_prompt_description" />
                  }
                  button={
                    <FlatButton onClick={() => navigate("/founder/survey")}>
                      <LocalizedMessage id="startups_survey_prompt_take_survey_button" />
                    </FlatButton>
                  }
                />
              ) : null}
              <h1>
                <H1Text>
                  <LocalizedMessage id="startups_founder_toolkit" />
                </H1Text>
              </h1>
              <div className="grid grid-cols-[repeat(auto-fill,minmax(12rem,1fr))] gap-8">
                <FeatureCard
                  title={<LocalizedMessage id="app_portfolio" />}
                  icon={<CustomIcon svg={chartBar} />}
                  status={isPortfolioOn ? undefined : "comingSoon"}
                  to="/founder/portfolio"
                  footer={isPortfolioOn ? null : <ComingSoonBadge />}
                />
                <FeatureCard
                  title={<LocalizedMessage id="app_messages" />}
                  icon={<CustomIcon svg={messages} />}
                  status={isMessagesOn ? undefined : "comingSoon"}
                  to=""
                  footer={
                    isMessagesOn ? (
                      <>
                        <Tooltip content="Get help and suport">
                          <InfoBadge />
                        </Tooltip>
                        <NotificationBadge>23</NotificationBadge>
                      </>
                    ) : (
                      <ComingSoonBadge />
                    )
                  }
                />
                <FeatureCard
                  title={<LocalizedMessage id="app_founderRewards" />}
                  icon={<CustomIcon svg={bullhorn} />}
                  status={isFounderRewardsOn ? undefined : "comingSoon"}
                  to=""
                  footer={
                    isFounderRewardsOn ? (
                      <>
                        <Tooltip content="Take advantage of exclusive deals for founders">
                          <InfoBadge />
                        </Tooltip>
                        <NotificationBadge>1</NotificationBadge>
                      </>
                    ) : (
                      <ComingSoonBadge />
                    )
                  }
                />
                <FeatureCard
                  title={<LocalizedMessage id="app_founderInsights" />}
                  icon={<CustomIcon svg={chartPie} />}
                  status={isFounderInsightsOn ? undefined : "comingSoon"}
                  to=""
                  footer={isFounderInsightsOn ? null : <ComingSoonBadge />}
                />
                <FeatureCard
                  title={<LocalizedMessage id="app_communities" />}
                  icon={<CustomIcon svg={users} />}
                  status={isCommunitiesOn ? undefined : "comingSoon"}
                  to=""
                  footer={isCommunitiesOn ? null : <ComingSoonBadge />}
                />
                <FeatureCard
                  title={<LocalizedMessage id="app_jotting" />}
                  icon={<CustomIcon svg={penNib} />}
                  status={isJottingOn ? undefined : "comingSoon"}
                  to=""
                  footer={isJottingOn ? null : <ComingSoonBadge />}
                />
                <FeatureCard
                  title={<LocalizedMessage id="app_bigProblem" />}
                  icon={<CustomIcon svg={globe} />}
                  status={isBigProblemOn ? undefined : "comingSoon"}
                  to=""
                  footer={isBigProblemOn ? null : <ComingSoonBadge />}
                />
                <FeatureCard
                  title={<LocalizedMessage id="app_industrySurvey" />}
                  icon={<CustomIcon svg={clipboardListCheck} />}
                  status={isIndustrySurveyOn ? undefined : "comingSoon"}
                  to=""
                  footer={isIndustrySurveyOn ? null : <ComingSoonBadge />}
                />
              </div>
            </div>
            <div className="flex flex-col gap-8">
              <h1>
                <H1Text>
                  <LocalizedMessage id="startups_title" />
                </H1Text>
              </h1>
              <div className="grid grid-cols-[repeat(auto-fill,minmax(12rem,1fr))] gap-8">
                {isStartupsOn === true ? (
                  <>
                    {Object.entries(startupsById).map(([id, startup]) => {
                      return (
                        <FeatureCard
                          key={id}
                          title={startup.SUName}
                          icon={<CustomIcon svg={rocketLaunch} />}
                          to={`/startups/${id}`}
                        />
                      );
                    })}
                    <div className="flex items-center justify-center">
                      <AddStartup startupsById={startupsById} />
                    </div>
                  </>
                ) : (
                  <>
                    <FeatureCard
                      title={<LocalizedMessage id="app_marketLab" />}
                      icon={<CustomIcon svg={marketLab} />}
                      to="https://next.revveon.com/login"
                    />
                    <FeatureCard
                      title={<LocalizedMessage id="app_funding" />}
                      icon={<CustomIcon svg={cartDollar} />}
                      status="comingSoon"
                      to=""
                      footer={<ComingSoonBadge />}
                    />
                    <FeatureCard
                      title={<LocalizedMessage id="app_salesFunnel" />}
                      icon={<CustomIcon svg={salesFunnel} />}
                      status="comingSoon"
                      to=""
                      footer={<ComingSoonBadge />}
                    />
                    <FeatureCard
                      title={<LocalizedMessage id="app_crm" />}
                      icon={<CustomIcon svg={bullseyeArrow} />}
                      status="comingSoon"
                      to=""
                      footer={<ComingSoonBadge />}
                    />
                    <FeatureCard
                      title={<LocalizedMessage id="app_recruitment" />}
                      icon={<CustomIcon svg={userPlus} />}
                      status="comingSoon"
                      to=""
                      footer={<ComingSoonBadge />}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </Wrapper>
      </Scrollable>
    </Grow>
  );
}
