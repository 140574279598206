import * as Form from "@radix-ui/react-form";
import { AriaLabelableProps } from "_shared/a11y";
import { RequiredFormMessage } from "_shared/field/RequiredFormMessage";
import { Radio } from "_shared/input/Radio";
import { RadioGroupLabel } from "_shared/input/RadioGroup/RadioGroupLabel";
import { ReactNode } from "react";

export type Props<V extends string> = AriaLabelableProps & {
  onChange: (newValue: V) => void;
  value?: V;
  options: ReadonlyArray<{ value: V; label: ReactNode }>;
};

export function RadioGroup<V extends string>(props: Props<V>) {
  const { value: groupValue, onChange, options, ...ariaProps } = props;
  return (
    <div role="radiogroup" className="flex flex-col gap-2" {...ariaProps}>
      <div className="flex flex-col">
        {options.map(({ value, label }) => {
          const checked = value === groupValue;
          return (
            <div key={value} className="hover:bg-shade">
              <RadioGroupLabel checked={checked}>
                <Form.Control asChild>
                  <Radio
                    checked={checked}
                    id="" // Stop @radix-ui Form.Control giving every radio the same ID
                    onChange={() => onChange(value)}
                    required
                  />
                </Form.Control>
                {label}
              </RadioGroupLabel>
            </div>
          );
        })}
      </div>
      <RequiredFormMessage type="select" />
    </div>
  );
}
