import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faEnvelope,
  faMobile,
} from "@fortawesome/sharp-light-svg-icons";
import * as Form from "@radix-ui/react-form";
import { useFeatureFlags } from "_shared/FeatureFlagsContext";
import { Toast } from "_shared/Toast/Toast";
import { useToast } from "_shared/Toast/ToastContext";
import { useAuth } from "_shared/auth/AuthContext";
import { PrimaryButton } from "_shared/button/PrimaryButton";
import { SecondaryButton } from "_shared/button/SecondaryButton";
import { Field } from "_shared/field/Field";
import { RequiredFormMessage } from "_shared/field/RequiredFormMessage";
import { TextInput } from "_shared/input/TextInput";
import { LocalizedMessage } from "_shared/localization/LocalizedMessage";
import { H2Text } from "_shared/text/H2Text";
import { LabelText } from "_shared/text/LabelText";
import { SmallText } from "_shared/text/SmallText";
import { TruncatedText } from "_shared/text/TruncatedText";
import { Status } from "_shared/utils";
import { useEffect, useState } from "react";
import { useCountdown } from "usehooks-ts";

interface Props {
  email: string;
  mobilePhone: string;
  password: string;
  onSuccess: () => void;
}

export function Verify(props: Props) {
  const { email, mobilePhone, onSuccess, password } = props;
  const { isAuthOn } = useFeatureFlags();
  const {
    verifyEmailOneTimeCode,
    verifyPhoneOneTimeCode,
    sendEmailOneTimeCode,
    sendPhoneOneTimeCode,
    setPassword,
    signIn,
  } = useAuth();
  const { showToast } = useToast();
  const [count, { startCountdown, resetCountdown }] = useCountdown({
    countStart: 30,
  });
  const [emailOneTimeCode, setEmailOneTimeCode] = useState("");
  const [phoneOneTimeCode, setPhoneOneTimeCode] = useState("");
  const [emailStatus, setEmailStatus] = useState<Status>("idle");
  const [phoneStatus, setPhoneStatus] = useState<Status>("idle");
  const [passwordStatus, setPasswordStatus] = useState<Status>("idle");

  useEffect(startCountdown, []);

  async function verifyEmail() {
    if (emailStatus === "success") return;
    setEmailStatus("pending");
    try {
      await verifyEmailOneTimeCode(emailOneTimeCode);
      setEmailStatus("success");
    } catch (e) {
      setEmailStatus("error");
      throw e;
    }
  }

  async function verifyPhone() {
    if (phoneStatus === "success") return;
    setPhoneStatus("pending");
    try {
      await verifyPhoneOneTimeCode(phoneOneTimeCode);
      setPhoneStatus("success");
    } catch (e) {
      setPhoneStatus("error");
      throw e;
    }
  }

  async function setPasswordAndSignInAgain() {
    setPasswordStatus("pending");
    try {
      // API requires password to be set in a separate request after phone & email have been verified
      await setPassword(password);
      // API then invalidates the token, meaing the user has to sign in again, so we save them some typing
      await signIn({ email, password });
      setPasswordStatus("success");
    } catch (e) {
      setPasswordStatus("error");
      showToast((toastProps) => (
        <Toast {...toastProps} duration={Infinity}>
          <LocalizedMessage id="error" />
        </Toast>
      ));
      throw e;
    }
  }

  async function resendCodes() {
    resetCountdown();
    startCountdown();
    if (!isAuthOn) return;
    try {
      const promises = [];
      if (emailStatus !== "success") promises.push(sendEmailOneTimeCode());
      if (phoneStatus !== "success") promises.push(sendPhoneOneTimeCode());
      await Promise.all(promises);
    } catch (e) {
      showToast((toastProps) => (
        <Toast {...toastProps} duration={Infinity}>
          <LocalizedMessage id="error" />
        </Toast>
      ));
    }
  }

  return (
    <Form.Root
      className="flex flex-col gap-6"
      onSubmit={async (e) => {
        e.preventDefault();
        try {
          if (isAuthOn) {
            await Promise.all([verifyEmail(), verifyPhone()]);
            await setPasswordAndSignInAgain();
          }
          onSuccess();
        } catch (_e) {
          // Do nothing. Each function called handles errors internally.
        }
      }}
    >
      <h2>
        <H2Text>
          <LocalizedMessage id="user_verification_title" />
        </H2Text>
      </h2>
      <Field name="emailCode" serverInvalid={emailStatus === "error"}>
        <LabelText>
          <Form.Label className="flex items-center gap-1">
            <FontAwesomeIcon fixedWidth icon={faEnvelope} />
            <LocalizedMessage id="user_email_verification_code" />
          </Form.Label>
        </LabelText>
        <Form.Control asChild>
          <TextInput
            autoComplete="off"
            required
            suffix={
              emailStatus === "success" ? (
                <FontAwesomeIcon
                  className="text-xl text-green-48"
                  icon={faCheck}
                />
              ) : null
            }
            readOnly={emailStatus === "success"}
            value={emailOneTimeCode}
            onChange={(newValue) => {
              setEmailStatus("idle");
              setEmailOneTimeCode(newValue);
            }}
          />
        </Form.Control>
        <RequiredFormMessage />
        <SmallText color="light">
          <TruncatedText>{email}</TruncatedText>
        </SmallText>
        {emailStatus === "error" ? (
          <SmallText color="warning">Invalid code</SmallText>
        ) : null}
      </Field>
      <Field name="phoneCode" serverInvalid={phoneStatus === "error"}>
        <LabelText>
          <Form.Label className="flex items-center gap-1">
            <FontAwesomeIcon fixedWidth icon={faMobile} />
            <LocalizedMessage id="user_phone_verification_code" />
          </Form.Label>
        </LabelText>
        <Form.Control asChild>
          <TextInput
            autoComplete="one-time-code"
            required
            suffix={
              phoneStatus === "success" ? (
                <FontAwesomeIcon
                  className="text-xl text-green-48"
                  icon={faCheck}
                />
              ) : null
            }
            readOnly={phoneStatus === "success"}
            value={phoneOneTimeCode}
            onChange={(newValue) => {
              setPhoneStatus("idle");
              setPhoneOneTimeCode(newValue);
            }}
          />
        </Form.Control>
        <RequiredFormMessage />
        <SmallText color="light">
          <TruncatedText>{mobilePhone}</TruncatedText>
        </SmallText>
        {phoneStatus === "error" ? (
          <SmallText color="warning">Invalid code</SmallText>
        ) : null}
      </Field>
      <LocalizedMessage id="user_check_your_email_and_phone" />
      <div className="flex flex-col gap-3">
        <Form.Submit asChild>
          <PrimaryButton
            status={
              emailStatus === "pending" ||
              phoneStatus === "pending" ||
              passwordStatus === "pending"
                ? "pending"
                : undefined
            }
          >
            <LocalizedMessage id="user_verify" />
          </PrimaryButton>
        </Form.Submit>
        <SecondaryButton disabled={count > 0} onClick={resendCodes}>
          <LocalizedMessage
            id="user_resend_verification_codes"
            values={{ count }}
          />
        </SecondaryButton>
      </div>
    </Form.Root>
  );
}
