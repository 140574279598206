// eslint-disable-next-line no-restricted-imports
import { PrimitiveType, useIntl } from "react-intl";
// eslint-disable-next-line no-restricted-imports
import { STRINGS } from "_shared/localization/strings";

export function useLocalize() {
  const intl = useIntl();
  function localize(
    id: keyof typeof STRINGS,
    values?: Record<string, PrimitiveType>,
  ): string {
    return intl.formatMessage({ id }, values);
  }
  return localize;
}
