import bullseyeArrow from "_assets/icons/bullseyeArrow.svg";
import cartDollar from "_assets/icons/cartDollar.svg";
import marketLab from "_assets/icons/marketLab.svg";
import salesFunnel from "_assets/icons/salesFunnel.svg";
import userPlus from "_assets/icons/userPlus.svg";
import { Breadcrumb } from "_shared/Breadcrumbs/Breadcrumb";
import { Breadcrumbs } from "_shared/Breadcrumbs/Breadcrumbs";
import { CustomIcon } from "_shared/CustomIcon";
import { FeatureCard } from "_shared/FeatureCard/FeatureCard";
import { NotificationBadge } from "_shared/FeatureCard/NotificationBadge";
import { Scrollable } from "_shared/Scrollable/Scrollable";
import { Wrapper } from "_shared/Wrapper";
import { Grow } from "_shared/flex/Grow";
import { LocalizedMessage } from "_shared/localization/LocalizedMessage";
import { H1Text } from "_shared/text/H1Text";
import { useParams } from "react-router-dom";
import { useStartupsQuery } from "startups/startup/useStartupsQuery";
import invariant from "tiny-invariant";

export function Startup() {
  const { startupId } = useParams();
  invariant(startupId);
  const { data: startupsById } = useStartupsQuery();
  if (startupsById === undefined) return null;
  const startup = startupsById[startupId];
  if (!startup) return <Wrapper>Not found</Wrapper>;
  return (
    <Grow>
      <Scrollable>
        <Wrapper>
          <div className="flex flex-col gap-12">
            <div className="flex flex-col gap-4">
              <Breadcrumbs>
                <Breadcrumb to="/">
                  <LocalizedMessage id="startups_title" />
                </Breadcrumb>
                <Breadcrumb>{startup.SUName}</Breadcrumb>
              </Breadcrumbs>
              <h1>
                <H1Text>{startup.SUName}</H1Text>
              </h1>
            </div>
            <div className="grid grid-cols-[repeat(auto-fill,minmax(12rem,1fr))] gap-8">
              <FeatureCard
                title={<LocalizedMessage id="app_marketLab" />}
                icon={<CustomIcon svg={marketLab} />}
                footer={<NotificationBadge>2</NotificationBadge>}
                to="market-lab"
              />
              <FeatureCard
                title={<LocalizedMessage id="app_funding" />}
                icon={<CustomIcon svg={cartDollar} />}
                status="comingSoon"
                to=""
              />
              <FeatureCard
                title={<LocalizedMessage id="app_salesFunnel" />}
                icon={<CustomIcon svg={salesFunnel} />}
                status="comingSoon"
                to=""
              />
              <FeatureCard
                title={<LocalizedMessage id="app_crm" />}
                icon={<CustomIcon svg={bullseyeArrow} />}
                status="comingSoon"
                to=""
              />
              <FeatureCard
                title={<LocalizedMessage id="app_recruitment" />}
                icon={<CustomIcon svg={userPlus} />}
                status="comingSoon"
                to=""
              />
            </div>
          </div>
        </Wrapper>
      </Scrollable>
    </Grow>
  );
}
