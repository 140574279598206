import classNames from "classnames";
import { ComponentProps, forwardRef } from "react";

type Props = Omit<ComponentProps<"textarea">, "ref">;

export const TextArea = forwardRef<HTMLTextAreaElement, Props>(
  function TextArea({ className, ...otherProps }, ref) {
    return (
      <textarea
        ref={ref}
        className={classNames(
          "grow rounded-sm border border-gray-80 px-3 py-2 focus-visible:outline focus-visible:outline-2 focus-visible:-outline-offset-1 focus-visible:outline-blue-62 data-[invalid]:border-red-50 dark:border-gray-42 dark:bg-gray-24 dark:data-[invalid]:border-yellow-57",
          className,
        )}
        {...otherProps}
      />
    );
  },
);
