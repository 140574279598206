import { Arrow, Content, Portal, Root, Trigger } from "@radix-ui/react-popover";
import { ReactNode, Ref, forwardRef } from "react";

interface Props {
  content: ReactNode;
  children: ReactNode;
}

export const Popover = forwardRef(function Popover(
  props: Props,
  ref: Ref<HTMLButtonElement>,
) {
  const { content, children, ...otherProps } = props;
  return (
    <Root>
      <Trigger asChild ref={ref} {...otherProps}>
        {children}
      </Trigger>
      <Portal>
        <Content
          sideOffset={5}
          className="max-w-[50vw] data-[state*=open]:animate-pop-in data-[state=closed]:animate-pop-out "
        >
          <div className="flex flex-col rounded-sm bg-white text-gray-42  shadow-lg dark:bg-gray-42 dark:text-gray-94">
            {content}
          </div>
          <Arrow className="fill-white dark:fill-gray-42" />
        </Content>
      </Portal>
    </Root>
  );
});
