import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeftFromArc,
  faArrowRightToArc,
  faInfoCircle,
  faKey,
  faPencil,
  faPlus,
  faTimesCircle,
} from "@fortawesome/sharp-light-svg-icons";
import * as Dialog from "@radix-ui/react-dialog";
import * as Form from "@radix-ui/react-form";
import { createColumnHelper } from "@tanstack/react-table";
import { Scrollable } from "_shared/Scrollable/Scrollable";
import { SideDialog } from "_shared/SideDialog/SideDialog";
import { SideDialogFooter } from "_shared/SideDialog/SideDialogFooter";
import { SideDialogHeader } from "_shared/SideDialog/SideDialogHeader";
import { Table } from "_shared/Table/Table";
import { Tooltip } from "_shared/Tooltip/Tooltip";
import { IconButton } from "_shared/button/IconButton";
import { PrimaryButton } from "_shared/button/PrimaryButton";
import { SecondaryButton } from "_shared/button/SecondaryButton";
import { Field } from "_shared/field/Field";
import { RequiredFormMessage } from "_shared/field/RequiredFormMessage";
import { Grow } from "_shared/flex/Grow";
import { Select } from "_shared/input/Select/Select";
import { Switch } from "_shared/input/Switch/Switch";
import { ToggleGroup } from "_shared/input/ToggleGroup/ToggleGroup";
import { ToggleGroupItemPadding } from "_shared/input/ToggleGroup/ToggleGroupItemPadding";
import { LocalizedMessage } from "_shared/localization/LocalizedMessage";
import { H3Text } from "_shared/text/H3Text";
import { MonoText } from "_shared/text/MonoText";
import { SmallText } from "_shared/text/SmallText";

interface Props {
  name?: string;
}

export function UseCase(props: Props) {
  const { name } = props;
  return (
    <SideDialog
      isModal={false}
      trigger={
        <IconButton size="small">
          <FontAwesomeIcon icon={faPencil} />
        </IconButton>
      }
    >
      <Form.Root className="flex grow flex-col gap-6">
        <SideDialogHeader>
          <div className="flex flex-col gap-1">
            <SmallText color="light">Use Case</SmallText>
            <span>{name}</span>
          </div>
        </SideDialogHeader>
        <Grow>
          <Scrollable>
            <div className="px-8">
              <h3>
                <H3Text>API</H3Text>
              </h3>
              <Table columns={columns} data={mockApiFields} />
            </div>
          </Scrollable>
        </Grow>
        <SideDialogFooter>
          <div className="flex flex-1 gap-4">
            <Form.Submit asChild>
              <PrimaryButton>
                <LocalizedMessage id="button_save" />
              </PrimaryButton>
            </Form.Submit>
            <Dialog.Close asChild>
              <SecondaryButton>
                <LocalizedMessage id="button_cancel" />
              </SecondaryButton>
            </Dialog.Close>
          </div>
          {status === "error" ? (
            <SmallText color="warning">
              <LocalizedMessage id="error" />
            </SmallText>
          ) : null}
        </SideDialogFooter>
      </Form.Root>
    </SideDialog>
  );
}

interface ApiField {
  name: string;
  type: "date" | "boolean" | "string" | "integer" | "float";
  use: "input" | "output" | "primary";
  isOn: boolean;
}

const columnHelper = createColumnHelper<ApiField>();

const columns = [
  columnHelper.accessor("name", {
    header: "Name",
    cell: ({ getValue }) => {
      return <MonoText>{getValue()}</MonoText>;
    },
  }),
  columnHelper.accessor("type", {
    header: "Type",
    cell: () => {
      return (
        <Field>
          <Form.Control asChild>
            <Select
              required
              value="bigint"
              onChange={() => {}}
              options={[
                { value: "bigint", label: "Bigint" },
                { value: "bit", label: "Bit" },
                { value: "dateTime", label: "DateTime" },
                { value: "decimal", label: "Decimal" },
                { value: "longText", label: "Long Text" },
                { value: "shortText", label: "Short Text" },
              ]}
            />
          </Form.Control>
          <RequiredFormMessage />
        </Field>
      );
    },
  }),
  columnHelper.accessor("use", {
    header: () => (
      <div className="flex items-center gap-2">
        Use
        <Tooltip
          content={
            <div className="flex flex-col gap-2">
              <div>Input - data sent to your service for processing</div>
              <div>{"Output - the result of your service's processing"}</div>
              <div>
                Primary key - A single column, like ID, or a composite of
                multiple columns
              </div>
            </div>
          }
        >
          <FontAwesomeIcon icon={faInfoCircle} />
        </Tooltip>
      </div>
    ),
    cell: ({ getValue }) => {
      return (
        <ToggleGroup
          value={getValue()}
          onChange={() => {}}
          options={[
            {
              value: "input",
              label: (
                <ToggleGroupItemPadding>
                  <div className="flex items-center gap-2 whitespace-nowrap">
                    <FontAwesomeIcon icon={faArrowRightToArc} />
                    Input
                  </div>
                </ToggleGroupItemPadding>
              ),
            },
            {
              value: "output",
              label: (
                <ToggleGroupItemPadding>
                  <div className="flex items-center gap-2 whitespace-nowrap">
                    <FontAwesomeIcon icon={faArrowLeftFromArc} />
                    Output
                  </div>
                </ToggleGroupItemPadding>
              ),
            },
            {
              value: "primary",
              label: (
                <ToggleGroupItemPadding>
                  <div className="flex items-center gap-2 whitespace-nowrap">
                    <FontAwesomeIcon icon={faKey} />
                    Primary key
                  </div>
                </ToggleGroupItemPadding>
              ),
            },
          ]}
        />
      );
    },
  }),
  columnHelper.accessor("isOn", {
    header: "Enabled",
    cell: ({ getValue }) => {
      const isOn = getValue();
      return <Switch aria-label="Enabled" value={isOn} />;
    },
  }),
  columnHelper.display({
    id: "actions",
    header: () => {
      return (
        <div className="flex grow justify-end">
          <SecondaryButton>
            <FontAwesomeIcon icon={faPlus} />
            Add field
          </SecondaryButton>
        </div>
      );
    },
    cell: () => {
      return (
        <div className="flex grow justify-center">
          <Tooltip content="Delete column">
            <IconButton size="small">
              <FontAwesomeIcon icon={faTimesCircle} />
            </IconButton>
          </Tooltip>
        </div>
      );
    },
  }),
];

const mockApiFields: Array<ApiField> = [
  {
    name: "id",
    type: "integer",
    use: "primary",
    isOn: true,
  },
  {
    name: "amount",
    type: "integer",
    use: "input",
    isOn: false,
  },
  {
    name: "location",
    type: "string",
    use: "input",
    isOn: true,
  },
  {
    name: "time",
    type: "date",
    use: "input",
    isOn: false,
  },
  {
    name: "fraud_risk",
    type: "float",
    use: "output",
    isOn: true,
  },
];
