import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/sharp-light-svg-icons";
import { useLocalize } from "_shared/localization/useLocalize";
import classNames from "classnames";

interface Props {
  currentIndex: number;
  length: number;
}

export function Stepper({ currentIndex, length }: Props) {
  const localize = useLocalize();
  return (
    <nav aria-label={localize("pagination_steps")}>
      <ol className="flex items-center">
        {Array.from({ length }).map((_, i) => {
          return (
            <li key={i} className="flex items-center">
              <div
                key={i}
                aria-current={i === currentIndex ? "step" : undefined}
                className={classNames(
                  "flex aspect-square w-10 items-center justify-center rounded-full border font-thin transition-colors",
                  i < currentIndex
                    ? "border-transparent bg-primary text-white dark:text-gray-24"
                    : i === currentIndex
                      ? "border-primary dark:bg-gray-31"
                      : "border-gray-67 dark:border-gray-42 dark:bg-gray-31",
                )}
              >
                {i < currentIndex ? (
                  <FontAwesomeIcon icon={faCheck} className="animate-pop-in" />
                ) : (
                  i + 1
                )}
              </div>
              {i < length - 1 ? (
                <div
                  key={`${i}_divider`}
                  className={classNames(
                    "h-[1px] w-5 transition-colors",
                    i < currentIndex
                      ? "bg-primary"
                      : "bg-gray-67 dark:bg-gray-42",
                  )}
                ></div>
              ) : null}
            </li>
          );
        })}
      </ol>
    </nav>
  );
}
