import { TextInput } from "_shared/input/TextInput";
import { Ref, forwardRef } from "react";
interface Props {
  value?: string;
  required?: boolean;
  onChange?: (value: string) => void;
}

export const LinkedInInput = forwardRef<HTMLInputElement, Props>(
  function LinkedInInput(props, ref: Ref<HTMLInputElement>) {
    const { onChange, ...otherProps } = props;
    return (
      <TextInput
        prefix="www.linkedin.com/in/"
        ref={ref}
        pattern="[^\\/]+"
        onChange={onChange}
        onPaste={(e: React.ClipboardEvent<HTMLInputElement>) => {
          const handle = e.clipboardData
            ?.getData("text")
            .split("linkedin.com/in/")
            .pop()
            ?.replace("/", "");
          if (!handle || !onChange) return;
          onChange(handle);
          e.preventDefault();
        }}
        {...otherProps}
      />
    );
  },
);
