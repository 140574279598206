import * as Form from "@radix-ui/react-form";
import { Scrollable } from "_shared/Scrollable/Scrollable";
import { Toast } from "_shared/Toast/Toast";
import { useToast } from "_shared/Toast/ToastContext";
import { Wrapper } from "_shared/Wrapper";
import { useAuth, useUser } from "_shared/auth/AuthContext";
import { BackButton } from "_shared/button/BackButton";
import { PrimaryButton } from "_shared/button/PrimaryButton";
import { SecondaryButton } from "_shared/button/SecondaryButton";
import { Field } from "_shared/field/Field";
import { InvalidEmailMessage } from "_shared/field/InvalidEmailMessage";
import { InvalidIntMessage } from "_shared/field/InvalidIntMessage";
import { RequiredFormMessage } from "_shared/field/RequiredFormMessage";
import { Grow } from "_shared/flex/Grow";
import { CheckboxGroup } from "_shared/input/CheckboxGroup/CheckboxGroup";
import { CheckboxGroupWithOther } from "_shared/input/CheckboxGroup/CheckboxGroupWithOther";
import { IntInput } from "_shared/input/IntInput";
import { ListBuilder } from "_shared/input/ListBuilder/ListBuilder";
import { RadioGroup } from "_shared/input/RadioGroup/RadioGroup";
import { RadioGroupWithOther } from "_shared/input/RadioGroup/RadioGroupWithOther";
import { Select } from "_shared/input/Select/Select";
import { TextArea } from "_shared/input/TextArea";
import { TextInput } from "_shared/input/TextInput";
import { LocalizedMessage } from "_shared/localization/LocalizedMessage";
import { H2Text } from "_shared/text/H2Text";
import { SmallText } from "_shared/text/SmallText";
import { Status } from "_shared/utils";
import { partition, sum } from "lodash";
import { useEffect, useId, useState } from "react";
import invariant from "tiny-invariant";
import { Question } from "user/static-survey/Question";
import { End } from "user/static-survey/Questions/End";

/**
 * WARNING: This component is built to work with a bad back-end.
 * It is not up to Blastoff's usual standards.
 */
export function Survey() {
  const { post } = useAuth();
  const user = useUser();
  const { showToast } = useToast();
  const [nextStatus, setNextStatus] = useState<Status>("idle");
  const [backStatus, setBackStatus] = useState<Status>("idle");
  const [skipStatus, setSkipStatus] = useState<Status>("idle");
  const [state, setState] = useState<State>();
  const [isComplete, setIsComplete] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [value, setValue] = useState<any>();
  const ids = {
    label: useId(),
  };

  function parseResponse(response: object) {
    if ("0" in response) {
      const newState = response[0] as State;
      setState(newState);
      const { defaultValue } = newState;
      if (defaultValue === undefined || defaultValue.length === 0) {
        setValue(undefined);
      } else {
        switch (newState.ans_type) {
          case "Open":
          case "Email":
          case "Open Numeric":
          case "Dropdown":
          case "5-Star Rating":
          case "Single Select":
          case "Single Select with Other": {
            if (
              typeof defaultValue[0] === "string" &&
              /^\d+$/.test(defaultValue[0])
            ) {
              // Sometimes the back-end sends AnsIDs as strings, which breaks `===` comparison
              setValue(Number(defaultValue[0]));
            } else {
              setValue(defaultValue[0]);
            }
            break;
          }
          case "Multi Select":
          case "Multi Select with Other": {
            setValue(new Set(defaultValue));
            break;
          }
          case "Rank": {
            setValue(defaultValue.map((AnsID) => `${AnsID}`));
            break;
          }
          case "Matrix": {
            setValue(
              Object.fromEntries(
                newState.answers.map(({ AnsID }, i) => {
                  return [AnsID, defaultValue[i]];
                }),
              ),
            );
            break;
          }
        }
      }
    } else {
      invariant(
        "message" in response && response.message === "SurveyCompleted",
      );
      setState(undefined);
      setValue(undefined);
      setIsComplete(true);
    }
  }

  useEffect(() => {
    async function fetchQuestion() {
      try {
        const response = await post("get-founder-survey-question");
        parseResponse(response);
      } catch (e) {
        showToast((props) => (
          <Toast {...props} duration={Infinity}>
            <LocalizedMessage id="error" />
          </Toast>
        ));
      }
    }
    fetchQuestion();
  }, [user]);

  async function back() {
    invariant(state?.QID !== undefined);
    setBackStatus("pending");
    try {
      const response = await post("get-previQuestion", {
        questionId: state.QID,
      });
      parseResponse(response);
    } catch (e) {
      showToast((props) => (
        <Toast {...props} duration={Infinity}>
          <LocalizedMessage id="error" />
        </Toast>
      ));
    }
    setBackStatus("idle");
  }

  async function skip() {
    invariant(state);
    setSkipStatus("pending");
    try {
      const response = await post("submit-founder-survey-answer", {
        values: [state.QID, null],
      });
      parseResponse(response);
    } catch (e) {
      showToast((props) => (
        <Toast {...props} duration={Infinity}>
          <LocalizedMessage id="error" />
        </Toast>
      ));
    }
    setSkipStatus("idle");
  }

  async function next() {
    invariant(state);
    setNextStatus("pending");
    const ansIds = new Set(state.answers.map(({ AnsID }) => Number(AnsID)));
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const values: Array<any> = [state.QID];
    switch (state.ans_type) {
      case "Open":
      case "Email": {
        /**
         * When `state.mandatory === false`, the user can click "Next" without
         * entering a value. This is as if they pressed "Skip", so we send `null`.
         */
        values.push(value ?? null);
        break;
      }
      case "Open Numeric":
      case "Dropdown":
      case "5-Star Rating":
      case "Single Select":
      case "Single Select with Other": {
        values.push(ansIds.has(Number(value)) ? Number(value) : value ?? null);
        break;
      }
      case "Multi Select":
      case "Multi Select with Other": {
        if (value instanceof Set && value.size > 0) {
          // Endpoint expects other value to be at the end
          const [rest, other] = partition([...value], (item) =>
            ansIds.has(Number(item)),
          );
          values.push(...rest);
          values.push(...other);
        } else {
          values.push(null);
        }
        break;
      }
      case "Rank": {
        if (Array.isArray(value) && value.length > 0) {
          values.push(...value.map((AnsID) => Number(AnsID)));
        } else {
          values.push(null);
        }
        break;
      }
      case "Matrix": {
        if (value && Object.keys(value).length > 0) {
          values.push(...state.answers.map(({ AnsID }) => value[AnsID] ?? 0));
        } else {
          values.push(null);
        }
        break;
      }
    }
    try {
      const response = await post("submit-founder-survey-answer", { values });
      parseResponse(response);
    } catch (e) {
      showToast((props) => (
        <Toast {...props} duration={Infinity}>
          <LocalizedMessage id="error" />
        </Toast>
      ));
    }
    setNextStatus("idle");
  }

  if (isComplete) return <End />;

  if (state === undefined) return null;

  const answersWithoutOther = state.answers.filter(
    ({ AnsString }) => AnsString !== "Other",
  );
  const hasOther = state.answers.length !== answersWithoutOther.length;

  let input;
  switch (state.ans_type) {
    case "Open": {
      input = (
        <Field>
          <Form.Control asChild>
            <TextArea
              rows={4}
              required={state.mandatory}
              value={value ?? ""}
              onChange={(e) => setValue(e.target.value)}
            />
          </Form.Control>
          <RequiredFormMessage />
        </Field>
      );
      break;
    }
    case "Email": {
      input = (
        <Field>
          <Form.Control asChild>
            <TextInput
              type="email"
              required={state.mandatory}
              value={value ?? ""}
              onChange={setValue}
            />
          </Form.Control>
          <InvalidEmailMessage />
          <RequiredFormMessage />
        </Field>
      );
      break;
    }
    case "Open Numeric": {
      input = (
        <Field>
          <Form.Control asChild>
            <IntInput
              required={state.mandatory}
              value={value}
              onChange={setValue}
            />
          </Form.Control>
          <InvalidIntMessage />
          <RequiredFormMessage />
        </Field>
      );
      break;
    }
    case "Dropdown": {
      input = (
        <Field>
          <Form.Control asChild>
            <Select
              required={state.mandatory}
              value={value}
              onChange={setValue}
              options={state.answers.map(({ AnsID, AnsString }) => {
                return { value: AnsID, label: AnsString };
              })}
            />
          </Form.Control>
          <RequiredFormMessage type="select" />
        </Field>
      );
      break;
    }
    // Endue derive the "with Other" type by just checking `answers` for  the string "Other" on the back-end. It cannot be trusted.
    case "Single Select":
    case "Single Select with Other":
    case "5-Star Rating": {
      input = (
        <Field>
          {hasOther ? (
            <RadioGroupWithOther
              aria-labelledby={ids.label}
              value={value}
              onChange={setValue}
              options={answersWithoutOther.map(({ AnsID, AnsString }) => {
                return { value: AnsID, label: AnsString };
              })}
            />
          ) : (
            <RadioGroup
              aria-labelledby={ids.label}
              value={value}
              onChange={setValue}
              options={state.answers.map(({ AnsID, AnsString }) => {
                return { value: AnsID, label: AnsString };
              })}
            />
          )}
        </Field>
      );
      break;
    }
    // Endue derive the "... with Other" type by just checking `answers` for  the string "Other" on the back-end. It cannot be trusted.
    case "Multi Select":
    case "Multi Select with Other": {
      input = (
        <Field>
          {hasOther ? (
            <CheckboxGroupWithOther
              aria-labelledby={ids.label}
              value={value ?? new Set()}
              onChange={setValue}
              required={state.mandatory}
              options={answersWithoutOther.map(({ AnsID, AnsString }) => {
                return { value: AnsID, label: AnsString };
              })}
            />
          ) : (
            <CheckboxGroup
              aria-labelledby={ids.label}
              value={value ?? new Set()}
              onChange={setValue}
              required={state.mandatory}
              options={state.answers.map(({ AnsID, AnsString }) => {
                return { value: AnsID, label: AnsString };
              })}
            />
          )}
        </Field>
      );
      break;
    }
    case "Matrix": {
      const total = sum(Object.values(value ?? {}));
      input = (
        <>
          <div className="grid grid-cols-[max-content,auto] items-center gap-4">
            {state.answers.map(({ AnsID, AnsString }) => {
              return (
                <div key={AnsID} className="contents">
                  <label htmlFor={AnsID}>{AnsString}</label>
                  <IntInput
                    id={AnsID}
                    required={state.mandatory}
                    suffix="%"
                    value={(value?.[AnsID] ?? "").toString()}
                    onChange={(newValue) => {
                      setValue({
                        ...value,
                        [AnsID]: newValue,
                      });
                    }}
                  />
                </div>
              );
            })}
          </div>
          <Field>
            <Form.Control
              className="hidden"
              type="number"
              value={total}
              min={total > 0 ? 100 : undefined}
              max={total > 0 ? 100 : undefined}
            />
            <SmallText color="warning">
              <Form.Message match="rangeUnderflow">
                Total is under 100%
              </Form.Message>
              <Form.Message match="rangeOverflow">
                Total is over 100%
              </Form.Message>
            </SmallText>
          </Field>
        </>
      );
      break;
    }
    case "Rank": {
      input = (
        <ListBuilder
          options={Object.fromEntries(
            state.answers.map(({ AnsID, AnsString }) => [AnsID, AnsString]),
          )}
          values={value ?? []}
          onChange={setValue}
        />
      );
      break;
    }
  }

  return (
    <Grow>
      <Scrollable>
        <Wrapper>
          <div className="flex flex-col items-center">
            <Form.Root
              className="max-w-lg"
              onSubmit={(e) => {
                e.preventDefault();
                next();
              }}
            >
              <Question
                header={
                  <div className="flex flex-col gap-4">
                    <div>
                      <h2>
                        <H2Text>
                          <LocalizedMessage id="survey_title" />
                        </H2Text>
                      </h2>
                    </div>
                    <BackButton
                      onClick={back}
                      disabled={
                        state.QID < 2 ||
                        backStatus === "pending" ||
                        nextStatus === "pending" ||
                        skipStatus === "pending"
                      }
                    />
                  </div>
                }
                label={<span id={ids.label}>{state.description}</span>}
                input={input}
                primaryButton={
                  <Form.Submit asChild>
                    <PrimaryButton
                      status={nextStatus}
                      disabled={
                        backStatus === "pending" || skipStatus === "pending"
                      }
                    >
                      Next
                    </PrimaryButton>
                  </Form.Submit>
                }
                secondaryButton={
                  <SecondaryButton
                    onClick={skip}
                    status={skipStatus}
                    disabled={
                      state.mandatory ||
                      nextStatus === "pending" ||
                      backStatus === "pending"
                    }
                  >
                    Skip
                  </SecondaryButton>
                }
              />
            </Form.Root>
          </div>
        </Wrapper>
      </Scrollable>
    </Grow>
  );
}

interface State {
  QID: number;
  description: string;
  is_locked: boolean;
  ans_type:
    | "Open"
    | "Email"
    | "Open Numeric"
    | "Dropdown"
    | "5-Star Rating"
    | "Single Select"
    | "Single Select with Other"
    | "Multi Select"
    | "Multi Select with Other"
    | "Matrix"
    | "Rank";
  Section: string;
  ans_count: string;
  mandatory: boolean;
  aboutYouPercentage: number;
  FounderLifePercentage: number;
  StartUpexperiencePercentage: number;
  MostRecentexitStartupPercentage: number;
  VentureCapitalfeedbackPercentage: number;
  VentureCapitalPercentage: number;
  WorkingAt_StartupPercentage: number;
  beingAfounderPercentage: number;
  EquityPercentage: number;
  OtherPercentage: number;
  answers: ReadonlyArray<Answer>;
  defaultValue: ReadonlyArray<string | number>;
}

interface Answer {
  AnsID: string;
  AnsOrder: string;
  AnsString: string;
}
