import * as ScrollArea from "@radix-ui/react-scroll-area";
import classNames from "classnames";
import { ComponentProps } from "react";

interface Props extends ComponentProps<typeof ScrollArea.Scrollbar> {
  orientation: "horizontal" | "vertical";
  onMouseEnter: () => void;
  onMouseLeave: () => void;
}

export function Scrollbar(props: Props) {
  const { orientation } = props;
  return (
    <ScrollArea.Scrollbar
      className={classNames(
        "flex items-center opacity-0 transition-all duration-300 data-[state=visible]:opacity-100",
        orientation === "horizontal"
          ? "h-3 origin-bottom hover:[scale:1.0_1.66]"
          : "w-3 origin-right flex-col hover:[scale:1.66_1.0]",
      )}
      forceMount // Keep mounted for transitions
      {...props}
    >
      <div
        className={classNames(
          "absolute rounded-full bg-black bg-opacity-20",
          orientation === "horizontal"
            ? "top-1/2 h-1 w-full -translate-y-1/2"
            : "left-1/2 h-full w-1 -translate-x-1/2",
        )}
      />
      <ScrollArea.Thumb
        className={classNames(
          "rounded-full bg-gray-48 transition-[scale] duration-300 dark:bg-gray-12",
          orientation === "horizontal" ? "h-2 " : "w-2",
        )}
        style={{
          [orientation === "horizontal" ? "height" : "width"]: undefined,
        }}
      />
    </ScrollArea.Scrollbar>
  );
}
