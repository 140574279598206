import { ReactNode } from "react";

interface Props {
  children?: ReactNode;
}

export function SideDialogFooter(props: Props) {
  const { children } = props;
  return (
    <div className="mx-8 flex gap-4 border-t border-gray-86 py-4 dark:border-gray-42 dark:bg-gray-31">
      {children}
    </div>
  );
}
