import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo } from "@fortawesome/sharp-light-svg-icons";
import { ComponentPropsWithoutRef, forwardRef } from "react";

export const InfoBadge = forwardRef<
  HTMLDivElement,
  ComponentPropsWithoutRef<"div">
>(function InfoBadge(props, ref) {
  return (
    <div
      ref={ref}
      className="flex aspect-square w-6 items-center justify-center rounded-full border border-current text-xs text-gray-42 dark:text-gray-80"
      {...props}
    >
      <FontAwesomeIcon icon={faInfo} />
    </div>
  );
});
