import * as Form from "@radix-ui/react-form";
import { LocalizedMessage } from "_shared/localization/LocalizedMessage";
import { SmallText } from "_shared/text/SmallText";

/**
 * Use within {@link Form.Field} to show an error message when an email input is invalid.
 */
export function InvalidEmailMessage() {
  return (
    <Form.Message match="typeMismatch">
      <SmallText color="warning">
        <LocalizedMessage id="input_error_email_invalid" />
      </SmallText>
    </Form.Message>
  );
}
