import * as Form from "@radix-ui/react-form";
import { LocalizedMessage } from "_shared/localization/LocalizedMessage";
import { SmallText } from "_shared/text/SmallText";

interface Props {
  type?: "generic" | "select" | "date";
}

/**
 * Use within {@link Form.Field} to show an error message when a required field is left blank.
 */
export function RequiredFormMessage(props: Props) {
  const { type = "generic" } = props;
  return (
    <Form.Message match="valueMissing">
      <SmallText color="warning">
        <LocalizedMessage id={`input_error_required_${type}`} />
      </SmallText>
    </Form.Message>
  );
}
