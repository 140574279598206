import { ChangeEventHandler, forwardRef, useId } from "react";

interface Props {
  accept: string;
  multiple?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  required?: boolean;
}

export const FileInput = forwardRef<HTMLInputElement, Props>(
  function FileInput(props, ref) {
    const id = useId();
    return (
      <div className="rounded-sm border border-dashed border-gray-86 p-1 dark:border-gray-42">
        <input
          ref={ref}
          type="file"
          aria-labelledby={id}
          className="revveon-file-input"
          {...props}
        />
      </div>
    );
  },
);
