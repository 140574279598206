import { InputProps } from "_shared/input/Input.types";
import { TextInput } from "_shared/input/TextInput";
import { mergeRefs } from "_shared/utils";
import { forwardRef, useEffect, useRef, useState } from "react";

interface Props extends InputProps {
  onChange?: (newValue: number | undefined) => void;
  value?: number;
}

/**
 * A controlled input which manages its own value, and only calls `onChange` when its value is an integer.
 */
export const IntInput = forwardRef<HTMLInputElement, Props>(
  function IntInput(props, ref) {
    const { onChange, prefix, suffix, value, ...inputProps } = props;
    const [localValue, setLocalValue] = useState<string>(
      value?.toString() ?? "",
    );
    const localRef = useRef<HTMLInputElement>();

    useEffect(() => {
      if (localRef.current === document.activeElement) return;
      setLocalValue(value?.toString() ?? "");
    }, [value]);

    return (
      <TextInput
        prefix={prefix}
        ref={mergeRefs(localRef, ref)}
        inputMode="numeric"
        pattern="[0-9]*"
        value={localValue}
        defaultValue={undefined}
        onChange={(newValue) => {
          setLocalValue(newValue);
          if (newValue === "") {
            try {
              onChange?.(undefined);
            } catch (err) {
              /**
               * Radix will not support deselecting until @radiux-ui/primitive `composeEventHandlers` fully supports custom `onChange` events (which don't use `Event` objects).
               * Currently, @radix-ui checks `event.defaultPrevented` which, though dodgy, works for strings but throws if we do `onChange(undefined)`.
               * See https://github.com/radix-ui/primitives/issues/2464
               * We therefore need to catch this error, which does not stop the `onChange(undefined)`.
               */
            }
          } else {
            const int = Number(newValue);
            if (!isNaN(int)) onChange?.(int);
          }
        }}
        suffix={suffix}
        {...inputProps}
      />
    );
  },
);
