import classNames from "classnames";
import { ComponentProps, ReactNode, forwardRef } from "react";

interface Props extends Omit<ComponentProps<"button">, "className"> {
  icon?: ReactNode;
  size?: "small" | "normal";
}

export const FlatButton = forwardRef<HTMLButtonElement, Omit<Props, "ref">>(
  function FlatButton(props, ref) {
    const {
      children,
      icon,
      type = "button",
      size = "normal",
      ...otherProps
    } = props;
    return (
      <button
        ref={ref}
        type={type}
        className={classNames(
          "hover:bg-shade flex items-center justify-center whitespace-nowrap rounded-sm leading-none text-primary active:brightness-105 disabled:pointer-events-none disabled:opacity-70",
          {
            "gap-1.5 px-2 py-1 text-sm tracking-wide": size === "small",
            "gap-2 px-4 py-3 text-base": size === "normal",
          },
        )}
        {...otherProps}
      >
        {icon}
        {children}
      </button>
    );
  },
);
