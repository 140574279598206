import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolderOpen } from "@fortawesome/sharp-light-svg-icons";
import darkLogo from "_assets/images/revveon-logo-dark.svg";
import lightLogo from "_assets/images/revveon-logo-light.svg";
import { Scrollable } from "_shared/Scrollable/Scrollable";
import { Wrapper } from "_shared/Wrapper";
import { PrimaryButton } from "_shared/button/PrimaryButton";
import { Grow } from "_shared/flex/Grow";
import { LocalizedMessage } from "_shared/localization/LocalizedMessage";
import { useLocalize } from "_shared/localization/useLocalize";
import { H1Text } from "_shared/text/H1Text";
import { SmallText } from "_shared/text/SmallText";
import { useTheme } from "_shared/theme/theme";
import { Link } from "react-router-dom";

export function NotFound() {
  const theme = useTheme();
  const localize = useLocalize();
  return (
    <Grow>
      <Scrollable>
        <Wrapper>
          <div className="flex flex-col items-center gap-12">
            <Link to="/" className="self-start">
              <img
                src={theme === "light" ? lightLogo : darkLogo}
                alt={localize("revveon")}
              />
            </Link>
            <div className="flex flex-col items-center gap-8">
              <FontAwesomeIcon
                icon={faFolderOpen}
                className="text-9xl text-gray-80 dark:text-gray-42"
              />
              <div>
                <SmallText color="light">404</SmallText>
              </div>
              <div>
                <h1>
                  <H1Text>
                    <LocalizedMessage id="404" />
                  </H1Text>
                </h1>
              </div>
            </div>
            <Link to="/">
              <PrimaryButton>
                <LocalizedMessage id="404_go_home" />
              </PrimaryButton>
            </Link>
          </div>
        </Wrapper>
      </Scrollable>
    </Grow>
  );
}
