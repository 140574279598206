import { createContext, useContext } from "react";
import invariant from "tiny-invariant";
import { SurveyAnswers } from "./Survey.types";

interface SurveyContextValue {
  answers: Partial<SurveyAnswers>;
  setAnswers: (newAnswers: Partial<SurveyAnswers>) => void;
  back: () => void;
  next: () => void;
}

const SurveyContext = createContext<SurveyContextValue | undefined>(undefined);

export function useSurveyContext() {
  const context = useContext(SurveyContext);
  invariant(
    context !== undefined,
    "useSurveyContext() must be used within a SurveyContext.",
  );
  return context;
}

export const SurveyContextProvider = SurveyContext.Provider;
