import { ReactNode } from "react";
import { Link } from "react-router-dom";

interface Props {
  children?: ReactNode;
  to?: string;
}

export function Breadcrumb({ children, to }: Props) {
  if (to === undefined) return children;
  return (
    <>
      <Link to={to} className="text-primary hover:underline">
        {children}
      </Link>
      <span>/</span>
    </>
  );
}
