import { ReactNode, forwardRef } from "react";

interface Props {
  skip?: boolean;
  className?: string;
  children?: ReactNode;
}

export const IgnorePointer = forwardRef<HTMLDivElement, Props>(
  function IgnorePointer(props, ref) {
    const { skip, children, ...otherProps } = props;
    if (skip) return children;
    return (
      // Extra div ensures compatibility with `<Tooltip><IgnorePointer /></Tooltip>`
      <div ref={ref} {...otherProps}>
        <div className="pointer-events-none">{children}</div>
      </div>
    );
  },
);
