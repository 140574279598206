export const STRINGS = {
  revveon: "Revveon",

  header_account: "Account",
  header_subscription: "Subscription",
  header_sign_out: "Sign out",
  header_terms: "Terms",

  user_sign_in_title: "Sign in",
  user_sign_in_action: "Sign in",
  user_dont_have_an_account: "Don't have an account?",
  user_email: "Email",
  user_personal_email: "Personal email",
  user_personal_email_description:
    "Please use your personal email as you can have multiple startups on your Revveon account",
  user_password: "Password",
  user_sign_in_error_email: "Email is not recognised",
  user_sign_in_error_password: "Password is incorrect",
  user_forgot_password: "Forgot password?",
  user_show_password:
    "Show password as plain text. Warning: this will display your password on the screen.",
  user_create_account: "Create account",
  user_create_revveon_account: "Create your Revveon account",
  user_your_details: "Your details",
  user_first_name: "First name",
  user_last_name: "Last name",
  user_mobile_number: "Mobile number",
  user_phone_invalid: "Enter a valid phone number",
  user_we_take_security_seriously:
    "We take security seriously and use both your mobile number and your email to keep your account secure.",
  user_i_agree_to_terms: "I agree to the <a>Terms & Conditions</a>",
  user_error_phone_taken:
    "Mobile number is already registered. <a>Sign in?</a>",
  user_error_email_taken: "Email is already registered. <a>Sign in?</a>",
  user_verification_title: "Account verification",
  user_check_your_email_and_phone:
    "Check your email inbox and mobile text messages",
  user_email_verification_code: "Email verification code",
  user_phone_verification_code: "Mobile verification code",
  user_verify: "Verify",
  user_resend_verification_codes: `Resend codes{count, plural, 
    =0 {}
    other { (#)}
  }`,
  user_success: "Success",
  user_welcome_account_verified:
    "Welcome, {name}! Your account has been verified.",
  user_theme_dark: "Dark theme",
  user_theme_light: "Light theme",
  user_theme_system: "System theme",
  user_your_profile: "Your Profile",
  user_linkedin_username: "LinkedIn username",
  user_set_linkedin_profile: "Fetch my LinkedIn profile",
  user_set_linkedin_description:
    "To save you a heap of time, we'll fetch your details from LinkedIn. You can delete any information you'd rather not include.",
  user_set_linkedin_invalid: "Type your username, or paste your profile link",
  user_contribution_time: "Contribution Time",
  user_survey_description_0:
    "Revveon is free forever but works best when it knows and understands you. All we ask in return is that you take our Founder Survey once a year.",
  user_survey_description_1:
    "We then get to know you like a friend should, and will be able to be there for you — should the time come.",
  user_take_founder_survey: "Take the Founder Survey",
  user_reset_password: "Reset your password",
  user_new_password: "New password",
  user_password_too_common: "Password is too common",
  user_reset_password_description:
    "We'll send an email to verify your new password.",
  user_send_email: "Send email",
  user_check_your_email: "Check your email inbox",
  user_password_reset: "Your password has been reset",

  user_education_option_none:
    "I did not attend school, but instead began working",
  user_education_option_schoolDropout: "I left school to begin a job",
  user_education_option_school: "I started work after school",
  user_education_option_vocationalTraining:
    "I had vocational training after school",
  user_education_option_sixthForm: "I went to sixth-form college after school",
  user_education_option_bachelors: "I studied up to my Undergraduate",
  user_education_option_postgraduate: "I studied up to my Postgraduate",
  user_ethnicity_option_asian: "Asian",
  user_ethnicity_option_black: "Black",
  user_ethnicity_option_latino: "Latino",
  user_ethnicity_option_middleEastern: "Middle Eastern",
  user_ethnicity_option_mixed: "Mixed",
  user_ethnicity_option_white: "White",
  user_ethnicity_option_other: "Other",

  user_settings_title: "User Settings",
  user_settings_account: "Account",
  user_settings_profile: "Profile",
  user_settings_background: "Background",
  user_settings_education: "Education",
  user_settings_other: "Other",
  user_settings_user_title: "Title",
  user_settings_profile_picture: "Profile picture",
  user_settings_change_image: "Change image",
  user_settings_upload_image: "Upload image",
  user_settings_remove_image: "Remove image",
  user_settings_first_name: "First name",
  user_settings_last_name: "Last name",
  user_settings_email: "Email",
  user_settings_password: "Password",
  user_settings_password_reset: "Reset password",
  user_settings_date_of_birth: "Date of birth",
  user_settings_country_of_birth: "Country of birth",
  user_settings_country: "Where do you currently live?",
  user_settings_gender: "How do you identify?",
  user_settings_ethnicity: "Ethnicity",
  user_settings_education_mother: "Mother's education",
  user_settings_education_father: "Father's education",
  user_settings_languages_spoken: "Languages spoken",
  user_settings_education_level: "Highest level of education achieved",
  user_settings_education_level_option_primary: "Primary school education",
  user_settings_education_level_option_secondary: "Secondary school education",
  user_settings_education_level_option_higher: "Higher education",
  user_settings_education_level_option_other: "Other",
  user_settings_activities_societies: "Activities and societies",
  user_settings_relationship_status: "Relationship status",
  user_settings_relationship_single: "Single",
  user_settings_relationship_married: "Married",
  user_settings_relationship_other: "Other",
  user_settings_financial_status:
    "How long would you survive financially if you had no income whatsoever?",
  user_settings_emotional_status: "Emotional status",
  user_settings_cannot_change_email: "Cannot change email",
  user_settings_cannot_change_phone: "Cannot change mobile number",

  portfolio_title: "Portfolio",
  portfolio_edit: "Edit",
  portfolio_add: "Add Project",
  portfolio_untitled_project: "Untitled Project",
  portfolio_for_profit: "For profit",
  portfolio_not_for_profit: "Non-profit",
  portfolio_joining_to_now: "{JoiningDate, date, ::y} - now",
  portfolio_joining_to_leaving_date:
    "{JoiningDate, date, ::y} - {LeavingDate, date, ::y}",
  portfolio_company: "Company",
  portfolio_website: "Website",
  portfolio_start_date: "Start Date",
  portfolio_end_date: "End Date",
  portfolio_error_date_after_joining: "Enter a date after your joining date",
  portfolio_still_working_here: "Still working here?",
  portfolio_clear: "Clear",
  portfolio_result: "Result",
  portfolio_description: "Description",
  portfolio_industry: "Industry",
  portfolio_organization: "Organization",
  portfolio_financial_feel: "Financially, do you feel...?",
  portfolio_worse_off: "Worse off",
  portfolio_better_off: "Better off",
  portfolio_total_funding_received: "Total Funding Received",
  portfolio_last_round_funding: "Last Round of Funding",
  portfolio_number_employees: "Number of Employees",
  portfolio_project_deleted: "Project deleted",

  deck_submit: "Submit deck",
  deck_label_firstname: "First name",
  deck_label_lastname: "Last name",
  deck_label_email: "Email",
  deck_label_phone: "Contact number",
  deck_label_linkedin_profile: "Your LinkedIn profile",
  deck_label_startup_name: "Startup name",
  deck_label_headquarters: "Headquarters",
  deck_label_domain: "Company domain name",
  deck_label_investment_series: "Investment series",
  deck_label_pitch_deck____any_other_bits_: "Pitch deck (and any other bits)",
  deck_label_subject: "Ticket name",
  deck_label_content: "Ticket description",

  app_messages: "Messages",
  app_founderRewards: "Founder Rewards",
  app_portfolio: "Portfolio",
  app_founderInsights: "Founder Insights",
  app_communities: "Communities",
  app_jotting: "Jotting",
  app_bigProblem: "Big Problem",
  app_industrySurvey: "Industry Survey",
  app_marketLab: "Market Lab",
  app_funding: "Funding",
  app_salesFunnel: "Sales Funnel",
  app_crm: "CRM",
  app_recruitment: "Recruitment",

  survey_title: "Founder Survey",
  survey_question_first_name: "First name",
  survey_question_last_name: "Last name",
  survey_question_age_range: "How old are you?",
  survey_question_country: "Where do you currently live?",
  survey_question_country_label: "Country",
  survey_question_date_of_birth: "When is your date of birth?",
  survey_question_education: "Which of the following best describes you?",
  survey_question_ethnicity: "Which of the following best describes you?",
  survey_question_gender: "How do you identify?",
  survey_question_founder_status: "Which of the following apply to you?",
  survey_question_founder_status_option_startupTeamMember:
    "I have worked as a team member in a startup",
  survey_question_founder_status_option_aspiring:
    "I have plans to found a startup in the near future",
  survey_question_founder_status_option_founder:
    "I have founded at least one startup",
  survey_question_has_failed_startup:
    "Have you been a founder of a failed startup previously?",
  survey_question_reason_for_failure:
    "In your opinion, what is the biggest factor in your most recent startup being unsuccessful?",
  survey_question_founder_story: "Tell us your founding story",
  survey_question_founder_startup_count: "How many startups have you founded?",
  survey_question_pre_founder_startup_count:
    "How many startups did you work at before becoming a founder?",
  survey_question_exit_count:
    "How many exits have you created from your founded startups?",
  survey_question_has_been_member_of_sold_startup:
    "Have you ever been a team member in a startup that has been sold?",
  survey_question_founder_learnings:
    "As a founder of startups, what were your most insightful learnings during the process?",
  survey_question_minorities: "Do any of the following apply to you?",
  survey_question_minorities_option_immigrant: "An immigrant",
  survey_question_minorities_option_lgbtq: "Part of LGBTQ+",
  survey_question_minorities_option_singleParent: "A single parent",
  survey_question_minorities_option_registeredDisabled: "Registered disabled",
  survey_question_most_recent_startup_description:
    "Thinking of your most recent exit/startup, which of the following best describes your startup?",
  survey_question_most_recent_startup_description_option_consultancy:
    "A consultancy, service or skills startup",
  survey_question_most_recent_startup_description_option_software:
    "A software technology startup",
  survey_question_most_recent_startup_description_option_hardware:
    "A hardware technology startup",
  survey_question_most_recent_startup_description_option_hardwareAndSoftware:
    "A hardware & software startup",
  survey_question_most_recent_startup_cofounder_role:
    "Thinking of your most recent exit/startup, were your co-founder/co-founding team mostly:",
  survey_question_most_recent_startup_cofounder_role_option_technicalFounder:
    "A technical founder",
  survey_question_most_recent_startup_cofounder_role_option_technicalAndCommercial:
    "A technical founder with commercial experience",
  survey_question_most_recent_startup_cofounder_role_option_commercialFounder:
    "A commercial founder",
  survey_question_most_recent_startup_cofounder_role_option_noneOfAbove:
    "None of the above",
  survey_question_most_recent_startup_founder_count:
    "Thinking of your most recent exit/startup, were you...",
  survey_question_most_recent_startup_founder_count_option_1: "A solo founder",
  survey_question_most_recent_startup_founder_count_option_2:
    "1 of 2 co-founders",
  "survey_question_most_recent_startup_founder_count_option_3+":
    "Part of a larger co-founding team",
  survey_question_most_recent_startup_offering:
    "Thinking of your most recent exit/startup, what was/is the offering?",
  survey_question_most_recent_startup_offering_option_b2b: "B2B",
  survey_question_most_recent_startup_offering_option_b2c: "B2C",
  survey_question_most_recent_startup_offering_option_b2bAndB2c: "B2B & B2C",
  survey_question_most_recent_startup_role:
    "Thinking of your most recent exit/startup, were you mostly...",
  survey_question_most_recent_startup_role_option_technicalFounder:
    "A technical founder",
  survey_question_most_recent_startup_role_option_technicalAndCommercial:
    "A technical founder with commercial experience",
  survey_question_most_recent_startup_role_option_commercialFounder:
    "A commercial founder",
  survey_question_most_recent_startup_tried_vc_funding:
    "Thinking of your most recent exit/startup, did you try to raise venture capital funding?",
  survey_question_most_recent_startup_tried_vc_funding_option_yes: "Yes",
  survey_question_most_recent_startup_tried_vc_funding_option_noDidntNeed:
    "No - we didn't need external funding",
  survey_question_most_recent_startup_tried_vc_funding_option_noDidntWant:
    "No - we didn't want to work with VC firms",
  survey_question_most_recent_startup_tried_vc_funding_option_noNotAnOption:
    "No - we didn't think it was an option",
  survey_question_most_recent_startup_vc_funding_market_feedback:
    "While trying to raise VC funding for your most recent exit/startup, which of the following feedback did you receive about the market?",
  survey_question_most_recent_startup_vc_funding_market_feedback_option_tooSmall:
    "The opportunity is not big enough",
  survey_question_most_recent_startup_vc_funding_market_feedback_option_tooEarly:
    "You are too early to market",
  survey_question_most_recent_startup_vc_funding_market_feedback_option_competitiveDifferentiation:
    "No, or weak, competitive differentiation",
  survey_question_most_recent_startup_vc_funding_market_feedback_option_unfavourableTrends:
    "Unfavourable macroeconomic or regulatory trends",
  survey_question_most_recent_startup_vc_funding_market_feedback_option_existingCompany:
    "An existing, more established company could do it easily",
  survey_question_most_recent_startup_vc_funding_market_feedback_option_crowdedSpace:
    "This is a crowded space",
  survey_question_is_most_recent_startup_vc_funded:
    "Were you successful in your attempts to raise venture capital for your most recent exit/startup?",
  survey_question_most_recent_startup_vc_funding_team_feedback:
    "While trying to raise VC funding for your most recent exit/startup, which of the following feedback did you receive about your team?",
  survey_question_most_recent_startup_vc_funding_team_feedback_option_dynamics:
    "Issue with founder or team dynamics",
  survey_question_most_recent_startup_vc_funding_team_feedback_option_missingKeyPerson:
    "Missing a key person",
  survey_question_most_recent_startup_vc_funding_team_feedback_option_notMissionDriven:
    "Founders aren't mission-driven",
  survey_question_most_recent_startup_vc_funding_team_feedback_option_lackFocus:
    "Lack of focus",
  survey_question_most_recent_startup_vc_funding_team_feedback_option_behaviourOrPersonality:
    "Personality/behavioural red flags",
  survey_question_most_recent_startup_vc_funding_team_feedback_option_dishonesty:
    "Dishonesty",
  survey_question_most_recent_startup_vc_funding_team_feedback_option_distributedTeam:
    "Distributed team",
  survey_question_most_recent_startup_vc_funding_team_feedback_option_negativeReferences:
    "Negative references",
  survey_question_most_recent_startup_vc_funding_team_feedback_option_founderNotCompelling:
    "CEO or founder isn't compelling",
  survey_question_most_recent_startup_vc_funding_fundraising_feedback:
    "While trying to raise VC funding for your most recent exit/startup, which of the following feedback did you receive about fundraising issues?",
  survey_question_most_recent_startup_vc_funding_fundraising_feedback_option_capTable:
    "Problematic cap table",
  survey_question_most_recent_startup_vc_funding_fundraising_feedback_option_presentationMaterials:
    "Bad presentation materials",
  survey_question_most_recent_startup_vc_funding_fundraising_feedback_option_valuationIssues:
    "Valuation issues",
  survey_question_most_recent_startup_vc_funding_fundraising_feedback_option_undesirableTerms:
    "Undesirable terms",
  survey_question_most_recent_startup_vc_funding_fundraising_feedback_option_coInvestorDynamics:
    "Co-investor dynamics",
  survey_question_most_recent_startup_vc_funding_fundraising_feedback_option_fundraisingTactics:
    "Fundraising tactics",
  survey_question_most_recent_startup_vc_funding_fundraising_feedback_option_findLead:
    "Needed to find a lead",
  survey_question_most_recent_startup_vc_funding_fundraising_feedback_option_coldPitch:
    "Unpersonalised cold pitch",
  survey_question_most_recent_startup_vc_funding_business_model_feedback:
    "While trying to raise VC funding for your most recent exit/startup, which of the following feedback did you receive about the business model?",
  survey_question_most_recent_startup_vc_funding_business_model_feedback_option_noTraction:
    "Not enough traction",
  survey_question_most_recent_startup_vc_funding_business_model_feedback_option_dislikeBusinessModel:
    "Dislike the business model",
  survey_question_most_recent_startup_vc_funding_business_model_feedback_option_dislikeMarketPlan:
    "Dislike the go-to-market plan",
  survey_question_most_recent_startup_vc_funding_business_model_feedback_option_supplyChainConcerns:
    "Supply chain concerns",
  survey_question_most_recent_startup_vc_funding_business_model_feedback_option_notScalable:
    "Not a scalable model",
  survey_question_most_recent_startup_vc_funding_business_model_feedback_option_unclearValueProposition:
    "Unclear value proposition",
  survey_question_most_recent_startup_vc_funding_business_model_feedback_option_weakMetrics:
    "Weak metrics/unit economics",
  survey_question_most_recent_startup_vc_funding_investor_specific_feedback:
    "While trying to raise VC funding for your most recent exit/startup, which of the following feedback did you receive about investor specific issues?",
  survey_question_most_recent_startup_vc_funding_investor_specific_feedback_option_geographicArea:
    "Not in our geographic area",
  survey_question_most_recent_startup_vc_funding_investor_specific_feedback_option_notExciting:
    "It's just not something I can get excited about",
  survey_question_most_recent_startup_vc_funding_investor_specific_feedback_option_tooCapitalIntensive:
    "Too capital-intensive for us",
  survey_question_most_recent_startup_vc_funding_investor_specific_feedback_option_tooEarly:
    "Too early for us",
  survey_question_most_recent_startup_vc_funding_investor_specific_feedback_option_tooLate:
    "Too late for us",
  survey_question_most_recent_startup_vc_funding_investor_specific_feedback_option_roundSize:
    "Too small/big a round",
  survey_question_most_recent_startup_vc_funding_investor_specific_feedback_option_convincePartnership:
    "I couldn't convince my partnership",
  survey_question_most_recent_startup_vc_funding_investor_specific_feedback_option_similarCompanyFailure:
    "I've seen a similar company try this and fail",
  survey_question_most_recent_startup_vc_funding_investor_specific_feedback_option_unreasonableVcExpectations:
    "Unreasonable expectations around the VC's role",
  survey_question_most_recent_startup_vc_funding_investor_specific_feedback_option_competitivePortfolioCompany:
    "Competitive with a portfolio company",
  survey_question_most_recent_startup_vc_funding_product_feedback:
    "While trying to raise VC funding for your most recent exit/startup, which of the following feedback did you receive about the product or tech issues?",
  survey_question_most_recent_startup_vc_funding_product_feedback_option_notEnoughTech:
    "Not enough tech",
  survey_question_most_recent_startup_vc_funding_product_feedback_option_notEnoughProduct:
    "Not enough product",
  survey_question_most_recent_startup_vc_funding_product_feedback_option_productDysfunction:
    "Product dysfunction",
  survey_question_most_recent_startup_vc_funding_product_feedback_option_licensingIssues:
    "Licensing or IP issues",
  survey_question_funding_rounds:
    "At which stages did your most recent exit/startup take in funding?",
  survey_question_funding_duration_months_by_round:
    "How long did it take to raise your...",
  survey_question_venture_capital_importance:
    "How important do you feel venture capital is in building a startup to its exit?",
  survey_question_venture_capital_importance_to_startup:
    "How important was venture capital in building your most recent startup toward exit?",
  survey_question_venture_capital_importance_to_startup_critical:
    "Critical to our success",
  survey_question_venture_capital_importance_to_startup_did_not_impact:
    "Did not impact our success",
  survey_question_vc_personal_interests_protected:
    "Overall, how much do you feel the VCs have your personal interests protected as a founder?",
  survey_question_vc_personal_interests_protected_extremely_protected:
    "Extremely protected",
  survey_question_vc_personal_interests_protected_not_protected:
    "Not protected at all",
  survey_question_predicted_personal_exit_range:
    "What range do you expect your personal exit to land in when exiting? (or FX equivalent)",
  survey_question_achieved_personal_exit_range:
    "Approximately how much was your personal exit? (or FX equivalent)",
  "survey_question_personal_exit_range_option_<5": "Less than $5 million",
  "survey_question_personal_exit_range_option_5-10": "$5 to $10 million",
  "survey_question_personal_exit_range_option_11-20": "$11 to $20 million",
  "survey_question_personal_exit_range_option_21-30": "$21 to $30 million",
  "survey_question_personal_exit_range_option_31-40": "$31 to $40 million",
  "survey_question_personal_exit_range_option_41-50": "$41 to $50 million",
  "survey_question_personal_exit_range_option_51-100": "$51 to $100 million",
  "survey_question_personal_exit_range_option_101-200": "$101 to $200 million",
  "survey_question_personal_exit_range_option_201-500": "$201 to $500 million",
  "survey_question_personal_exit_range_option_>500": "$500 million +",
  survey_question_had_vc_partner_help_sales_and_revenue:
    "Did your VC partners assist with early sales and revenue generating opportunities such as setting up meetings, or gaining reference sites, etc.?",
  survey_question_had_vc_partner_help_hiring:
    "Did your VC partners help with hiring early team roles?",
  survey_question_had_vc_partner_help_funding_rounds:
    "Did your VC partners have an active role in building funding rounds (as both lead or follow investor)?",
  survey_question_vc_investor_support:
    "Beyond investment, how much do you feel your VC investors supported your company?",
  survey_question_vc_investor_support_not_supportive: "Not supportive at all",
  survey_question_vc_investor_support_extremely_supportive:
    "Extremely supportive",
  survey_question_was_supported_post_exit:
    "Did your venture capital investors support you after the exit with any changes to your life?",
  survey_question_would_accept_care_future_exit:
    "Would you accept care after a future exit?",
  survey_question_venture_capital_trust:
    "Having exited your startup as a founder, given the whole experience, how much do you trust the venture capital market?",
  survey_question_venture_capital_trust_strong_distrust: "Strong distrust",
  survey_question_venture_capital_trust_complete_trust: "Complete trust",
  survey_question_is_protection_of_value_important:
    "Is fairness and protection of value important in your personal engagement with a startup as a founder?",
  survey_question_is_satisfied_with_exit_earnings:
    "Do you feel the earnings from your exit were fair and representative of your efforts?",
  survey_question_expected_fair_exit_earnings:
    "What do you think would have been fair?",
  survey_question_mental_health_post_exit:
    "After exiting your startup, how was your mental health impacted?",
  survey_question_mental_health_during_startup_build:
    "While building your startup, how much was your mental health impacted?",
  survey_question_mental_health_not_at_all: "Not at all",
  survey_question_mental_health_very_severely: "Very severely",
  survey_question_equity_holding_on_exit:
    "What was your equity holding on exit?",
  survey_question_founder_advice:
    "How would you describe being a founder to someone considering becoming a founder?  Consider how being a founder made you feel.",
  survey_question_would_pay_it_forward:
    "If a VC fund had invested in you, assisted with sales and other important support areas, would you 'pay it forward' by investing funds from your exit back into the fund, to sustain it?",
  survey_question_pay_it_forward_hesitance:
    "What might make you hesitant to 'pay it forward?'",
  survey_question_pay_it_forward_percentage:
    "What percentage of your exit would you pay forward into a fund to invest in the next generation of founders? (Think of this as another startup from yourself)",
  survey_question_would_like_pay_it_forward_first_approach:
    "If you had contributed to a fund by paying it forward from your previous exit, would you like to approach that venture fund first ahead of the wider market?",
  survey_question_would_refound: "Would you go on to re-found another startup?",
  survey_question_has_already_refounded: "Have you already re-founded?",
  survey_question_would_be_angel_investor:
    "Having achieved a level of liquidity, would you invest your network and capital as an angel investor?",
  survey_question_has_been_member_of_exited_startup:
    "Have you ever been a part of a startup that went through an exit?",
  survey_question_aspiring_founder_self_description:
    "Which of these do you feel apply to yourself?",
  survey_question_aspiring_founder_self_description_option_nervous:
    "I am nervous I haven't been a founder before",
  survey_question_aspiring_founder_self_description_option_connectWithFundTeam:
    "I do not know how to connect with a fund team/partner",
  survey_question_aspiring_founder_self_description_option_pitchIdea:
    "I do not know how to explain or pitch my idea",
  survey_question_aspiring_founder_self_description_option_stealIdea:
    "I am worried someone will steal my idea",
  survey_question_aspiring_founder_self_description_option_investmentProcess:
    "I do not know how the investment process works",
  survey_question_aspiring_founder_self_description_option_valueIdea:
    "I am unsure of how to value my idea",
  survey_question_aspiring_founder_startup_learnings:
    "What have you learned from working at a startup?",
  survey_question_responsibility_time_split:
    "What percentage of your time do you spend on each of the following responsibilities?",
  survey_question_responsibility_option_funding: "Raising funding",
  survey_question_responsibility_option_development: "Product development",
  survey_question_responsibility_option_sales: "Sales",
  survey_question_responsibility_option_leadership: "Leading the team",
  survey_question_responsibility_option_recruitment: "Recruitment",
  survey_question_responsibility_option_admin: "Admin",
  survey_question_responsibility_option_other: "Other",
  survey_question_responsibility_priority:
    "Which of these responsibilities are a priority for you as a founder?",
  survey_question_responsibility_priority_hint:
    "Rank by priority, with 1 being the most important",
  survey_question_isolation_during_startup_build:
    "Did you feel isolated during the build of your startup?",
  survey_question_isolation_during_startup_build_option_yesUnsupported:
    "Yes, I felt isolated and unsupported",
  survey_question_isolation_during_startup_build_option_yesTeamAndInvestor:
    "Yes, but my team and/or investor tried to help",
  survey_question_isolation_during_startup_build_option_noTeam:
    "No, I felt supported by my team",
  survey_question_isolation_during_startup_build_option_noInvestors:
    "No, I felt supported by my investors",
  survey_question_isolation_during_startup_build_option_noTeamAndInvestors:
    "No, I felt supported by both my team & investors",
  survey_question_aspiring_founder_biggest_challenge:
    "What was your biggest challenge when making decisions as a founder?",
  survey_question_not_founder_self_description:
    "Which of these best describe you or your company?",
  survey_question_not_founder_self_description_option_consultant: "Consultant",
  survey_question_not_founder_self_description_option_venturePartnerSales:
    "Venture partner sales / Lead generator",
  survey_question_not_founder_self_description_option_marketingSpecialist:
    "Marketing specialist",
  survey_question_not_founder_self_description_option_corporateFinanceService:
    "Corporate finance service",
  survey_question_would_use_central_platform:
    "Would you use a central platform to coordinate with a community of skills, services and capital sources?",
  survey_question_is_opted_in_to_results_email:
    "Are you interested in receiving an infographic with the survey results?",
  survey_question_marketing_opt_in: "Would you like to hear about...?",
  survey_question_marketing_opt_in_option_kavedonUpdates: "Kavedon updates",
  survey_question_marketing_opt_in_option_startupNews: "Startup news",
  survey_question_marketing_opt_in_option_events: "Events",
  survey_question_marketing_opt_in_option_fundingOpportunities:
    "Funding opportunities",
  survey_question_is_aspiring_mentor:
    "Having been through a full cycle of a startup to exit, would you become a mentor to other founders?",
  survey_end_title: "Thank you",
  survey_end_description: "Revveon is now stronger thanks to you!",
  survey_end_continue: "Continue to Revveon",

  market_lab_products: "Products",

  input_other: "Other",
  input_prefer_not_to_say: "Prefer not to say",
  input_error_date_before_today: "Enter a date before today",
  input_error_required_generic: "Required",
  input_error_required_select: "Select an option",
  input_error_required_other: "Enter other option",
  input_error_required_date: "Enter a date",
  input_error_email_invalid: "Enter an email, like name@example.com",
  input_error_whole_number: "Enter a whole number, using only the digits 0-9",
  input_error_url: "Enter a website, like https://www.example.com",
  input_slider_min: "Minimum: {ariaLabel}",
  input_slider_max: "Maximum: {ariaLabel}",
  input_rating_unimportant: "Unimportant",
  input_rating_important: "Important",
  input_list_builder_available: "Available",
  input_list_builder_selected: "Selected",
  input_list_builder_button_up: "Move up",
  input_list_builder_button_down: "Move down",
  input_radiogroup_yes: "Yes",
  input_radiogroup_no: "No",
  input_radiogroup_unsure: "Unsure",
  input_checkboxgroup_all_that_apply: "Select all that apply",
  input_file_upload_file: "Upload file",

  button_back: "Back",
  button_next: "Next",
  button_skip: "Skip",
  button_open: "Open",
  button_close: "Close",
  button_cancel: "Cancel",
  button_submit: "Submit",
  button_select: "Select",
  button_deselect: "Deselect",
  button_save: "Save",
  button_delete: "Delete",
  button_undo: "Undo",

  pagination_steps: "Steps",

  feature_coming_soon: "Coming soon",

  startups_title: "Startups",
  startups_founder_toolkit: "Founder Toolkit",
  startups_notification_count: `{count, plural,
    one {# notification}
    other {# notifications}
  }`,
  startups_survey_prompt_title: "Take the {now, time, ::y} Founder Survey",
  startups_survey_prompt_description: "Gain 1 year of free access to Revveon",
  startups_survey_prompt_take_survey_button: "Take the survey",
  startups_add: "Add startup",
  startups_add_add_portfolio_first:
    "You must add a <a>Portfolio</a> project first",
  startups_add_choose_portfolio_project: "Choose a Portfolio project",
  startups_add_already_promoted:
    "This project already has an associated startup",
  startups_add_label_invite_code_sent: "An invite code has been emailed to you",
  startups_add_label_invite_code: "Invite code",
  startups_add_resend_code: "Resend code",
  add_startup_label_address: "Business address",
  startups_add_label_email: "Business email",
  startups_add_label_phone: "Business phone",
  startups_add_label_startup_notes: "Notes",

  "404": "There's nothing here...",
  "404_go_home": "Go home",
  error: "Something went wrong",
  loading: "Loading...",
};
