import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/sharp-light-svg-icons";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Breadcrumb } from "_shared/Breadcrumbs/Breadcrumb";
import { Breadcrumbs } from "_shared/Breadcrumbs/Breadcrumbs";
import { Scrollable } from "_shared/Scrollable/Scrollable";
import { Toast } from "_shared/Toast/Toast";
import { useToast } from "_shared/Toast/ToastContext";
import { Wrapper } from "_shared/Wrapper";
import { useAuth, useUser } from "_shared/auth/AuthContext";
import { FlatButton } from "_shared/button/FlatButton";
import { SecondaryButton } from "_shared/button/SecondaryButton";
import { Grow } from "_shared/flex/Grow";
import { LocalizedMessage } from "_shared/localization/LocalizedMessage";
import { H1Text } from "_shared/text/H1Text";
import { SmallText } from "_shared/text/SmallText";
import { EditProject } from "founder/portfolio/EditProject";
import { Project } from "founder/portfolio/Portfolio.types";
import { TimelineEvent } from "founder/portfolio/TimelineEvent";
import { ViewProject } from "founder/portfolio/ViewProject";
import { usePortfolioProjectsQuery } from "founder/portfolio/usePortfolioProjectsQuery";
import { ComponentProps, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import invariant from "tiny-invariant";

export function Portfolio() {
  const navigate = useNavigate();
  const { projectId } = useParams();
  const { showToast } = useToast();
  const user = useUser();
  const { post } = useAuth();
  const queryClient = useQueryClient();
  const queryKey = ["/get-portfolio-list", user.email];

  const query = usePortfolioProjectsQuery();

  const [pendingProjectEdit, setPendingProjectEdit] = useState<
    Project | undefined
  >(projectId === undefined ? undefined : {});
  const projectsById = {
    ...query.data,
  };

  const editMutation = useMutation({
    mutationFn: () => {
      return post(
        isNewProject ? "add-new-portfolio" : "update-portfolio",
        currentProject,
      );
    },
    onSuccess: () => {
      query.refetch();
      reset();
    },
    onError,
  });

  const deleteMutation = useMutation({
    mutationFn: (portfolio_id: string) => {
      return post("delete-portfolio", { portfolio_id });
    },
    onError,
  });

  function reset() {
    setPendingProjectEdit(undefined);
    navigate("/founder/portfolio");
  }

  function onError() {
    showToast((props) => (
      <Toast {...props} duration={Infinity}>
        <LocalizedMessage id="error" />
      </Toast>
    ));
  }

  function onDelete() {
    invariant(projectId);
    const projectIdToDelete = projectId;
    // Ensure that, although the delete request has not been sent yet, the project is removed visually from the Portfolio
    queryClient.setQueryData(
      queryKey,
      (oldProjectsById: Record<string, Project>) => {
        const tempObject = { ...oldProjectsById };
        delete tempObject[projectIdToDelete];
        return tempObject;
      },
    );
    reset();
    showToast((props: Pick<ComponentProps<typeof Toast>, "onClose">) => {
      let isCancelled = false;
      return (
        <Toast
          {...props}
          duration={5000}
          onClose={async () => {
            props.onClose();
            if (!isCancelled)
              await deleteMutation.mutateAsync(projectIdToDelete);
            // Bring back the project that we optimistically deleted from the query cache if isCancelled is true
            query.refetch();
          }}
          closeButton={
            <FlatButton
              onClick={() => {
                isCancelled = true;
              }}
            >
              <LocalizedMessage id="button_undo" />
            </FlatButton>
          }
        >
          <SmallText color="inherit">
            <LocalizedMessage id="portfolio_project_deleted" />
          </SmallText>
        </Toast>
      );
    });
  }

  const currentProject = {
    LeavingDate: "", // Endpoint requires an empty string, not undefined
    ...(projectId ? query.data?.[projectId] : {}),
    ...pendingProjectEdit,
  };
  if (projectId) projectsById[projectId] = currentProject;
  const isNewProject =
    projectId === undefined && pendingProjectEdit !== undefined;

  return (
    <Grow>
      <Scrollable>
        <Wrapper>
          <div className="flex flex-col gap-8">
            <div className="flex items-end justify-between gap-8">
              <div className="flex flex-col gap-4">
                <Breadcrumbs>
                  <Breadcrumb to="/">
                    <LocalizedMessage id="startups_founder_toolkit" />
                  </Breadcrumb>
                </Breadcrumbs>
                <h1>
                  <H1Text>
                    <LocalizedMessage id="portfolio_title" />
                  </H1Text>
                </h1>
              </div>
            </div>
            <div className="flex">
              <div className="flex min-w-0 flex-1 flex-col">
                <TimelineEvent
                  title={
                    <SecondaryButton
                      onClick={() => {
                        navigate("/founder/portfolio");
                        setPendingProjectEdit({});
                      }}
                    >
                      <FontAwesomeIcon icon={faPlus} />{" "}
                      <LocalizedMessage id="portfolio_add" />
                    </SecondaryButton>
                  }
                />
                {isNewProject ? (
                  <ViewProject isActive project={currentProject} />
                ) : null}
                {Object.entries(projectsById)
                  .sort(([_, projectA], [__, projectB]) => {
                    if (!projectA.JoiningDate) return -1;
                    if (!projectB.JoiningDate) return 1;
                    return projectB.JoiningDate.localeCompare(
                      projectA.JoiningDate,
                    );
                  })
                  .map(([id, project]) => {
                    return (
                      <ViewProject
                        key={id}
                        project={project}
                        onEdit={() => {
                          navigate(
                            `/founder/portfolio/${project.portfolio_id}`,
                          );
                          setPendingProjectEdit({});
                        }}
                        isActive={id === projectId}
                      />
                    );
                  })}
              </div>
              <div className="w-1/3 shrink-0">
                {pendingProjectEdit !== undefined ? (
                  <EditProject
                    project={currentProject}
                    onChange={setPendingProjectEdit}
                    editMutation={editMutation}
                    onCancel={reset}
                    onDelete={isNewProject ? undefined : onDelete}
                  />
                ) : null}
              </div>
            </div>
          </div>
        </Wrapper>
      </Scrollable>
    </Grow>
  );
}
