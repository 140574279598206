import classNames from "classnames";
import { ReactNode } from "react";

interface Props {
  children?: ReactNode;
  readOnly?: boolean;
}

export function InputWrapper(props: Props) {
  const { readOnly, ...otherProps } = props;
  return (
    <div
      className={classNames(
        "flex items-stretch divide-x divide-gray-80 rounded-sm border border-gray-80 focus-within:outline focus-within:outline-2 focus-within:-outline-offset-1 focus-within:outline-blue-62 data-[invalid]:border-red-50 dark:divide-gray-42 dark:border-gray-42 dark:data-[invalid]:border-yellow-57",
        readOnly
          ? "pointer-events-none bg-gray-94 dark:bg-gray-27"
          : "bg-white dark:bg-gray-24",
      )}
      onClick={(e) => e.currentTarget.querySelector("input")?.focus()}
      {...otherProps}
    />
  );
}
