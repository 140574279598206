import { Tooltip } from "_shared/Tooltip/Tooltip";
import { LocalizedMessage } from "_shared/localization/LocalizedMessage";

interface Props {
  children?: string;
}

export function NotificationBadge(props: Props) {
  const { children } = props;
  return (
    <Tooltip
      content={
        <LocalizedMessage
          id="startups_notification_count"
          values={{ count: children }}
        />
      }
    >
      <div className="flex aspect-square w-6 items-center justify-center rounded-full bg-primary text-xs text-white">
        {children}
      </div>
    </Tooltip>
  );
}
