import * as Form from "@radix-ui/react-form";
import { Scrollable } from "_shared/Scrollable/Scrollable";
import { Toast } from "_shared/Toast/Toast";
import { useToast } from "_shared/Toast/ToastContext";
import { Wrapper } from "_shared/Wrapper";
import { useAuth } from "_shared/auth/AuthContext";
import { InlineButton } from "_shared/button/InlineButton";
import { PrimaryButton } from "_shared/button/PrimaryButton";
import { Field } from "_shared/field/Field";
import { InvalidEmailMessage } from "_shared/field/InvalidEmailMessage";
import { RequiredFormMessage } from "_shared/field/RequiredFormMessage";
import { Grow } from "_shared/flex/Grow";
import { TextInput } from "_shared/input/TextInput";
import { LocalizedMessage } from "_shared/localization/LocalizedMessage";
import { H1Text } from "_shared/text/H1Text";
import { LabelText } from "_shared/text/LabelText";
import { SmallText } from "_shared/text/SmallText";
import { Status } from "_shared/utils";
import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

export function SignIn() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { signIn } = useAuth();
  const { showToast } = useToast();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [status, setStatus] = useState<Status>("idle");
  const [errorField, setErrorField] = useState<"email" | "password">();

  return (
    <Grow>
      <Scrollable>
        <Wrapper>
          <div className="flex flex-col items-center gap-16">
            <h1>
              <H1Text>
                <LocalizedMessage id="user_sign_in_title" />
              </H1Text>
            </h1>
            <hr className="w-24 border-gray-42" />
            <Form.Root
              className="flex w-80 flex-col gap-6"
              onSubmit={async (e) => {
                e.preventDefault();
                setStatus("pending");
                try {
                  await signIn({
                    email,
                    password,
                  });
                  setStatus("success");
                  navigate(state?.continue ?? "/", { replace: true });
                } catch (error) {
                  if (!(error instanceof Error)) throw error;
                  switch (error.message) {
                    case "email_not_registered":
                      setErrorField("email");
                      break;
                    case "password_incorrect. ": // Yes, back-end has a typo: ". "
                      setErrorField("password");
                      break;
                    default:
                      showToast((props) => (
                        <Toast {...props} duration={Infinity}>
                          <LocalizedMessage id="error" />
                        </Toast>
                      ));
                  }
                  setStatus("error");
                }
              }}
              onChange={() => {
                setStatus("idle");
                setErrorField(undefined);
              }}
            >
              <Field name="email" serverInvalid={errorField === "email"}>
                <Form.Label>
                  <LabelText>
                    <LocalizedMessage id="user_email" />
                  </LabelText>
                </Form.Label>
                <Form.Control asChild>
                  <TextInput
                    type="email"
                    autoComplete="username"
                    required
                    onChange={setEmail}
                    value={email}
                  />
                </Form.Control>
                <InvalidEmailMessage />
                {errorField === "email" ? (
                  <SmallText color="warning">
                    <LocalizedMessage id="user_sign_in_error_email" />
                  </SmallText>
                ) : null}
                <RequiredFormMessage />
              </Field>
              <Field name="password" serverInvalid={errorField === "password"}>
                <Form.Label>
                  <LabelText>
                    <LocalizedMessage id="user_password" />
                  </LabelText>
                </Form.Label>
                <Form.Control asChild>
                  <TextInput
                    type="password"
                    autoComplete="current-password"
                    required
                    onChange={setPassword}
                    value={password}
                  />
                </Form.Control>
                <RequiredFormMessage />
                {errorField === "password" ? (
                  <SmallText color="warning">
                    <LocalizedMessage id="user_sign_in_error_password" />
                  </SmallText>
                ) : null}
                <Link
                  to="/user/reset-password"
                  state={{ email }}
                  className="self-start"
                >
                  <SmallText color="inherit">
                    <InlineButton>
                      <LocalizedMessage id="user_forgot_password" />
                    </InlineButton>
                  </SmallText>
                </Link>
              </Field>
              <Form.Submit asChild>
                <PrimaryButton status={status}>
                  <LocalizedMessage id="user_sign_in_action" />
                </PrimaryButton>
              </Form.Submit>
              <div>
                <SmallText color="light">
                  <LocalizedMessage id="user_dont_have_an_account" />
                </SmallText>
                &nbsp;
                <Link to="/user/register">
                  <SmallText color="inherit">
                    <InlineButton>
                      <LocalizedMessage id="user_create_account" />
                    </InlineButton>
                  </SmallText>
                </Link>
              </div>
            </Form.Root>
          </div>
        </Wrapper>
      </Scrollable>
    </Grow>
  );
}
