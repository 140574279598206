import classNames from "classnames";
import { createDefaults } from "./defaults";

interface Props {
  stroke?: "solid" | "gradient";
  svg: string;
}

export const [CustomIconDefaults, useCustomIconDefaults] =
  createDefaults<Props>();

/**
 * Prefer `FontAwesomeIcon`, unless the designs specify a custom icon.
 */
export function CustomIcon(props: Props) {
  const { stroke = "solid", svg } = useCustomIconDefaults(props);
  return (
    <div
      className={classNames(
        "inline-block aspect-square w-[1em]",
        "align-[-0.125em]", // Match FontAwesomeIcon
        {
          "bg-current": stroke === "solid",
          "bg-gradient-to-tr from-purple-44 to-purple-36 hover:bg-white dark:from-purple-55 dark:to-white":
            stroke === "gradient",
        },
      )}
      style={{
        WebkitMaskImage: `url(${svg})`,
        maskImage: `url(${svg})`,
        WebkitMaskSize: "contain",
        maskSize: "contain",
      }}
    />
  );
}
