import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGripLinesVertical } from "@fortawesome/sharp-light-svg-icons";
import { Root, Thumb } from "@radix-ui/react-switch";
import { AriaLabelableProps } from "_shared/a11y";
import classNames from "classnames";
import { forwardRef } from "react";

type Props = AriaLabelableProps & {
  value?: boolean;
  onChange?: (value: boolean) => void;
};

export const Switch = forwardRef<HTMLButtonElement, Props>(
  function Switch(props, ref) {
    const { value, onChange, ...otherProps } = props;
    return (
      <Root
        {...otherProps}
        ref={ref}
        className={classNames(
          "group/switch relative flex w-14 items-center rounded-full hover:brightness-95 active:brightness-105",
          "focus-visible:outline focus-visible:outline-2 focus-visible:outline-blue-62",
        )}
        checked={value}
        onCheckedChange={onChange}
      >
        <div className="absolute flex w-full px-[15%]">
          <div className="h-1.5 grow rounded-full bg-gray-80 transition-colors duration-300 hover:brightness-95 active:brightness-105 group-aria-checked/switch:bg-primary dark:bg-gray-24 dark:group-aria-checked/switch:bg-primary" />
        </div>
        <Thumb
          className={classNames(
            "relative flex aspect-square w-1/2 items-center justify-center rounded-full bg-white text-xs text-gray-42 shadow transition-transform duration-300",
            "dark:bg-gray-42 dark:text-white",
            "data-[state=checked]:translate-x-full",
          )}
        >
          <FontAwesomeIcon icon={faGripLinesVertical} />
        </Thumb>
      </Root>
    );
  },
);
