import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical } from "@fortawesome/sharp-light-svg-icons";
import { Popover } from "_shared/Popover/Popover";
import { IconButton } from "_shared/button/IconButton";
import { ReactNode, Ref, forwardRef } from "react";

interface Props {
  children: ReactNode;
}

export const KebabMenu = forwardRef(function KebabMenu(
  props: Props,
  ref: Ref<HTMLButtonElement>,
) {
  const { children } = props;
  return (
    <Popover content={children} ref={ref}>
      <IconButton size="small">
        <FontAwesomeIcon icon={faEllipsisVertical} />
      </IconButton>
    </Popover>
  );
});
