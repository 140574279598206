import { ReactNode } from "react";

interface SideNavProps {
  children?: ReactNode;
}

export function SideNav(props: SideNavProps) {
  const { children } = props;
  return <div className="sticky top-8 flex flex-col gap-2">{children}</div>;
}
