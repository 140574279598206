import classNames from "classnames";
import {
  CSSProperties,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from "react";

type Direction = "forward" | "back";

interface Props {
  pages: ReactNode[];
  /**
   * `PageView` is fixed-width. Its height grows to fit its children.
   */
  width: CSSProperties["width"];
  pageIndex: number;
  back: () => void;
  next: () => void;
  animationDurationMs: number;
  animationState?: Direction;
}

export function usePageView({
  initialPageIndex = 0,
  animationDurationMs = 300,
} = {}): Omit<Props, "pages" | "width"> {
  const [pageIndex, setPageIndex] = useState(initialPageIndex);
  const [animationState, setAnimationState] = useState<Direction>();
  const back = useCallback(() => {
    setPageIndex((i) => Math.max(0, i - 1));
    setAnimationState("back");
  }, []);
  const next = useCallback(() => {
    setPageIndex((i) => i + 1);
    setAnimationState("forward");
  }, []);

  useEffect(() => {
    if (!animationState) return;
    const timer = setTimeout(() => {
      setAnimationState(undefined);
    }, animationDurationMs);
    return () => window.clearTimeout(timer);
  }, [animationState]);

  return {
    pageIndex,
    back,
    next,
    animationDurationMs,
    animationState,
  };
}

export function PageView(props: Props) {
  const { pages, width, pageIndex, animationState, animationDurationMs } =
    props;

  return (
    <div className="flex justify-center self-stretch overflow-clip">
      <div style={{ width }}>
        <div
          className={classNames("flex", {
            "-translate-x-1/3": animationState === undefined,
            "animate-page-forward": animationState === "forward",
            "animate-page-back": animationState === "back",
          })}
          style={{
            animationDuration: `${animationDurationMs}ms`,
            width: `calc(${width} * 3)`,
          }}
        >
          {/* Outro the previous page (when going forward) */}
          <div
            className={classNames("shrink-0", {
              "animate-pop-out": animationState === "forward",
            })}
            style={{
              animationDuration: `${animationDurationMs}ms`,
              width,
              transformOrigin: "left",
            }}
          >
            {animationState === "forward" ? pages[pageIndex - 1] : null}
          </div>
          {/* Intro the current page */}
          <div
            className={classNames("shrink-0", {
              "animate-pop-in": animationState !== undefined,
            })}
            style={{
              animationDuration: `${animationDurationMs}ms`,
              width,
              transformOrigin: animationState === "forward" ? "right" : "left",
            }}
          >
            {pages[pageIndex]}
          </div>
          {/* Outro the previous page (when going back) */}
          <div
            className={classNames("shrink-0", {
              "animate-pop-out": animationState === "back",
            })}
            style={{
              animationDuration: `${animationDurationMs}ms`,
              width,
              transformOrigin: "right",
            }}
          >
            {animationState === "back" ? pages[pageIndex + 1] : null}
          </div>
        </div>
      </div>
    </div>
  );
}
