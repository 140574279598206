import { LocalizedMessage } from "_shared/localization/LocalizedMessage";
import { SmallText } from "_shared/text/SmallText";

export function ComingSoonBadge() {
  return (
    <SmallText color="light">
      <LocalizedMessage id="feature_coming_soon" />
    </SmallText>
  );
}
