import { H2Text } from "_shared/text/H2Text";
import { SmallText } from "_shared/text/SmallText";
import { ReactNode } from "react";

interface Props {
  icon?: ReactNode;
  title?: ReactNode;
  subtitle?: ReactNode;
  button?: ReactNode;
}

export function Alert(props: Props) {
  const { icon, title, subtitle, button } = props;
  return (
    <div className="flex items-center gap-5 rounded-sm border border-primary bg-primary bg-opacity-5 py-3 pe-3 ps-5">
      <div className="text-2xl text-primary">{icon}</div>
      <div className="flex grow flex-col gap-0.5">
        <div>
          <h2>
            <H2Text>{title}</H2Text>
          </h2>
        </div>
        <div>
          <SmallText color="inherit">{subtitle}</SmallText>
        </div>
      </div>
      {button}
    </div>
  );
}
