import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Scrollable } from "_shared/Scrollable/Scrollable";
import { SmallText } from "_shared/text/SmallText";

interface Props<TData> {
  data: Array<TData>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  columns: Array<ColumnDef<TData, any>>;
}

export function Table<TData>(props: Props<TData>) {
  const { columns, data } = props;
  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
  });
  const columnSizes: Array<string> = [];
  for (const headerGroup of table.getHeaderGroups()) {
    for (const header of headerGroup.headers) {
      columnSizes.push(header.column.columnDef.meta?.size ?? "auto");
    }
  }
  return (
    <Scrollable axis="both">
      <table
        className="grid"
        style={{
          gridTemplateColumns: columnSizes.join(" "),
        }}
      >
        <thead className="contents">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id} className="contents">
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  className="flex items-center px-3 py-1.5 text-left"
                >
                  <SmallText color="light">
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                  </SmallText>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody className="contents">
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id} className="group contents">
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id} className="contents">
                  <div className="group-hover:bg-shade flex grow items-center px-3 py-1.5">
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </div>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </Scrollable>
  );
}
