import { ReactNode, forwardRef } from "react";

interface Props {
  children: ReactNode;
}

export const Chip = forwardRef<HTMLDivElement, Props>(
  function Chip(props, ref) {
    return (
      <div
        ref={ref}
        className="flex items-center gap-2 rounded-sm bg-gray-86 px-2.5 py-0.5 text-sm dark:bg-gray-42"
        {...props}
      />
    );
  },
);
