export const exits = [
  { value: "1", label: "Aquisition" },
  { value: "2", label: "Dissolution" },
  { value: "3", label: "Initial Public Offering (IPO)" },
  { value: "4", label: "Left the Company" },
  { value: "5", label: "Private Sale of your Stake" },
  { value: "6", label: "Liquidation" },
  { value: "7", label: "Merger" },
  { value: "8", label: "Still Engaged in Business" },
  { value: "9", label: "Other" },
] as const;

export type Exit = (typeof exits)[number]["value"];
