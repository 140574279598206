import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/sharp-light-svg-icons";
import { LocalizedMessage } from "_shared/localization/LocalizedMessage";
import { ComponentProps, forwardRef } from "react";
import { InlineButton } from "./InlineButton";

type Props = ComponentProps<typeof InlineButton>;

export const BackButton = forwardRef<HTMLButtonElement, Props>(
  function BackButton(props, ref) {
    const { children } = props;
    return (
      <InlineButton
        ref={ref}
        {...props}
        icon={<FontAwesomeIcon icon={faArrowLeft} />}
      >
        {children ?? <LocalizedMessage id="button_back" />}
      </InlineButton>
    );
  },
);
