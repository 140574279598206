import {
  MutableRefObject,
  RefCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";

export type Status = "idle" | "pending" | "error" | "success";

export function mergeRefs<T>(
  ...refs: ReadonlyArray<RefCallback<T> | MutableRefObject<T | null> | null>
) {
  return (instance: T | null) => {
    for (const ref of refs) {
      if (typeof ref === "function") {
        ref(instance);
      } else if (ref) {
        ref.current = instance;
      }
    }
  };
}

/**
 * Measure the size of an element, re-render if the size changes.
 *
 * @example
 * function MyComponent(props) {
 *   const ref = useRef();
 *   const [ width, height ] = useSize(ref);
 *   return <div ref={ref} />;
 * };
 */
export function useSize<T extends HTMLElement>(
  ref: React.RefObject<T>,
): [width: number, height: number] {
  const [width, setWidth] = useState<number>(0.0);
  const [height, setHeight] = useState<number>(0.0);

  useLayoutEffect(() => {
    if (!ref.current) return;
    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        setWidth(entry.target.clientWidth);
        setHeight(entry.target.clientHeight);
      }
    });
    resizeObserver.observe(ref.current);
    return () => resizeObserver.disconnect();
  }, [ref.current]);

  return [width, height];
}

/**
 * Returns a copy of `set`. If `item` was in the original `set`, it is removed; otherwise, it is added.
 */
export function toggleInSet<T>(item: T, set: Set<T>) {
  const newSet = new Set(set);
  if (set.has(item)) {
    newSet.delete(item);
  } else {
    newSet.add(item);
  }
  return newSet;
}

export function swap<T>(
  array: ReadonlyArray<T>,
  from: number,
  to: number,
): Array<T> {
  const copy = [...array];
  const temp = array[to];
  copy[to] = array[from];
  copy[from] = temp;
  return copy;
}

export function useMediaQuery(query: string): boolean {
  const [matches, setMatches] = useState<boolean>(
    () => window.matchMedia(query).matches,
  );

  useEffect(() => {
    const mediaQuery = window.matchMedia(query);
    function onChange(e: MediaQueryListEvent) {
      setMatches(e.matches);
    }
    mediaQuery.addEventListener("change", onChange);
    return () => mediaQuery.removeEventListener("change", onChange);
  });

  return matches;
}

export function formatISO(date: Date): string {
  return date.toISOString().slice(0, 10);
}
