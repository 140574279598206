import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faSearch } from "@fortawesome/sharp-light-svg-icons";
import * as Form from "@radix-ui/react-form";
import { createColumnHelper } from "@tanstack/react-table";
import { Breadcrumb } from "_shared/Breadcrumbs/Breadcrumb";
import { Breadcrumbs } from "_shared/Breadcrumbs/Breadcrumbs";
import { Table } from "_shared/Table/Table";
import { Tooltip } from "_shared/Tooltip/Tooltip";
import { PrimaryButton } from "_shared/button/PrimaryButton";
import { SecondaryButton } from "_shared/button/SecondaryButton";
import { Field } from "_shared/field/Field";
import { Switch } from "_shared/input/Switch/Switch";
import { TextInput } from "_shared/input/TextInput";
import { LocalizedMessage } from "_shared/localization/LocalizedMessage";
import { H1Text } from "_shared/text/H1Text";
import { H2Text } from "_shared/text/H2Text";
import { LabelText } from "_shared/text/LabelText";
import { MonoText } from "_shared/text/MonoText";
import { Link, useNavigate } from "react-router-dom";
import { UseCase } from "startups/startup/market-lab/products/UseCase";

export function MarketLabProduct() {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col gap-8">
      <div className="flex items-center">
        <div className="flex flex-1 flex-col gap-4">
          <Breadcrumbs>
            <Breadcrumb to="/">
              <LocalizedMessage id="startups_title" />
            </Breadcrumb>
            <Breadcrumb to={`/startups/0`}>Kavedon Kapital</Breadcrumb>
            <Breadcrumb to="/startups/0/market-lab">
              <LocalizedMessage id="app_marketLab" />
            </Breadcrumb>
            <Breadcrumb>Fraud-o-matic</Breadcrumb>
          </Breadcrumbs>
          <H1Text>Fraud-o-matic</H1Text>
        </div>
        <PrimaryButton
          icon={<FontAwesomeIcon icon={faSearch} />}
          onClick={() => navigate("challenges")}
        >
          Find challenges
        </PrimaryButton>
      </div>
      <div className="flex gap-12">
        <Form.Root className="flex w-1/3 flex-col gap-6">
          <h2>
            <H2Text>Product Details</H2Text>
          </h2>
          <Field>
            <LabelText>Name</LabelText>
            <Form.Control asChild>
              <TextInput value="Foo" />
            </Form.Control>
          </Field>
        </Form.Root>
        <div className="flex-1">
          <div className="flex items-center">
            <h2 className="flex-1">
              <H2Text>Use Cases</H2Text>
            </h2>
            <SecondaryButton icon={<FontAwesomeIcon icon={faAdd} />}>
              Add Use Case
            </SecondaryButton>
          </div>
          <Table columns={columns} data={mockUseCases} />
        </div>
      </div>
    </div>
  );
}

interface UseCase {
  name: string;
  status: "public" | "private";
  fields: string;
}

const columnHelper = createColumnHelper<UseCase>();

const columns = [
  columnHelper.accessor("name", {
    header: "Name",
    cell: ({ getValue }) => (
      <Link to="" className="text-primary underline-offset-2 hover:underline">
        {getValue()}
      </Link>
    ),
  }),
  columnHelper.accessor("status", {
    header: "Visibility",
    cell: ({ getValue }) => {
      const status = getValue();
      return (
        <Tooltip
          content={status === "public" ? "Visible to enterprises" : "Private"}
        >
          <Switch aria-label="Visibility" value={status === "public"} />
        </Tooltip>
      );
    },
  }),
  columnHelper.accessor("fields", {
    header: "Fields",
    cell: ({ row, getValue }) => {
      return (
        <div className="flex items-center gap-2">
          <MonoText>{getValue()}</MonoText>
          <UseCase name={row.original.name} />
        </div>
      );
    },
  }),
];

const mockUseCases: Array<UseCase> = [
  {
    name: "Foo",
    status: "public",
    fields: "location, amount, time... +2",
  },
  {
    name: "Bar",
    status: "private",
    fields: "location, time",
  },
];
