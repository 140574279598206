import { useState } from "react";

interface ToggleButtonProps {
  "aria-pressed": boolean;
  onClick: () => void;
}

export function useToggleButton(
  initialIsPressed = false,
): [isPressed: boolean, toggle: () => void, buttonProps: ToggleButtonProps] {
  const [isPressed, setIsPressed] = useState(initialIsPressed);
  function toggle() {
    setIsPressed(!isPressed);
  }
  return [
    isPressed,
    toggle,
    {
      "aria-pressed": isPressed,
      onClick: toggle,
    },
  ];
}

type LabelProps = {
  "aria-label": string;
  "aria-labelledby"?: never;
};

type LabelledByProps = {
  "aria-label"?: never;
  "aria-labelledby": string;
};

export type AriaLabelableProps = LabelProps | LabelledByProps;
