import { useEffect, useState } from "react";
import { useLocalStorage } from "usehooks-ts";

export type Theme = "light" | "dark";

const mediaQueryList = window.matchMedia("(prefers-color-scheme: dark)");

function useSystemTheme(): Theme {
  const [isDarkMode, setIsDarkMode] = useState<boolean>(mediaQueryList.matches);

  useEffect(() => {
    function onSystemThemeChange(event: MediaQueryListEvent) {
      setIsDarkMode(event.matches);
    }
    mediaQueryList.addEventListener("change", onSystemThemeChange);
    return () =>
      mediaQueryList.removeEventListener("change", onSystemThemeChange);
  }, []);

  return isDarkMode ? "dark" : "light";
}

export function usePreferredTheme(): [
  theme: Theme | null,
  setTheme: (theme: Theme | null) => void,
] {
  return useLocalStorage<Theme | null>("revveon_theme", null);
}

export function useTheme(): Theme {
  const systemTheme = useSystemTheme();
  const [preferredTheme] = usePreferredTheme();
  return preferredTheme ?? systemTheme;
}
