import { ReactNode } from "react";
import { SurveyBackButton } from "./SurveyBackButton";
import { SurveyNextButton } from "./SurveyNextButton";

interface Props {
  header?: ReactNode;
  label?: ReactNode;
  input?: ReactNode;
  primaryButton?: ReactNode;
  secondaryButton?: ReactNode;
}

export function Question(props: Props) {
  const {
    header = <SurveyBackButton />,
    label,
    input,
    primaryButton = <SurveyNextButton />,
    secondaryButton,
  } = props;
  return (
    <div className="flex flex-col gap-16">
      <div className="self-start ps-6">{header}</div>
      <div className="flex flex-col gap-8">
        <div className="flex">
          <div
            className="rounded-rl-sm -me-2 h-6 w-8 shrink-0 bg-white dark:bg-gray-42 rtl:-scale-x-100"
            style={{ clipPath: "polygon(0% 4%, 100% 100%, 100% 0%, 0% 0%)" }}
          />
          <div className="rounded-md bg-white px-6 py-4 text-lg dark:bg-gray-42">
            {label}
          </div>
        </div>
        <div className="flex flex-col items-start gap-8 ps-12">{input}</div>
      </div>
      <div className="flex items-center gap-4 ps-12">
        {primaryButton}
        {secondaryButton}
      </div>
    </div>
  );
}
