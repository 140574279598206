import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleMinus } from "@fortawesome/sharp-light-svg-icons";
import * as Form from "@radix-ui/react-form";
import { RefetchOptions, UseMutationResult } from "@tanstack/react-query";
import { exits } from "_options/exits";
import { fundingRounds } from "_options/fundingRounds";
import { industries } from "_options/industries";
import { InlineButton } from "_shared/button/InlineButton";
import { PrimaryButton } from "_shared/button/PrimaryButton";
import { SecondaryButton } from "_shared/button/SecondaryButton";
import { Field } from "_shared/field/Field";
import { InvalidIntMessage } from "_shared/field/InvalidIntMessage";
import { InvalidPastDateMessage } from "_shared/field/InvalidPastDateMessage";
import { InvalidUrlMessage } from "_shared/field/InvalidUrlMessage";
import { RequiredFormMessage } from "_shared/field/RequiredFormMessage";
import { DateInput } from "_shared/input/DateInput";
import { IntInput } from "_shared/input/IntInput";
import { RatingInput } from "_shared/input/RatingInput/RatingInput";
import { Select } from "_shared/input/Select/Select";
import { TextArea } from "_shared/input/TextArea";
import { TextInput } from "_shared/input/TextInput";
import { ToggleGroup } from "_shared/input/ToggleGroup/ToggleGroup";
import { UrlInput } from "_shared/input/UrlInput";
import { LocalizedMessage } from "_shared/localization/LocalizedMessage";
import { useLocalize } from "_shared/localization/useLocalize";
import { LabelText } from "_shared/text/LabelText";
import { SmallText } from "_shared/text/SmallText";
import { formatISO } from "_shared/utils";
import { Project } from "founder/portfolio/Portfolio.types";

export function EditProject(props: {
  project: Project;
  onChange: (project: Project) => void;
  editMutation: UseMutationResult<
    RefetchOptions | undefined,
    Error,
    void,
    unknown
  >;
  onCancel: () => void;
  onDelete?: () => void;
}) {
  const { project, onChange, editMutation, onCancel, onDelete } = props;
  const localize = useLocalize();

  function onUpdate(update?: Project) {
    onChange({ ...project, ...update });
  }

  return (
    <Form.Root
      className="rounded-sm border-l border-gray-86 ps-8 pt-6 dark:border-gray-42"
      onSubmit={(e) => {
        e.preventDefault();
        editMutation.mutate();
      }}
    >
      <div className="flex flex-col items-start gap-6 pb-6">
        <Field>
          <Form.Label>
            <LabelText>
              <LocalizedMessage id="portfolio_company" />
            </LabelText>
          </Form.Label>
          <Form.Control asChild>
            <TextInput
              value={project.projectCompany_name ?? ""}
              onChange={(value) => onUpdate({ projectCompany_name: value })}
              required
            />
          </Form.Control>
          <RequiredFormMessage />
        </Field>
        <Field>
          <Form.Label>
            <LabelText>
              <LocalizedMessage id="portfolio_website" />
            </LabelText>
          </Form.Label>
          <Form.Control asChild>
            <UrlInput
              required
              value={project.Website ?? ""}
              onChange={(value) => onUpdate({ Website: value })}
            />
          </Form.Control>
          <RequiredFormMessage />
          <InvalidUrlMessage />
        </Field>
        <Field>
          <Form.Label>
            <LabelText>
              <LocalizedMessage id="portfolio_start_date" />
            </LabelText>
          </Form.Label>
          <Form.Control asChild>
            <DateInput
              required
              value={
                project.JoiningDate ? new Date(project.JoiningDate) : undefined
              }
              max={new Date()}
              onChange={(value) =>
                onUpdate({ JoiningDate: value ? formatISO(value) : "" })
              }
            />
          </Form.Control>
          <InvalidPastDateMessage />
          <RequiredFormMessage />
        </Field>
        <Field>
          <Form.Label>
            <LabelText>
              <LocalizedMessage id="portfolio_end_date" />
            </LabelText>
          </Form.Label>
          <Form.Control asChild>
            <DateInput
              value={
                project.LeavingDate ? new Date(project.LeavingDate) : undefined
              }
              max={new Date()}
              onChange={(value) => {
                onUpdate({ LeavingDate: value ? formatISO(value) : "" });
              }}
              min={
                project.JoiningDate ? new Date(project.JoiningDate) : undefined
              }
            />
          </Form.Control>
          <InvalidPastDateMessage />
          <Form.Message match="rangeUnderflow">
            <SmallText color="warning">
              <LocalizedMessage id="portfolio_error_date_after_joining" />
            </SmallText>
          </Form.Message>
          <div>
            <SmallText color="light">
              <LocalizedMessage id="portfolio_still_working_here" />{" "}
              <InlineButton
                onClick={() => {
                  onUpdate({ LeavingDate: "" });
                }}
              >
                <LocalizedMessage id="portfolio_clear" />
              </InlineButton>
            </SmallText>
          </div>
        </Field>
        <Field>
          <Form.Label>
            <LabelText>
              <LocalizedMessage id="portfolio_result" />
            </LabelText>
          </Form.Label>
          <Form.Control asChild>
            <Select
              required
              value={project.ExitType}
              onChange={(value) => onUpdate({ ExitType: value })}
              options={exits}
            />
          </Form.Control>
          <RequiredFormMessage />
        </Field>
        <Field>
          <Form.Label>
            <LabelText>
              <LocalizedMessage id="portfolio_description" />
            </LabelText>
          </Form.Label>
          <Form.Control asChild>
            <TextArea
              value={project.Elavator ?? ""}
              onChange={(e) => onUpdate({ Elavator: e.target.value })}
              required
            />
          </Form.Control>
          <RequiredFormMessage />
        </Field>
        <Field>
          <Form.Label>
            <LabelText>
              <LocalizedMessage id="portfolio_industry" />
            </LabelText>
          </Form.Label>
          <Form.Control asChild>
            <Select
              required
              value={project.Industry}
              onChange={(value) => onUpdate({ Industry: value })}
              options={industries}
            />
          </Form.Control>
          <RequiredFormMessage />
        </Field>
        <Field>
          <Form.Label>
            <LabelText>
              <LocalizedMessage id="portfolio_organization" />
            </LabelText>
          </Form.Label>
          <Form.Control asChild required>
            <ToggleGroup
              value={
                project.isForProfit === undefined
                  ? project.isForProfit
                  : project.isForProfit
                    ? "true"
                    : "false"
              }
              onChange={(value) => onUpdate({ isForProfit: value === "true" })}
              options={[
                {
                  value: "true",
                  label: localize("portfolio_for_profit"),
                },
                {
                  value: "false",
                  label: localize("portfolio_not_for_profit"),
                },
              ]}
            />
          </Form.Control>
          <RequiredFormMessage />
        </Field>
        <Field>
          <Form.Label id="profitLoss">
            <LabelText>
              <LocalizedMessage id="portfolio_financial_feel" />
            </LabelText>
          </Form.Label>
          <Form.Control asChild required>
            {/* Value edited to match the 1-4 numbering in the back-end to the 0-3 in the front end */}
            <RatingInput
              required
              start={localize("portfolio_worse_off")}
              end={localize("portfolio_better_off")}
              length={4}
              aria-labelledby="profitLoss"
              value={
                project.FinancialFeel
                  ? Number(project.FinancialFeel) - 1
                  : undefined
              }
              onChange={(value) => {
                onUpdate({ FinancialFeel: (value + 1).toString() });
              }}
            />
          </Form.Control>
          <RequiredFormMessage />
        </Field>
        <Field>
          <Form.Label>
            <LabelText>
              <LocalizedMessage id="portfolio_total_funding_received" />
            </LabelText>
          </Form.Label>
          <Form.Control asChild>
            <IntInput
              required
              prefix="$"
              value={project.TotalFunding}
              onChange={(value) => onUpdate({ TotalFunding: value })}
            />
          </Form.Control>
          <InvalidIntMessage />
          <RequiredFormMessage />
        </Field>
        <Field>
          <Form.Label>
            <LabelText>
              <LocalizedMessage id="portfolio_last_round_funding" />
            </LabelText>
          </Form.Label>
          <Form.Control asChild>
            <Select
              required
              value={project.LastFunding}
              onChange={(value) => onUpdate({ LastFunding: value })}
              options={fundingRounds}
            />
          </Form.Control>
          <RequiredFormMessage />
        </Field>
        <Field>
          <Form.Label>
            <LabelText>
              <LocalizedMessage id="portfolio_number_employees" />
            </LabelText>
          </Form.Label>
          <Form.Control asChild>
            <IntInput
              required
              value={project.TotalEmployee}
              onChange={(value) => onUpdate({ TotalEmployee: value })}
            />
          </Form.Control>
          <InvalidIntMessage />
          <RequiredFormMessage />
        </Field>
      </div>
      <div className="sticky bottom-0 flex gap-4 self-stretch border-t border-gray-86 bg-gray-94 py-4 dark:border-gray-42 dark:bg-gray-27">
        <div className="flex flex-1 gap-4">
          <Form.Submit asChild>
            <PrimaryButton status={editMutation.status}>
              <LocalizedMessage id="button_save" />
            </PrimaryButton>
          </Form.Submit>
          <SecondaryButton onClick={onCancel}>
            <LocalizedMessage id="button_cancel" />
          </SecondaryButton>
        </div>
        {onDelete ? (
          <SecondaryButton
            icon={<FontAwesomeIcon icon={faCircleMinus} />}
            onClick={onDelete}
          >
            <LocalizedMessage id="button_delete" />
          </SecondaryButton>
        ) : null}
      </div>
    </Form.Root>
  );
}
