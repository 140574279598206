import { ComponentProps, ReactNode, forwardRef } from "react";

interface Props extends Omit<ComponentProps<"button">, "className"> {
  icon?: ReactNode;
}

export const InlineButton = forwardRef<HTMLButtonElement, Omit<Props, "ref">>(
  function InlineButton(
    { children, icon, type = "button", ...otherProps },
    ref,
  ) {
    return (
      <button
        ref={ref}
        type={type}
        className="inline-flex items-center gap-1 rounded-sm underline decoration-1 underline-offset-4 outline-2 outline-blue-62 hover:decoration-2 focus-visible:outline disabled:no-underline disabled:opacity-70"
        {...otherProps}
      >
        {icon}
        {children}
      </button>
    );
  },
);
