import classNames from "classnames";
import { ComponentProps, ReactNode, forwardRef } from "react";

interface Props extends Omit<ComponentProps<"button">, "className"> {
  children?: ReactNode;
  onClick?: () => void;
  size?: "small" | "normal";
}

export const IconButton = forwardRef<HTMLButtonElement, Omit<Props, "ref">>(
  function IconButton(props, ref) {
    const {
      children,
      onClick,
      size = "normal",
      type = "button",
      ...otherProps
    } = props;
    return (
      <button
        ref={ref}
        type={type}
        className={classNames(
          "hover:bg-shade aspect-square w-10 rounded-full",
          {
            "border border-gray-86 dark:border-gray-42": size === "normal",
            "text-primary": size === "small",
          },
        )}
        onClick={onClick}
        {...otherProps}
      >
        {children}
      </button>
    );
  },
);
