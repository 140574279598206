import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBriefcase,
  faDollar,
  faPencil,
  faSeedling,
  faUsers,
} from "@fortawesome/sharp-light-svg-icons";
import { fundingRounds } from "_options/fundingRounds";
import { industries } from "_options/industries";
import { useFeatureFlags } from "_shared/FeatureFlagsContext";
import { Tooltip } from "_shared/Tooltip/Tooltip";
import { SecondaryButton } from "_shared/button/SecondaryButton";
import { Chip } from "_shared/chip/Chip";
import { LocalizedMessage } from "_shared/localization/LocalizedMessage";
import { H2Text } from "_shared/text/H2Text";
import { SmallText } from "_shared/text/SmallText";
import { TruncatedText } from "_shared/text/TruncatedText";
import { DeckSubmission } from "founder/portfolio/DeckSubmission";
import { Project } from "founder/portfolio/Portfolio.types";
import { TimelineEvent } from "founder/portfolio/TimelineEvent";

interface Props {
  project?: Project;
  isActive?: boolean;
  onEdit?: () => void;
}

export function ViewProject(props: Props) {
  const { project, isActive, onEdit } = props;
  const { isSubmitDeckOn } = useFeatureFlags();
  return (
    <TimelineEvent
      title={
        <h2>
          <H2Text>
            {project?.projectCompany_name ?? (
              <LocalizedMessage id="portfolio_untitled_project" />
            )}
          </H2Text>
        </h2>
      }
      action={
        onEdit ? (
          <>
            {isSubmitDeckOn ? <DeckSubmission project={project} /> : null}
            <SecondaryButton
              icon={<FontAwesomeIcon icon={faPencil} />}
              onClick={onEdit}
            >
              <LocalizedMessage id="portfolio_edit" />
            </SecondaryButton>
          </>
        ) : null
      }
      isActive={isActive}
    >
      <div className="flex flex-col ps-6">
        <div className="flex items-center gap-2">
          {project?.Website ? (
            <TruncatedText>
              <a
                href={project.Website}
                target="_blank"
                rel="noreferrer"
                className="text-primary hover:underline"
              >
                <SmallText color="inherit">{project.Website}</SmallText>
              </a>
            </TruncatedText>
          ) : null}
          {project?.JoiningDate ? (
            <div className="shrink-0">
              <SmallText color="light">
                <LocalizedMessage
                  id={
                    project.LeavingDate
                      ? "portfolio_joining_to_leaving_date"
                      : "portfolio_joining_to_now"
                  }
                  values={{
                    JoiningDate: new Date(project.JoiningDate),
                    LeavingDate: project.LeavingDate
                      ? new Date(project.LeavingDate)
                      : undefined,
                  }}
                />
              </SmallText>
            </div>
          ) : null}
        </div>
        <div className="pb-3 pt-2">{project?.Elavator}</div>
        <div className="flex flex-wrap gap-2">
          {project?.Industry !== undefined ? (
            <Tooltip content={<LocalizedMessage id="portfolio_industry" />}>
              <Chip>
                <FontAwesomeIcon icon={faBriefcase} />
                {
                  industries.find(({ value }) => value === project.Industry)
                    ?.label
                }
              </Chip>
            </Tooltip>
          ) : null}
          {project?.isForProfit !== undefined ? (
            <Tooltip content={<LocalizedMessage id="portfolio_organization" />}>
              <Chip>
                <FontAwesomeIcon icon={faDollar} />
                {project.isForProfit ? (
                  <LocalizedMessage id="portfolio_for_profit" />
                ) : (
                  <LocalizedMessage id="portfolio_not_for_profit" />
                )}
              </Chip>
            </Tooltip>
          ) : null}
          {project?.TotalEmployee !== undefined ? (
            <Tooltip
              content={<LocalizedMessage id="portfolio_number_employees" />}
            >
              <Chip>
                <FontAwesomeIcon icon={faUsers} />
                {project?.TotalEmployee}
              </Chip>
            </Tooltip>
          ) : null}
          {project?.LastFunding !== undefined ? (
            <Tooltip
              content={<LocalizedMessage id="portfolio_last_round_funding" />}
            >
              <Chip>
                <FontAwesomeIcon icon={faSeedling} />
                {
                  fundingRounds.find(
                    ({ value }) => value === project.LastFunding,
                  )?.label
                }
              </Chip>
            </Tooltip>
          ) : null}
        </div>
      </div>
    </TimelineEvent>
  );
}
