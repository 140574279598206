import { useEffect, useRef, useState } from "react";

export interface TelephoneNumber {
  /**
   * Full phone number in E.164 format, like `+44 1234 567 890`
   */
  e164: string;
  /**
   * 2-letter country code, like `GB`
   */
  iso3166: string;
  /**
   * International dial code (UK is `44`)
   */
  countryCode: string;
  /**
   * `+44 1234 567 890` -> `01234 567 890`
   */
  withoutCountryCode: string;
}

export function useTelInput() {
  const ref = useRef<HTMLInputElement>(null);
  const iti = useRef<intlTelInput.Plugin>();
  const [e164, setE164] = useState("");
  const [iso3166, setIso3166] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [withoutCountryCode, setWithoutCountryCode] = useState("");

  useEffect(() => {
    async function init() {
      /**
       * libphonenumber is massive but enables parsing. As tel inputs are not going to be used on most pages, import it dynamically.
       * See https://www.npmjs.com/package/intl-tel-input#getting-started-using-a-cdn:~:text=you%20can%20manually%20include%20the%20script%20yourself%20however%20you%20like
       */
      import("intl-tel-input/build/css/intlTelInput.css");
      const promise = import("intl-tel-input");
      await import("intl-tel-input/build/js/utils.js");
      const resolved = await promise;
      const intlTelInput = resolved as unknown as typeof resolved.default; // Workaround allowSyntheticDefaultImports issue
      if (!ref.current) return;
      iti.current = intlTelInput(ref.current, {
        dropdownContainer: document.body,
        preferredCountries: navigator.languages
          .map((tag) => {
            const locale = new Intl.Locale(tag);
            return locale.region;
          })
          .filter((region): region is string => region !== undefined),
      });
      function onInput() {
        const country = iti.current?.getSelectedCountryData();
        setE164(iti.current?.getNumber() ?? "");
        setIso3166(country?.iso2 ?? "");
        setCountryCode(country?.dialCode ?? "");
        setWithoutCountryCode(
          iti.current?.getNumber()?.replace(`+${country?.dialCode}`, "") ?? "",
        );
      }
      function onBlur() {
        if (!ref.current) return;
        ref.current.value = iti.current?.getNumber() ?? "";
        // Fake change event for compatibility with @radix-ui/react-form@0.0.3 validation
        ref.current.dispatchEvent(new Event("change"));
      }
      ref.current.addEventListener("input", onInput);
      /**
       * This custom event is part of intl-tel-input.
       * See https://github.com/jackocnr/intl-tel-input?tab=readme-ov-file#events
       */
      ref.current.addEventListener("countrychange", onInput);
      ref.current.addEventListener("blur", onBlur);
      return () => {
        ref.current?.removeEventListener("input", onInput);
        ref.current?.removeEventListener("countrychange", onInput);
        ref.current?.removeEventListener("blur", onBlur);
        iti.current?.destroy();
      };
    }
    init();
  }, []);

  const inputProps = {
    type: "tel" as const,
    autoComplete: "tel",
    ref,
    pattern: /^\+[1-9]\d{1,14}$/.source,
  };
  const tel: TelephoneNumber = {
    e164,
    iso3166,
    countryCode,
    withoutCountryCode,
  };
  return [inputProps, tel] as const;
}
