import {
  Content,
  Overlay,
  Portal,
  Root,
  Trigger,
} from "@radix-ui/react-dialog";
import { ReactNode } from "react";

interface Props {
  trigger?: ReactNode;
  /**
   * Modal dialogs block interaction with the rest of the app.
   */
  isModal: boolean;
  children?: ReactNode;
}

export function SideDialog(props: Props) {
  const { trigger, isModal, children } = props;
  return (
    <Root modal={isModal}>
      <Trigger asChild>{trigger}</Trigger>
      <Portal>
        {isModal ? (
          <Overlay className="fixed inset-0 bg-black bg-opacity-20 backdrop-blur-sm" />
        ) : undefined}
        <Content
          className="fixed right-0 top-0 flex h-full flex-col border-l border-gray-86 bg-white data-[state=closed]:animate-slide-right data-[state=open]:animate-slide-left dark:border-gray-42 dark:bg-gray-31"
          onPointerDownOutside={(e) => {
            // Close dialogs on click outside. Modal dialogs must be closed explicitly.
            if (isModal) e.preventDefault();
          }}
        >
          {children}
        </Content>
      </Portal>
    </Root>
  );
}
