import { ReactNode, useLayoutEffect, useRef, useState } from "react";

export interface Props {
  /**
   * Transition duration in milliseconds
   */
  duration: number;
  children?: ReactNode;
}

/**
 * When a new layout causes a change in this components position, it will automatically
 * transition from the old to the new position.
 *
 * @example
 * const ListWhereOrderMayChange = ({items}) => {
 *   const [sortOrder, setSortOrder] = useState('ASC');
 *   return <>
 *     {items
 *       .sort(sortOrder === 'ASC' ? (a, b) => a - b : (a, b) => b - a)
 *       .map((item) => <AnimatedMove key={item}>{item}</AnimatedMove>)
 *     }
 *     <button onClick={() => setSortOrder('DESC')}>Sort</button>
 *   </>
 * }
 */
export function AnimatedMove(props: Props) {
  const { children, duration, ...otherProps } = props;
  const ref = useRef<HTMLDivElement>(null);
  const position = useRef<{ oldTop: number; oldLeft: number }>();
  const animation = useRef<Animation>();
  const [mountTimeMs] = useState(Date.now());

  useLayoutEffect(() => {
    if (!ref.current) return;
    if (animation.current) return; // Don't start another animation while one is already running
    // If just mounted, allow time for other animations to complete
    if (Date.now() < mountTimeMs + 1000) return;
    // Update element position
    const { oldLeft, oldTop } = position.current ?? {};
    const newTop = ref.current.offsetTop;
    const newLeft = ref.current.offsetLeft;
    position.current = { oldTop: newTop, oldLeft: newLeft };
    if (oldTop === undefined || oldLeft === undefined) return;
    // Calculate difference between old and new position
    const deltaX = newLeft - oldLeft;
    const deltaY = newTop - oldTop;
    if (deltaX === 0 && deltaY === 0) return;
    // Animate from old position to new postion
    animation.current = ref.current.animate(
      [
        { transform: `translate(${-deltaX}px, ${-deltaY}px)` },
        { transform: "none" },
      ],
      {
        duration,
        easing: "ease-out",
      },
    );
    animation.current.addEventListener("finish", () => {
      animation.current = undefined;
    });
  });

  return (
    <div ref={ref} {...otherProps}>
      {children}
    </div>
  );
}
