import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyes } from "@fortawesome/sharp-light-svg-icons";
import * as Form from "@radix-ui/react-form";
import { useToggleButton } from "_shared/a11y";
import { TextInput } from "_shared/input/TextInput";
import { LocalizedMessage } from "_shared/localization/LocalizedMessage";
import { useLocalize } from "_shared/localization/useLocalize";
import { LabelText } from "_shared/text/LabelText";
import { SmallText } from "_shared/text/SmallText";
import { ReactNode, useEffect, useState } from "react";
import { FormattedNumber } from "react-intl";

const MIN_LENGTH = 15;

interface Props {
  onChange: (newValue: string) => void;
  value: string;
  label?: ReactNode;
}

export function NewPasswordField(props: Props) {
  const { label, value, ...otherProps } = props;
  const localize = useLocalize();
  const [showPassword, _, showPasswordButtonProps] = useToggleButton();
  const commonPasswords = useCommonPasswords();

  return (
    <div className="flex flex-col gap-1">
      <Form.Label>
        <LabelText>{label}</LabelText>
      </Form.Label>
      {commonPasswords === undefined ? (
        <div className="h-10 w-full animate-pulse rounded-sm bg-gray-86 dark:bg-gray-42"></div>
      ) : (
        <>
          <div className="relative flex flex-col">
            <Form.Control asChild>
              <TextInput
                type={showPassword ? "text" : "password"}
                autoComplete="new-password"
                minLength={MIN_LENGTH}
                required
                value={value}
                {...otherProps}
              />
            </Form.Control>
            <button
              type="button"
              className="absolute right-0 h-full w-12"
              aria-label={localize("user_show_password")}
              {...showPasswordButtonProps}
            >
              <FontAwesomeIcon icon={showPassword ? faEyes : faEye} />
            </button>
          </div>
          <div className="flex gap-1">
            <div className="flex-1">
              <SmallText color="warning">
                <Form.Message match={(v) => commonPasswords.has(v)}>
                  <LocalizedMessage id="user_password_too_common" />
                </Form.Message>
              </SmallText>
            </div>
            <Form.ValidityState>
              {(validity) => (
                <SmallText color={validity?.tooShort ? "warning" : "light"}>
                  <FormattedNumber value={value.length} />
                  {" / "}
                  <FormattedNumber value={MIN_LENGTH} />
                </SmallText>
              )}
            </Form.ValidityState>
          </div>
        </>
      )}
    </div>
  );
}

/**
 * Get the top 100k most common passwords from Have I Been Pwned, courtesy of
 * https://www.ncsc.gov.uk/blog-post/passwords-passwords-everywhere
 */
function useCommonPasswords(): Set<string> | undefined {
  const [passwords, setPasswords] = useState<Set<string>>();

  useEffect(() => {
    async function fetchPasswords() {
      const array = await import("./PwnedPasswordsTop100k.json");
      setPasswords(new Set(array));
    }
    fetchPasswords();
  }, []);

  return passwords;
}
