import { useIsFetching, useIsMutating } from "@tanstack/react-query";
import darkLogo from "_assets/images/revveon-logo-dark.svg";
import lightLogo from "_assets/images/revveon-logo-light.svg";
import { Spinner } from "_shared/Spinner";
import { Tooltip } from "_shared/Tooltip/Tooltip";
import { LocalizedMessage } from "_shared/localization/LocalizedMessage";
import { useLocalize } from "_shared/localization/useLocalize";
import { useTheme } from "_shared/theme/theme";
import { Link } from "react-router-dom";
import { UserSidebar } from "./UserSidebar";

export function GlobalHeader() {
  const localize = useLocalize();
  const theme = useTheme();
  const activeFetchCount = useIsFetching() + useIsMutating();
  return (
    <div className="flex items-center justify-between px-8 py-4">
      <div className="flex items-center gap-6">
        <Link to="/" className="contents">
          <img
            src={theme === "light" ? lightLogo : darkLogo}
            alt={localize("revveon")}
          />
        </Link>
        {activeFetchCount > 0 ? (
          <Tooltip content={<LocalizedMessage id="loading" />}>
            <div className="text-xl">
              <Spinner />
            </div>
          </Tooltip>
        ) : null}
      </div>
      <UserSidebar />
    </div>
  );
}
