import { ReactNode } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAuth } from "./AuthContext";

interface Props {
  children?: ReactNode;
}

export function Authenticated({ children }: Props) {
  const { user } = useAuth();
  const { pathname } = useLocation();
  if (user === undefined) {
    return <Navigate to="/user/sign-in" state={{ continue: pathname }} />;
  }
  if (children) return children;
  return <Outlet />;
}
