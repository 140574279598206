import { Spinner } from "_shared/Spinner";
import { Status } from "_shared/utils";
import { ComponentProps, ReactNode, forwardRef } from "react";

interface Props extends Omit<ComponentProps<"button">, "className"> {
  icon?: ReactNode;
  status?: Status;
}

export const PrimaryButton = forwardRef<HTMLButtonElement, Omit<Props, "ref">>(
  function PrimaryButton(
    { children, icon, status, type = "button", disabled, ...otherProps },
    ref,
  ) {
    return (
      <button
        ref={ref}
        type={type}
        disabled={status === "pending" || disabled}
        className="flex items-center justify-center gap-2 whitespace-nowrap rounded-sm bg-primary px-4 py-3 text-base leading-none text-white hover:brightness-95 active:brightness-105 disabled:pointer-events-none disabled:opacity-70 dark:text-gray-16"
        {...otherProps}
      >
        {status === "pending" ? (
          <Spinner />
        ) : (
          <>
            {icon}
            {children}
          </>
        )}
      </button>
    );
  },
);
