import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/sharp-light-svg-icons";
import { Close, Description, Root } from "@radix-ui/react-toast";
import { IconButton } from "_shared/button/IconButton";
import { useLocalize } from "_shared/localization/useLocalize";
import { SmallText } from "_shared/text/SmallText";
import { ReactNode, useId, useRef } from "react";

interface ToastProps {
  children: ReactNode;
  duration?: number;
  onClose: () => void;
  closeButton?: ReactNode;
}

/**
 * @example
 * const optionalDuration = 7000 //(milliseconds)
 * showToast((props) => <Toast {...props} duration={optionalDuration}>Toast Text</Toast>)
 *
 * const onToastClose = () => {
 *  console.log("Toast is now closed");
 * };
 * const closeButton = <button onClick={() => {}}>Undo</button>; // Styles the Toast's <Close>, onClick will call before closing
 * showToast((props: Pick<ComponentProps<typeof Toast>, "onClose">) => (
 *  <Toast
 *    {...props}
 *    duration={optionalDuration}
 *    onClose={() => {
 *    props.onClose();
 *    onToastClose();
 *    }}
 *    closeButton={closeButton}
 *  >
 *  Toast Text
 *  </Toast>
 * ));
 */
export function Toast(props: ToastProps) {
  const localize = useLocalize();
  const id = useId();
  const {
    children,
    onClose,
    duration = 6000,
    closeButton = (
      <IconButton aria-label={localize("button_close")} size="small">
        <FontAwesomeIcon icon={faTimes} size="lg" />
      </IconButton>
    ),
  } = props;
  const ref = useRef<HTMLLIElement>(null);
  return (
    <Root
      aria-labelledby={id}
      forceMount
      className="flex max-w-md items-start rounded-sm bg-white p-1.5 shadow-md data-[state*=open]:animate-toast-in data-[state=closed]:animate-toast-out dark:bg-gray-24"
      duration={duration}
      onAnimationEnd={() => {
        if (ref.current?.getAttribute("data-state") === "closed") {
          onClose();
        }
      }}
      ref={ref}
    >
      <Description id={id} className="flex-1 px-3 py-2">
        <SmallText color="inherit">{children}</SmallText>
      </Description>
      <Close asChild>{closeButton}</Close>
    </Root>
  );
}
