export const fundingRounds = [
  { value: "1", label: "Pre-seed" },
  { value: "2", label: "Seed" },
  { value: "3", label: "Angel" },
  { value: "4", label: "Series A" },
  { value: "5", label: "Series B" },
  { value: "6", label: "Series C" },
  { value: "7", label: "Series D" },
  { value: "8", label: "Series E" },
  { value: "9", label: "Series F" },
  { value: "10", label: "Series G" },
  { value: "11", label: "Later Round" },
] as const;

export type FundingRound = (typeof fundingRounds)[number]["value"];
