import { InputProps } from "_shared/input/Input.types";
import { TextInput } from "_shared/input/TextInput";
import { forwardRef } from "react";

interface Props extends InputProps {
  onChange?: (newValue?: string | undefined) => void;
  value?: string;
}

/**
 * A controlled input with built-in http URL validation.
 */
export const UrlInput = forwardRef<HTMLInputElement, Props>(
  function UrlInput(props, ref) {
    const { onChange, ...inputProps } = props;

    return (
      <TextInput
        ref={ref}
        type="url"
        pattern="https?://.*\..*"
        onChange={onChange}
        {...inputProps}
      />
    );
  },
);
